import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import BankList from './BankList';

export const Bank: object = {
  list: BankList,
  icon: AccountBalanceIcon,
};

export default Bank;
