import React, { useState } from 'react';
import {
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  required,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { Grid } from '@material-ui/core';
import {
  AssetSegment,
  assetsType,
  DocumentSegment,
  propertyUse,
} from '../../Utils/enums';
import {
  formatInputNumber,
  generateYearsSince,
  parseInputNumber,
} from '../../Utils/functions';
import Addresses from '../../Components/Addresses';
import FormTitle from '../../Components/FormTitle';

const isRequired = [required()];

const AssetsForm = ({ className, formClassName, ...rest }: any) => {
  const translate = useTranslate();
  const form = useForm();
  const [values, setValues] = useState(form.getState().values);
  const years = generateYearsSince();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <SelectInput
            source='segment'
            fullWidth
            disabled={rest?.record.id}
            label={translate('resources.assets.fields.assetTypeFilter')}
            variant='outlined'
            onChange={() => {
              setValues(form.getState().values);
            }}
            choices={assetsType}
            validate={isRequired}
          />
        </Grid>
        {values?.segment || rest?.record?.segment ? (
          <>
            <Grid item xs={4}>
              <ReferenceInput
                source={rest.record?.id ? 'assetType.id' : 'assetType'}
                resource='assets'
                label={translate('resources.assets.fields.assetType')}
                reference='asset-types'
                fullWidth
                variant='outlined'
                validate={isRequired}
                onChange={() => {
                  setValues(form.getState().values);
                  form.change('assetTypeParameter', '');
                }}
                filter={
                  values?.segment && {
                    segment: values.segment,
                  }
                }
              >
                <SelectInput optionText='name' />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                source={
                  rest.record?.id
                    ? 'assetTypeParameter.id'
                    : 'assetTypeParameter'
                }
                disabled={!values?.assetType}
                resource='assets'
                label={translate('resources.assets.fields.assetTypeParameter')}
                reference='asset-type-parameters'
                fullWidth
                variant='outlined'
                filter={
                  values?.assetType && {
                    assetType: values.assetType.id || values.assetType,
                  }
                }
              >
                <SelectInput optionText='name' />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                source={rest.record?.id ? 'documentType.id' : 'documentType'}
                resource='assets'
                label={translate('resources.assets.fields.documentType')}
                reference='document-types'
                fullWidth
                variant='outlined'
                filter={
                  values?.segment && {
                    documentSegment: DocumentSegment.Asset.id,
                    segment: 'info',
                  }
                }
                validate={isRequired}
              >
                <SelectInput optionText='name' />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <TextInput
                source='documentNumber'
                resource='assets'
                fullWidth
                variant='outlined'
                validate={isRequired}
              />
            </Grid>
          </>
        ) : null}
        {values?.segment === AssetSegment.Vehicle ||
        rest?.record?.segment === AssetSegment.Vehicle ? (
          <>
            <Grid item xs={4}>
              <TextInput
                source='brand'
                resource='assets'
                fullWidth
                variant='outlined'
                validate={isRequired}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                source='model'
                resource='assets'
                fullWidth
                variant='outlined'
                validate={isRequired}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectInput
                source='year'
                fullWidth
                label={translate('resources.assets.fields.year')}
                variant='outlined'
                validate={isRequired}
                choices={years}
              />
            </Grid>
          </>
        ) : null}
        {values?.segment || rest?.record?.segment ? (
          <Grid item xs={4}>
            <TextInput
              source='value'
              fullWidth
              label={translate('resources.assets.fields.value')}
              defaultValue='0'
              format={(v) => formatInputNumber(v)}
              parse={(v) => parseInputNumber(v)}
              variant='outlined'
              validate={isRequired}
            />
          </Grid>
        ) : null}
        {values?.segment === AssetSegment.Vehicle ||
        rest.record.segment === AssetSegment.Vehicle ? (
          <Grid item xs={4}>
            <BooleanInput
              label={translate('resources.assets.fields.isInsured')}
              defaultValue={false}
              source='isInsured'
              fullWidth
              variant='outlined'
            />
          </Grid>
        ) : null}
        {values?.segment === AssetSegment.Property ||
        rest?.record?.segment === AssetSegment.Property ? (
          <>
            <Grid item xs={4}>
              <SelectInput
                source='propertyUse'
                fullWidth
                variant='outlined'
                label={translate('resources.assets.fields.propertyUse')}
                choices={propertyUse}
              />
            </Grid>
            <Grid item xs={4}>
              <BooleanInput
                label={translate('resources.assets.fields.haveDeed')}
                defaultValue={false}
                source='haveDeed'
                fullWidth
                variant='outlined'
              />
            </Grid>
          </>
        ) : null}
        {values?.segment || rest?.record?.segment ? (
          <Grid item xs={4}>
            <BooleanInput
              label={translate('resources.assets.fields.legalOpposition')}
              defaultValue={false}
              source='legalOpposition'
              fullWidth
              variant='outlined'
            />
          </Grid>
        ) : null}
        {values?.segment === AssetSegment.Property ||
        rest?.record?.segment === AssetSegment.Property ? (
          <Grid item xs={12}>
            <FormTitle title='Dirección de la propiedad' />
            <Addresses record={rest.record} />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default AssetsForm;
