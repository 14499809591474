import { Edit, ListButton, TopToolbar } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import IncomeTypeForm from './IncomeTypeForm';

const IncomeTypeActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const IncomeTypeEdit = (props: any) => {
  return (
    <Edit actions={<IncomeTypeActions {...props} />} {...props}>
      <IncomeTypeForm {...props} />
    </Edit>
  );
};

export default IncomeTypeEdit;
