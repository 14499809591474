import Cookies from '../Utils/cookies';

const authProvider = {
  login: ({ username, password }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const identifier = username;
    const request = new Request(`${apiUrl}/auth/local`, {
      method: 'POST',
      body: JSON.stringify({ identifier, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        Cookies.setCookie('token', response.jwt, 1);
        Cookies.setCookie('loginTime', new Date(), 1);
        Cookies.setCookie(
          'franchise',
          JSON.stringify(response.user.franchise),
          1
        );
        Cookies.setCookie('role', response.user.role.name, 1);
        localStorage.setItem('user', JSON.stringify(response.user));

        return response;
      })
      .then(({ jwt, user }) => {
        fetch(`${apiUrl}/employees/me`, {
          headers: new Headers({ authorization: `Bearer ${jwt}` }),
        })
          .then((res) => {
            if (res.status < 200 || res.status >= 300) {
              return '';
            }
            return res.json();
          })
          .then((employee) =>
            localStorage.setItem('employee', JSON.stringify(employee))
          );

        return fetch(`${apiUrl}/users-permissions/roles/${user.role.id}`, {
          headers: new Headers({ authorization: `Bearer ${jwt}` }),
        });
      })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ role }) => {
        localStorage.setItem(
          'permissions',
          JSON.stringify(role.permissions.application.controllers)
        );
        localStorage.setItem(
          'users-permissions',
          JSON.stringify(role.permissions['users-permissions'].controllers)
        );
      });
  },

  logout: () => {
    clearStorage();

    return Promise.resolve();
  },

  checkAuth: () => {
    return Cookies.getCookie('token') ? Promise.resolve() : Promise.reject();
  },

  checkError: ({ status }) => {
    if (status === 401) {
      clearStorage();

      return Promise.reject();
    } else if (status === 403) {
      window.location.href = `/#/403`;
    }

    return Promise.resolve();
  },

  getPermissions: () => {
    var permissions = localStorage.getItem('permissions');
    var userPermissions = localStorage.getItem('users-permissions');

    return permissions
      ? Promise.resolve(
          Object.assign(
            {},
            JSON.parse(permissions),
            JSON.parse(userPermissions)
          )
        )
      : Promise.reject();
  },

  getIdentity: () => {
    const user: any = JSON.parse(localStorage.getItem('user'));
    const userName: string = user.person
      ? `${user.person.firstName} ${user.person.fatherLastName}`
      : user.username;

    return Promise.resolve({
      id: 'user',
      fullName: userName,
    });
  },
};

function clearStorage() {
  Cookies.deleteCookie('role');
  Cookies.deleteCookie('user');
  Cookies.deleteCookie('token');
  Cookies.deleteCookie('employee');
  Cookies.deleteCookie('franchise');

  localStorage.clear();
}

export default authProvider;
