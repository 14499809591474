import React from 'react';
import {
  ArrayInput,
  ReferenceInput,
  required,
  FormDataConsumer,
  AutocompleteInput,
  SimpleFormIterator,
} from 'react-admin';
import { Grid } from '@material-ui/core';

const isRequired = [required()];

const Positions = ({
  className = '',
  source = 'positions',
  columns = 12,
  ...rest
}) => {
  return (
    <ArrayInput source={source} validation={isRequired} label='' {...rest}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return (
              <Grid container>
                <Grid item xs={columns}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'position.id' : 'position'
                    )}
                    reference='positions'
                    validate={isRequired}
                    variant='outlined'
                    fullWidth
                    label='Posición'
                  >
                    <AutocompleteInput optionText='name' />
                  </ReferenceInput>
                </Grid>
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default Positions;
