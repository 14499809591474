import { fetchUtils } from 'react-admin';
import Cookies from '../Utils/cookies';
import simpleDataProvider from './ra-strapi-rest';

const httpClient = (url, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = Cookies.getCookie('token');
  options.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleDataProvider(
  process.env.REACT_APP_API_URL,
  httpClient
);

export default dataProvider;
