import { makeStyles } from '@material-ui/core';

export const FormShow = makeStyles((theme) => {
  const fontSizeShowField = '15px';

  return {
    root: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    show: {
      height: 'max-content',
    },
    textColor: { color: theme.palette.text.primary },
    divider: {
      backgroundColor: '#979797',
      height: '33px',
      margin: '1px 25px 0px 30px',
      width: '2px',
    },
    buttonPosition: {
      marginInlineEnd: 'auto',
    },
    buttonSpacing: {
      margin: '0rem 0rem 1rem 0rem;',
    },
    borderBottom: {
      '& > div:last-child': {
        paddingBottom: theme.spacing(3),
      },
    },
    listItem: {
      padding: '0',
    },
    listButton: {
      padding: '10px',
      width: '100%',
      '& > span': { justifyContent: 'end' },
    },
    [theme.breakpoints.up('md')]: {
      flexParent: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      width100: {
        width: '100%',
        fontSize: fontSizeShowField,
        marginBottom: '10px',
      },
      width70: {
        width: '69%',
        fontSize: fontSizeShowField,
        marginBottom: '10px',
        marginRight: '1%',
      },
      width60: {
        width: '59%',
        fontSize: fontSizeShowField,
        marginBottom: '10px',
        marginRight: '1%',
      },
      width50: {
        width: '49%',
        fontSize: fontSizeShowField,
        marginBottom: '10px',
        marginRight: '1%',
      },
      width40: {
        width: '39%',
        fontSize: fontSizeShowField,
        marginBottom: '10px',
        marginRight: '1%',
      },
      width33: {
        width: '32%',
        '& .MuiTypography-body2': {
          fontSize: fontSizeShowField,
        },
        marginBottom: '10px',
        marginRight: '1%',
      },
      width30: {
        width: '29%',
        fontSize: fontSizeShowField,
        marginBottom: '10px',
        marginRight: '1%',
      },
      width25: {
        width: '24%',
        fontSize: fontSizeShowField,
        marginBottom: '10px',
        marginRight: '1%',
        '& > *': {
          width: '100%',
        },
      },
    },
    form: {
      '& section': {
        marginTop: '15px',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
      '& > label': {
        display: 'none',
      },
    },
    max: {
      maxWidth: '84%',
    },
  };
});

export const FormGeneral = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& [data-color=red] .MuiFormHelperText-contained': {
      color: 'red !important',
    },
    '& [data-color=green] .MuiFormHelperText-contained': {
      color: 'green !important',
    },
  },
  borderBottom: {
    '& > div:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  [theme.breakpoints.up('md')]: {
    flexParent: {
      '& > div:last-child > span': {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    width100: {
      width: '100%',
      marginRight: '1%',
    },
    width75: {
      width: '74%',
      marginRight: '1%',
    },
    width50: {
      width: '49%',
      marginRight: '1%',
    },
    width33: {
      width: '32%',
      marginRight: '1%',
    },
    width25: {
      width: '24%',
      marginRight: '1%',
    },
  },
  form: {
    '& section': {
      display: 'flex',
      marginTop: '15px',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    '& > label': {
      display: 'none',
    },
  },
  stylesForm: {
    flexBasis: 700,
    paddingBottom: 125,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));
