import React from 'react';
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  ChipField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import ListActions from '../../Components/ListActions';
import CustomList from '../../Components/CustomList';

import { StateType } from '../../Utils/enums';

const ENTITY_NAME = 'state-rule';

const StateRuleFilter = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput source='name' resettable alwaysOn />
    <ReferenceInput
      reference='loan-application-state'
      source='actualState'
      resettable
      filter={{
        stateTypeId: StateType.LoanApplication,
      }}
    >
      <SelectInput source='name' />
    </ReferenceInput>
    <ReferenceInput
      reference='loan-application-state'
      source='futureState'
      resettable
      sort={{ field: 'id', order: 'ASC' }}
      filter={{
        stateTypeId: StateType.LoanApplication,
      }}
    >
      <SelectInput source='name' />
    </ReferenceInput>
  </Filter>
);

export const StateRuleList = ({ permissions, ...props }) => {
  return (
    <CustomList
      filters={<StateRuleFilter />}
      actions={
        <ListActions entity='state-rule' permissions={permissions} {...props} />
      }
      {...props}
      hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
    >
      <Datagrid rowClick='show'>
        <TextField source='code_id' label="Id" />
        <TextField source='name' />
        <ReferenceField source='actualState' reference='loan-application-states' link={false}>
          <ChipField source='name' size='small' />
        </ReferenceField>
        <ReferenceField source='futureState' reference='loan-application-states' link={false}>
          <ChipField source='name' size='small' />
        </ReferenceField>
      </Datagrid>
    </CustomList>
  );
};

export default StateRuleList;
