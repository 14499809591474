import { ChevronLeft } from "@material-ui/icons";
import React from "react";
import { Edit, ListButton, TopToolbar } from "react-admin";
import { GuaranteeTypeForm } from "./GuaranteeTypeForm";

const GuaranteeTypeActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton 
      basePath={basePath} 
      label="ra.action.back"
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const GuaranteeTypeEdit = (props: any) => (
  <Edit actions={<GuaranteeTypeActions {...props} />} {...props} >
    <GuaranteeTypeForm {...props} />
  </Edit>
);

export default GuaranteeTypeEdit;