import React from "react";
import { Edit, ListButton, TopToolbar } from "react-admin";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ApplicationTypeSettingForm from "./ApplicationTypeSettingForm";


const ApplicationTypeSettingActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const ApplicationTypeSettingEdit = (props: any) => (
  <Edit actions={<ApplicationTypeSettingActions {...props} />} {...props}>
    <ApplicationTypeSettingForm {...props} />
  </Edit>
);

export default ApplicationTypeSettingEdit;
