import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import PersonList from './PersonList';
import PersonCreate from './PersonCreate';
import PersonEdit from './PersonEdit';
import PersonShow from './PersonShow';

export const People: object = {
  list: PersonList,
  create: PersonCreate,
  edit: PersonEdit,
  show: PersonShow,
  icon: PeopleAltIcon,
};

export default People;
