import React from 'react';

import {
  ReferenceInput,
  required,
  SelectInput,
  useTranslate,
} from 'react-admin';

import { Grid } from '@material-ui/core';

export const LoanReferenceForm = ({ className = '', ...rest }) => {
  const translate = useTranslate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <ReferenceInput
          source='referenceId'
          reference='references'
          filter={rest.filter}
          fullWidth
          label={translate('resources.references.fields.name')}
          validate={[required()]}
          variant='outlined'
        >
          <SelectInput multiline />
        </ReferenceInput>
      </Grid>
    </Grid>
  );
};
