import React from "react";
import { Box, Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import { ArrayInput, AutocompleteInput, FormWithRedirect, ReferenceInput, SaveButton, SelectInput, SimpleFormIterator, TextInput, Toolbar, required, useNotify, useRedirect, useRefresh } from 'react-admin'
import { useState } from 'react';
import { canShowSaveButton } from "../../Utils/MaintenancePersmissionsUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  })
);

const isRequired = [required()];

export const RoleStateForm: React.FC<any> = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState<any>();
  return (
    <FormWithRedirect
      { ...props }
      render={(formProps) => {
        if (!values) setValues(formProps.record);
        return (
          <>
            <Grid container spacing={2} classes={{ root: classes.root }}>
                <Grid item xs={12}>
                  <Typography variant='h4' gutterBottom>
                    General
                  </Typography>
                  <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <ReferenceInput
                          source={formProps.record?.role?.id ? 'role.id' : 'role'}
                          resource='segment_roles'
                          reference='users-permissions/roles'
                          fullWidth
                          validate={isRequired}
                          variant='outlined'
                          label="Rol"
                        >
                          <AutocompleteInput
                            optionText={(record) => record && `${record.name}`}
                            optionValue='id'
                          />
                        </ReferenceInput>
                      </Grid>
                      <Grid item xs={12}>
                        <ArrayInput source='state' label="Estados de solicitudes">
                          <SimpleFormIterator>
                            <ReferenceInput
                              source={formProps.record?.id ? 'id' : ''}
                              reference='loan-application-states'
                              validate={required()}
                              variant='outlined'
                              label=''
                              fullWidth
                            >
                              <SelectInput />
                            </ReferenceInput>
                          </SimpleFormIterator>
                        </ArrayInput>
                      </Grid>
                  </Grid>
                </Grid>
            </Grid>
            { canShowSaveButton(props) && 
              <Toolbar>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <SaveButton
                    saving={formProps.saving}
                    disabled={formProps.invalid}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    redirect='list'
                  />
                </Box>
              </Toolbar>
            }
          </>
        );
      }}
    >
    </FormWithRedirect>
  );
}

export default RoleStateForm;