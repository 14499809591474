import React from "react";
import { Box, Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import { FormWithRedirect, required, maxLength } from "ra-core";
import { BooleanInput, SaveButton, SelectInput, TextInput, Toolbar } from "react-admin";
import { canShowSaveButton } from "../../Utils/MaintenancePersmissionsUtils";
import { personTypes } from "../../Utils/enums";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      marginBottom: '10px',
      '& > p': {
        display: 'none',
      },
    },
  })
);

interface CreditTypeInterface {
  props?:object
};

const isRequired = [required(), maxLength(50)];

export const CreditTypeForm: React.FC<CreditTypeInterface> = (props) => {
  const classes = useStyles();

  return (
    <FormWithRedirect 
      {...props}
      render={(formProps) => {
        return (
          <>
            <Grid container spacing={2} classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  General
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput 
                      fullWidth
                      source="name"
                      resource="credit-types"
                      validate={isRequired}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput 
                      fullWidth
                      source="personType"
                      resource="credit-types"
                      validate={isRequired}
                      variant="outlined"
                      choices={personTypes}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BooleanInput
                      source='active'
                      defaultValue={true}
                      resource='credit-types'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              canShowSaveButton(props) &&
              <Toolbar>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <SaveButton 
                    saving={formProps.saving}
                    disabled={formProps.invalid}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    redirect='list'
                  />
                </Box>
              </Toolbar>
            }
          </>
        )
      }}
    />
  );
};