import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import GuaranteePanelList from './GuaranteePanelList';
import GuaranteePanelShow from './GuaranteePanelShow';

export const GuaranteePanel: object = {
  list: GuaranteePanelList,
  icon: PersonAddDisabledIcon,
  show: GuaranteePanelShow,
};

export default GuaranteePanel;
