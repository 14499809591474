import React, { useState } from 'react';
import { Button, useGetList } from 'react-admin';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';

import { Theme } from '../../Theme';
import Cookies from '../../Utils/cookies';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '60vw',
    maxWidth: '60vw',
    height: '80vh',
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
  },
}));

interface Img {
  url: string;
  id: number;
}

const PromotionModal = () => {
  const classes = useStyles();
  const { data, loading } = useGetList('promotions/active');
  const recentlyLogged = Boolean(Cookies.getCookie('loginTime'));
  const [open, setOpen] = useState(recentlyLogged);
  const images = [];

  for (const item in data) {
    images.push(data[item]);
  }

  const handleClose = () => {
    setOpen(false);
    Cookies.deleteCookie('loginTime');
  };

  return (
    <Dialog
      open={open && Boolean(images.length)}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      maxWidth='xs'
      classes={{ paper: classes.root }}
      fullWidth
    >
      <MuiDialogTitle id='form-dialog-title' disableTypography>
        <Typography variant='h4'>Promociones</Typography>
      </MuiDialogTitle>
      <DialogContent>
        {loading ? (
          <div
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <CircularProgress style={{ margin: 'auto' }} />
          </div>
        ) : (
          <>
            {images.map((promotion: Img, index) => (
              <img
                style={{
                  width: '100%',
                  borderRadius: '4px',
                  marginBlock: '10px',
                }}
                src={promotion.url}
                alt={`promocion-${index}`}
              />
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          label='Confirmar'
          onClick={() => handleClose()}
          color='primary'
        />
      </DialogActions>
    </Dialog>
  );
};

export default PromotionModal;
