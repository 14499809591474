import React from 'react';
import { Edit, ListButton, TopToolbar } from 'react-admin';
import UserForm from './UserForm';

import Close from '@material-ui/icons/Close';

const UserActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton basePath={basePath} label='ra.action.close' icon={<Close />} />
  </TopToolbar>
);

const UserEdit = (props: any) => {
  return (
    <Edit actions={<UserActions {...props} />} {...props}>
      <UserForm />
    </Edit>
  );
};

export default UserEdit;
