import { Create, ListButton, TopToolbar } from 'react-admin';

import ChevronLeft from '@material-ui/icons/ChevronLeft';

import RelatedTypeForm from './RelatedTypeForm';

const RelatedTypeActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const RelatedTypeCreate = (props: any) => {
  return (
    <Create actions={<RelatedTypeActions {...props} />} {...props}>
      <RelatedTypeForm {...props} />
    </Create>
  );
};

export default RelatedTypeCreate;
