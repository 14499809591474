import { ChevronLeft } from "@material-ui/icons";
import React from "react";
import { Edit, ListButton, TopToolbar } from "react-admin";
import { CreditTypeForm } from "./CreditTypeForm";

const CreditTypeActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton 
      basePath={basePath} 
      label="ra.action.back"
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const CreditTypeEdit = (props: any) => (
  <Edit actions={<CreditTypeActions {...props} />} {...props} >
    <CreditTypeForm {...props} />
  </Edit>
);

export default CreditTypeEdit;