import * as React from 'react';
import { TabbedShowLayout, Tab, TextField } from 'react-admin';

import { FormShow } from '../../styles';

export const GuaranteeShow = () => {
  const classes = FormShow();

  return (
    <TabbedShowLayout className={classes.borderBottom}>
      <Tab label='Observaciones' contentClassName={classes.flexParent}>
        <TextField source='observation' emptyText='-' label='' />
      </Tab>
    </TabbedShowLayout>
  );
};

export default GuaranteeShow;
