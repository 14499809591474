import React, { useState } from 'react';
import {
  ArrayField,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useTranslate,
} from 'react-admin';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { AssignStateLoanReference } from './AssignStateLoanReference';
import { FormShow } from '../../../styles';
import FormTitle from '../../../Components/FormTitle';
import TablePlaceholder from '../../../Components/TablePlaceholder';
import { Button } from '@material-ui/core';
import { Theme } from '../../../Theme';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogActions: {
    padding: theme.spacing(1, 3),
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const LoanReferenceList = (props: any) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [loanReference, setLoanReference] = useState<any>();
  const classes = useStyles();
  const handleClickOpenClose = () => setOpenDialog(!openDialog);
  const handleClickButtonRecord = (record: any) => () => {
    setLoanReference({
      id: record?.reference?.id,
      name: record?.reference?.name,
    });
    handleClickOpenClose();
    return '';
  };
  const translate = useTranslate();
  return (
    <>
      <AssignStateLoanReference
        loanApplication={props.record?.id}
        loanReference={loanReference}
        openDialog={openDialog}
        handleClickOpenClose={handleClickOpenClose}
      />
      <ReferenceManyField
        reference='loan-references'
        addLabel={false}
        target='reference'
        filter={{ loanApplication: props.record?.id }}
      >
        <List bulkActionButtons={false} actions={false} {...props}>
          <Datagrid
            expand={<ExpandGridContacts />}
          >
            <TextField source='reference.name' label='Nombre' />
            <ReferenceField
              source='reference.referenceType'
              emptyText='-'
              reference='reference-types'
              label={translate('resources.references.fields.referenceType')}
              link={false}
            >
              <TextField source='name' />
            </ReferenceField>
            <TextField source='state.name' emptyText='-' label='Estado' />
          </Datagrid>
        </List>
      </ReferenceManyField>
    </>
  );
};

const ExpandGridContacts = () => {
  const classes = FormShow();
  const translate = useTranslate();
  return (
    <>
      <FormTitle title='Contactos' className={classes.width100} />
      <ArrayField
        fieldKey='id'
        addLabel={false}
        label=''
        source='reference.contacts'
        className={classes.width100}
      >
        <Datagrid empty={<TablePlaceholder title='Contactos' />}>
          <TextField
            source='contactType.name'
            emptyText='-'
            label={translate('resources.contacts.fields.contactType')}
          />
          <TextField
            source='contact'
            emptyText='-'
            label={translate('resources.contacts.fields.contact')}
          />
        </Datagrid>
      </ArrayField>
    </>
  );
};
