import Cookies from '../Utils/cookies';

const documentProvider = {
  uploadDocument: async (formDataFile) => {
    const token = Cookies.getCookie('token');
    return fetch(`${process.env.REACT_APP_API_URL}/documents`, {
      method: 'POST',
      body: formDataFile,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  editDocument: async (formDataFile, documentId) => {
    const token = Cookies.getCookie('token');
    return fetch(`${process.env.REACT_APP_API_URL}/upload?id=${documentId}`, {
      method: 'POST',
      body: formDataFile,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  downloadDocument: async (documentId) => {
    const token = Cookies.getCookie('token');
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/documents/${documentId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const blobFile = await response.blob();
    const fileURL = URL.createObjectURL(blobFile);

    return window.open(fileURL);
  },
  downloadDocumentZip: async (loanApplicationId) => {
    const token = Cookies.getCookie('token');
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/documents/download-documents-clients/${loanApplicationId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (res) => {
      const parsedData = await res.json();
      if (parsedData.statusCode === 403) {
        window.location.href = `/#/403`;
        return;
      }

      return parsedData;
    });
    return response;
  },
};

export default documentProvider;
