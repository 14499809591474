import React from 'react';
import { Edit, TabbedForm, TopToolbar, ListButton, FormTab } from 'react-admin';
import CustomToolbar from '../../Components/CustomToolbar';

import IncomeForm from './IncomeForm';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const Actions = ({ data }: any) => (
  <TopToolbar>
    <ListButton
      to={
        data?.person
          ? `/people/${data?.person}/show/incomes`
          : `/legal-people/${data?.legalPerson}/show/incomes`
      }
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const redirect = (basePath, id, data) =>
  data.person
    ? `/people/${data.person}/show/incomes`
    : `/legal-people/${data.legalPerson}/show/incomes`;

const IncomeEdit = (props) => {
  return (
    <Edit title='Income' {...props} actions={<Actions {...props} />}>
      <TabbedForm
        redirect={redirect}
        variant='outlined'
        toolbar={<CustomToolbar />}
      >
        <FormTab label='Ingresos'>
          <IncomeForm />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default IncomeEdit;
