import { Link } from '@material-ui/core';
import React from 'react';
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  EditButton,
  DeleteWithConfirmButton,
  AutocompleteInput,
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';

import CustomList from '../../Components/CustomList';
import FullNameField from '../../Components/FullNameField';
import ListActions from '../../Components/ListActions';

import { StateType } from '../../Utils/enums';
import EmployeeShow from './EmployeeShow';

const ENTITY_NAME = 'employee';

const EmployeesFilter = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput source='code' resettable alwaysOn />
    <ReferenceInput
      reference='states'
      source='state'
      resettable
      filter={{
        stateTypeId: StateType.Employee,
      }}
    >
      <SelectInput source='name' />
    </ReferenceInput>
    <ReferenceInput reference='departments' source='department' resettable>
      <AutocompleteInput source='name' />
    </ReferenceInput>
    <ReferenceInput reference='positions' source='position' resettable>
      <AutocompleteInput source='name' />
    </ReferenceInput>
  </Filter>
);

export const EmployeesList = ({ permissions, ...props }) => {

  return (
    <CustomList
      actions={
        <ListActions entity='employee' permissions={permissions} {...props} />
      }
      filters={<EmployeesFilter />}
      {...props}
      hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
    >
      <Datagrid expand={<EmployeeShow />}>
        <TextField source='code' resource='employees' />
        <FunctionField
          source='person'
          render={(record) => (
            <Link
              component={RouterLink}
              to={`/people/${record.person?.id}/show`}
              variant='inherit'
              color='textPrimary'
              underline='none'
            >
              <FullNameField
                record={record}
                entity='person'
                source='person'
                size={24}
              />
            </Link>
          )}
        />
        <TextField
          source='department.name'
          resource='employees'
          emptyText='-'
        />
        <TextField source='position.name' resource='employees' emptyText='-' />
        <TextField source='state.name' resource='employees' emptyText='-' />
        <FunctionField
          render={(record) => (
            <>
              { permissions.employee?.update?.enabled && 
                <EditButton record={record} label='' />
              }
              { permissions.employee?.delete?.enabled && 
                <DeleteWithConfirmButton
                  record={record}
                  redirect={false}
                  label=''
                  confirmContent='¿Está seguro? '
                  confirmTitle='Dar de baja'
                />
              }
            </>
          )}
        />
      </Datagrid>
    </CustomList>
  );
};

export default EmployeesList;
