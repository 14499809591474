import AccountTreeIcon from '@material-ui/icons/AccountTree';

import SubstateList from './SubstateList';

export const Substates: object = {
  list: SubstateList,
  icon: AccountTreeIcon,
};

export default Substates;
