import FolderIcon from '@material-ui/icons/Folder';

import DocumentTypeList from './DocumentTypeList';

export const DocumentType: object = {
  list: DocumentTypeList,
  icon: FolderIcon,
};

export default DocumentType;
