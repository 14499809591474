import React from 'react';
import {
  Create,
  TabbedForm,
  FormTab,
  TopToolbar,
  ListButton,
} from 'react-admin';

import { parse } from 'query-string';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { LoanReferenceForm } from './LoanReferenceForm';
import { errors, thereWasProblem } from '../../Utils/messages';
import { useNotify } from 'react-admin';

const userTypeFromLocationSearch = (person: any, legalPerson: any) => {
  if (!person && !legalPerson) return {};
  if (person) return { person };
  return { legalPerson };
};

const Actions = ({ loanApplication }) => (
  <TopToolbar>
    <ListButton
      to={`/loan-applications/${loanApplication}/show/references`}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

export const LoanReferenceCreate = (props: any) => {
  const {
    person,
    'legal-person': legalPerson,
    loanApplication,
  } = parse(props.location.search);
  const notify = useNotify();

  const onFailure = (error) =>
    notify(
      `${errors[error.body?.data?.errorCode] || thereWasProblem}`,
      'warning'
    );

  return (
    <Create
      title=''
      {...props}
      onFailure={onFailure}
      actions={<Actions loanApplication={loanApplication}></Actions>}
    >
      <TabbedForm
        initialValues={{ loan_applicationId: parseInt(loanApplication) }}
        redirect={`/loan-applications/${loanApplication}/show/references`}
        variant='outlined'
      >
        <FormTab label='Referencia'>
          <LoanReferenceForm
            filter={{
              ...userTypeFromLocationSearch(person, legalPerson),
            }}
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
