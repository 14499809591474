import MapIcon from '@material-ui/icons/Map';

import AddressTypeList from './AddressTypeList';

export const AddressType: object = {
  list: AddressTypeList,
  icon: MapIcon,
};

export default AddressType;
