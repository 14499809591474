import { Create, ListButton, TopToolbar } from 'react-admin';

import ChevronLeft from '@material-ui/icons/ChevronLeft';

import AcademicLevelForm from './AcademicLevelForm';

const AcademicLevelActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const AcademicLevelCreate = (props: any) => {
  return (
    <Create actions={<AcademicLevelActions {...props} />} {...props}>
      <AcademicLevelForm {...props} />
    </Create>
  );
};

export default AcademicLevelCreate;
