import React, { useState } from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SelectInput,
  useTranslate,
} from 'react-admin';

import { Grid } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { PersonType, PersonTypeName } from '../../Utils/enums';

const isRequired = [required()];

const RelatedForm = ({ className = '', ...rest }) => {
  const translate = useTranslate();
  const form = useForm();
  const [values, setValues] = useState(form.getState().values);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <ReferenceInput
            source='relatedType'
            reference='related-types'
            fullWidth
            label={translate('resources.related.fields.relatedType')}
            validate={isRequired}
            variant='outlined'
          >
            <SelectInput />
          </ReferenceInput>
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            source={rest.record?.id ? 'personType.id' : 'personType'}
            fullWidth
            helperText='Puedes agregar un relacionado físico o moral.'
            label={translate('resources.related.fields.personType')}
            variant='outlined'
            initialValue={
              values?.relatedLegalPerson
                ? PersonType.LegalPerson
                : PersonType.PhysicalPerson
            }
            onChange={() => {
              setValues(form.getState().values);
              form.change('relatedPerson', null);
              form.change('relatedLegalPerson', null);
            }}
            choices={[
              {
                id: PersonType.PhysicalPerson,
                name: PersonTypeName.PhysicalPerson,
              },
              {
                id: PersonType.LegalPerson,
                name: PersonTypeName.LegalPerson,
              },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          {values?.personType?.id === PersonType.LegalPerson ||
          values?.personType === PersonType.LegalPerson ||
          values?.relatedLegalPerson ? (
            <ReferenceInput
              source='relatedLegalPerson'
              reference='legal-people'
              label='Buscar persona moral'
              validate={isRequired}
              fullWidth
              variant='outlined'
            >
              <AutocompleteInput
                helperText='Los resultados de la busqueda están sujetos al tipo de persona seleccionada, adicional la persona debe estar previamente creada en el sistema.'
                optionText={(record) =>{
                    if(
                      (values?.legalPerson?.id === record?.id ||
                        values?.legalPerson === record?.id) &&
                        (values?.personType?.id !== PersonType.LegalPerson ||
                      values?.personType !== PersonType.LegalPerson)
                    ) return
                    return record && `${record.documentNumber} - ${record.businessName}`
                  }
                }
              />
            </ReferenceInput>
          ) : (
            <ReferenceInput
              source='relatedPerson'
              reference='people'
              label='Buscar persona física'
              validate={isRequired}
              fullWidth
              variant='outlined'
            >
              <AutocompleteInput
                helperText='Los resultados de la busqueda están sujetos al tipo de persona seleccionada, adicional la persona debe estar previamente creada en el sistema.'
                optionText={(record) =>{
                    if(
                      (values?.person === record?.id ||
                        values?.person?.id === record?.id) &&
                      (values?.personType?.id !== PersonType.PhysicalPerson ||
                      values?.personType !== PersonType.PhysicalPerson)
                    ) return
                    return record && `${record.documentNumber} - ${record.firstName} ${record.fatherLastName}`
                  }
                }
              />
            </ReferenceInput>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default RelatedForm;
