import HomeWorkIcon from '@material-ui/icons/HomeWork';

import AssetTypeList from './AssetTypeList';

export const AssetType: object = {
  list: AssetTypeList,
  icon: HomeWorkIcon,
};

export default AssetType;
