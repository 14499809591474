import { Edit, ListButton, TopToolbar } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import AcademicLevelForm from './AcademicLevelForm';

const AcademicLevelActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const AcademicLevelEdit = (props: any) => {
  return (
    <Edit actions={<AcademicLevelActions {...props} />} {...props}>
      <AcademicLevelForm {...props} />
    </Edit>
  );
};

export default AcademicLevelEdit;
