import { useState } from 'react';
import { parse } from 'query-string';
import {
  Login,
  useNotify,
  Button,
  SimpleForm,
  required,
  TextInput,
  PasswordInput,
  useRedirect,
} from 'react-admin';
import {
  makeStyles,
  Typography,
  Card,
  CardContent,
  Box,
  Divider,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import loginBackground from '../assets/img/login-background.jpg';
import useSettings from '../hooks/useSettings';
import { createTheme, Theme } from '../Theme';
import { thereWasProblem } from '../Utils/messages';
import authProvider from '../Providers/authProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingInline: theme.spacing(0.5),
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
  },
  cardContainer: {
    width: '40vw',
    maxWidth: '520px',
    WebkitBoxShadow: 'none',
  },
  cardContent: {
    padding: theme.spacing(2, 4, 4, 4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 300,
  },
  content: { width: '100%', '& > *': { padding: '0 !important' } },
  field: { margin: '0 0 15px', '& > p': { display: 'none' } },
  buttonSpacing: {
    margin: '0rem 0rem 1rem 0rem;',
  },
}));

const CustomLogin = (props) => {
  const { code: codeString } = parse(props.location.search);
  const reset = codeString ? true : false;

  const { settings } = useSettings();
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(reset);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  const sendEmail = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    }).then(async (res) => {
      const parsedData = await res.json();
      if (parsedData.statusCode <= 400) {
        setIsLoading(false);
        switch (parsedData?.message[0]?.messages[0]?.id) {
          case 'Auth.form.error.email.format':
            notify(`${thereWasProblem}: Correo inválido.`, 'warning');
            break;
          case 'Auth.form.error.user.not-exist':
            notify(`${thereWasProblem}: Correo inexistente.`, 'warning');
            break;
          default:
            notify('Fallo general.', 'warning');
            break;
        }
        return;
      }

      notify('Se le ha enviado un correo.');
      setIsLoading(false);
      setIsResetting(false);
    });
  };

  const changePassword = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
      method: 'POST',
      body: JSON.stringify({
        code: codeString,
        password: newPassword,
        passwordConfirmation: newPasswordConfirm,
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    }).then(async (res) => {
      const parsedData = await res.json();
      if (parsedData.statusCode <= 400) {
        setIsLoading(false);
        switch (parsedData?.message[0]?.messages[0]?.id) {
          case 'Auth.form.error.code.provide':
            notify(`${thereWasProblem}: Intento no autorizado.`, 'warning');
            break;
          case 'Auth.form.error.password.matching':
            notify(
              `${thereWasProblem}: Las contraseñas no coinciden.`,
              'warning'
            );
            break;
          default:
            notify('Fallo general.', 'warning');
            break;
        }
        return;
      }

      notify('Se le ha cambiado la contraseña.');
      setIsLoading(false);
      setIsResetting(false);
    });
  };

  return (
    <Login backgroundImage={loginBackground} theme={theme}>
      <Card className={classes.cardContainer}>
        <CardContent className={classes.cardContent}>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            mb={3}
          >
            <div>
              <Typography color='textPrimary' gutterBottom variant='h2'>
                {isResetting ? 'Reiniciar contraseña' : 'Iniciar sesión'}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {!isResetting
                  ? 'Ingresa tus credenciales para entrar al sistema.'
                  : reset
                  ? 'Ingresa la nueva contraseña.'
                  : 'Ingresa tu correo institucional.'}
              </Typography>
            </div>
          </Box>
          {isResetting ? (
            <Box flexGrow={1} mt={3}>
              <Box display='flex' justifyContent='center'>
                <SimpleForm toolbar={<></>} className={classes.content}>
                  {!reset && (
                    <>
                      <TextInput
                        source='email'
                        label='Correo'
                        variant='outlined'
                        validate={[required()]}
                        onChange={(e) => setEmail(e.target.value)}
                        className={classes.field}
                        fullWidth
                      />
                      <Button
                        label={isLoading ? '' : 'Enviar'}
                        onClick={() => sendEmail()}
                        disabled={!email}
                        startIcon={
                          isLoading && (
                            <CircularProgress size={20} color='info' />
                          )
                        }
                        variant='contained'
                        color='secondary'
                        fullWidth
                      />
                    </>
                  )}
                  {reset && (
                    <>
                      <PasswordInput
                        source='newPassword'
                        variant='outlined'
                        label='Nueva contraseña'
                        onChange={(e) => setNewPassword(e.target.value)}
                        validate={[required()]}
                        className={classes.field}
                        fullWidth
                      />
                      <PasswordInput
                        source='confirmPassword'
                        variant='outlined'
                        label='Confirme la contraseña'
                        onChange={(e) => setNewPasswordConfirm(e.target.value)}
                        validate={[required()]}
                        className={classes.field}
                        fullWidth
                      />
                      <Button
                        label={isLoading ? '' : 'Cambiar'}
                        onClick={() => changePassword()}
                        disabled={!newPassword || !newPasswordConfirm}
                        variant='contained'
                        color='secondary'
                        startIcon={
                          isLoading && (
                            <CircularProgress size={20} color='info' />
                          )
                        }
                        fullWidth
                      />
                    </>
                  )}
                </SimpleForm>
              </Box>
            </Box>
          ) : (
            <Box flexGrow={1} mt={3}>
              <Box display='flex' justifyContent='center'>
                <SimpleForm toolbar={<></>} className={classes.content}>
                  <TextInput
                    source='username'
                    variant='outlined'
                    label='Usuario'
                    onChange={(e) => setUsername(e.target.value)}
                    validate={[required()]}
                    className={classes.field}
                    fullWidth
                  />
                  <PasswordInput
                    source='password'
                    variant='outlined'
                    label='Contraseña'
                    onChange={(e) => setPassword(e.target.value)}
                    validate={[required()]}
                    className={classes.field}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setIsLoading(true);
                        authProvider
                          .login({
                            username,
                            password,
                          })
                          .then(() => {
                            redirect('/#');
                            setIsLoading(false);
                          })
                          .catch(() => {
                            notify('Fallo en la autenticación', 'warning');
                            setIsLoading(false);
                          });
                      }
                    }}
                    fullWidth
                  />
                  <Button
                    label={isLoading ? '' : 'Acceder'}
                    onClick={() => {
                      setIsLoading(true);
                      authProvider
                        .login({
                          username,
                          password,
                        })
                        .then(() => {
                          redirect('/#');
                          setIsLoading(false);
                        })
                        .catch(() => {
                          notify('Fallo en la autenticación', 'warning');
                          setIsLoading(false);
                        });
                    }}
                    startIcon={
                      isLoading && <CircularProgress size={20} color='info' />
                    }
                    variant='contained'
                    disabled={!username || !password}
                    color='secondary'
                    fullWidth
                  />
                </SimpleForm>
              </Box>
            </Box>
          )}
          <Box my={3}>
            <Divider />
          </Box>
          <Box display='flex'>
            <div>
              <Typography
                onClick={() => setIsResetting(!isResetting)}
                variant='body2'
                color='textSecondary'
                style={{ cursor: 'pointer' }}
              >
                {isResetting ? 'Cancelar' : 'Cambiar contraseña'}
              </Typography>
            </div>
          </Box>
        </CardContent>
      </Card>
    </Login>
  );
};

export default CustomLogin;
