import React from 'react';
import {
  ArrayField,
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  ListButton,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useTranslate,
  useNotify,
  useGetOne,
  usePermissions,
} from 'react-admin';

import {
  makeStyles,
  Typography,
  withStyles,
  Link as MuiLink,
} from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { FormShow } from '../../styles';
import { DocumentUploadButton } from '../Documents';
import { DocumentList } from '../Documents';
import FormTitle from '../../Components/FormTitle';
import PersonNipButton from './PersonNipButton';
import FullNameField from '../../Components/FullNameField';
import CreditCircleRetry from '../../Components/CreditCircleRetry';
import ResendNipButton from '../../Components/ResendNipButton';
import TablePlaceholder from '../../Components/TablePlaceholder';
import ReferenceShow from '../References/ReferenceShow';
import CustomDateInput from '../../Components/CustomDateInput';
import CIECRequestButton from './CIECRequestButton';

import {
  DocumentSegment,
  RepresentativeTypeNames,
  UnykooState,
  entities,
  personType,
} from '../../Utils/enums';
import { errors, thereWasProblem } from '../../Utils/messages';
import { Timeline } from '../../Components/Timeline';
import { deletePreviousPath, getUserFromLS } from '../../Utils/functions';
import { CONF_ENABLE_CREDIT_BUREAU } from '../../constants';
import { PERSON_CONF } from './PersonConf';

const styles = {
  button: {
    marginTop: '1em',
  },
};

const useStyles = makeStyles((theme) => ({
  title: {
    letterSpacing: '1px',
    color: theme.palette.text.secondary,
    padding: '0 0.2rem 0.2rem',
    borderRadius: 4,
    borderBottom: `${theme.palette.divider} 1px solid`,
    fontSize: '1rem',
  },
  guaranteed: {
    color: theme.palette.text.secondary,
    alignSelf: 'center',
  },
}));

const AddNewButton = withStyles(styles)(
  ({ classes, record, label, path }: any) => (
    <Button
      className={classes.button}
      component={Link}
      to={{
        pathname: `/${path}/create`,
        search: `?person=${record.id}`,
      }}
      label={label}
    >
      <AddCircleOutlineIcon />
    </Button>
  )
);

const PersonShowActions = ({ basePath, data, permissions }: any) => {
  const classes = useStyles();
  const user = getUserFromLS();
  const { isAdmin } = user?.role || {};
  const validateEdit =
    isAdmin ||
    (permissions?.person?.update?.enabled && !data?.loanApplications?.length);

  return (
    <TopToolbar
      style={{
        justifyContent: `${
          data?.agent || data?.guaranteed ? 'space-between' : 'flex-end'
        }`,
      }}
    >
      {data?.agent && (
        <Typography component='div' variant='h6' className={classes.title}>{`${
          data?.agent?.code ?? ''
        } | ${data?.agent?.person?.firstName ?? ''} ${
          data?.agent?.person?.fatherLastName ?? ''
        }`}</Typography>
      )}
      {data?.guaranteed && (
        <Typography component='div' variant='h6' className={classes.guaranteed}>
          {`Esta persona se encuentra como garante de la ${
            personType[data?.guaranteed?.entity]
          } ${data?.guaranteed?.id}`}
        </Typography>
      )}
      <div>
        {permissions?.person?.requestciec?.enabled && (
          <CIECRequestButton person={data?.id} />
        )}
        <ListButton
          basePath={basePath}
          label='ra.action.back'
          icon={<ChevronLeft />}
          component={Link}
          to={basePath}
        />
        {validateEdit && <EditButton to={`${basePath}/${data?.id}`} />}
      </div>
    </TopToolbar>
  );
};

const PersonShow = ({ ...props }) => {
  const classes = FormShow();
  const translate = useTranslate();
  const notify = useNotify();
  const tab = props.location.pathname.split('show/')[1];
  const { data } = useGetOne('people', props.id);
  const { permissions } = usePermissions();

  return (
    <Show
      {...props}
      actions={<PersonShowActions {...props} />}
      aside={
        tab !== 'incomes' && tab !== 'assets' ? (
          <Timeline segment='person' />
        ) : (
          <></>
        )
      }
    >
      <TabbedShowLayout className={classes.borderBottom}>
        {renderGeneral()}
        {permissions?.['loan-application'].find.enabled && renderApplications()}
        {CONF_ENABLE_CREDIT_BUREAU && renderCC()}
        {permissions?.document.find.enabled && renderDocuments()}
        {permissions?.['loan-references'].find.enabled && renderReferences()}
        {permissions?.related.find.enabled && renderRelateds()}
        {permissions?.income.find.enabled && renderIncomes()}
        {permissions?.asset.find.enabled && renderAssets(permissions)}
      </TabbedShowLayout>
    </Show>
  );

  function renderGeneral() {
    const role = JSON.parse(localStorage.getItem('user') || '{}').role.id;
    return (
      <Tab label='General' contentClassName={classes.flexParent}>
        <FormTitle title='Información personal' className={classes.width100} />
        <TextField className={classes.width25} source='code_id' label='Id' />
        <TextField className={classes.width25} source='firstName' />
        <TextField
          className={classes.width25}
          source='secondName'
          emptyText='-'
        />
        <TextField className={classes.width25} source='fatherLastName' />
        <TextField
          className={classes.width25}
          source='motherLastName'
          emptyText='-'
        />
        <TextField
          source='documentType.name'
          className={classes.width25}
          emptyText='-'
        />
        <TextField source='documentNumber' className={classes.width25} />
        <FunctionField
          source='birthday'
          className={classes.width25}
          render={() => <CustomDateInput source='birthday' />}
        />
        <TextField
          source='nationality.nationality'
          className={classes.width25}
          emptyText='-'
        />
        <TextField source='sex' className={classes.width25} emptyText='-' />
        <TextField
          source='maritalStatus.name'
          className={classes.width25}
          emptyText='-'
        />
        <TextField
          source='academicLevel.name'
          className={classes.width25}
          emptyText='-'
        />
        <TextField
          source='profession.name'
          className={classes.width25}
          emptyText='-'
        />
        <TextField
          source='professionName'
          className={classes.width25}
          label={'Otra profesión'}
          emptyText='-'
        />
        <TextField
          source='occupation.name'
          className={classes.width25}
          emptyText='-'
        />
        <TextField
          source='economicActivity.name'
          className={classes.width25}
          emptyText='-'
        />
        <BooleanField
          source='isEmployee'
          className={classes.width25}
          emptyText='-'
        />
        <BooleanField
          source='isClient'
          className={classes.width25}
          emptyText='-'
        />
        <BooleanField
          source='isFranchiseManager'
          label={'¿Es encargado de franquicia?'}
          className={classes.width25}
          emptyText='-'
        />
        {permissions?.person?.requestciec?.enabled && (
          <TextField
            source='ciec'
            resource='people'
            emptyText='-'
            className={classes.width25}
          />
        )}
        <NumberField
          source='billingIncome'
          emptyText='-'
          className={classes.width25}
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <NumberField
          source='statementIncome'
          emptyText='-'
          className={classes.width25}
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <NumberField
          source='financialInformationIncome'
          emptyText='-'
          className={classes.width25}
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <NumberField
          source='onboardingGuaranteeValue'
          emptyText='-'
          className={classes.width25}
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <TextField
          source='onboardingGuaranteeType'
          className={classes.width25}
          emptyText='-'
        />

        <BooleanField
          source='blacklist'
          className={classes.width25}
          emptyText='-'
        />
        {PERSON_CONF.sections.other_documents && (
          <>
            <FormTitle title='Otros documentos' className={classes.width100} />
            <ArrayField
              addLabel={false}
              source='otherDocuments'
              fieldKey='id'
              className={classes.width100}
            >
              <Datagrid empty={<TablePlaceholder title='Otros documentos' />}>
                <TextField
                  emptyText='-'
                  source='documentType.name'
                  label={translate(
                    'resources.other-documents.fields.documentType'
                  )}
                />
                <TextField
                  emptyText='-'
                  source='documentNumber'
                  label={translate(
                    'resources.other-documents.fields.documentNumber'
                  )}
                />
              </Datagrid>
            </ArrayField>
          </>
        )}
        <FormTitle title='Contactos' className={classes.width100} />
        <ArrayField
          addLabel={false}
          source='contacts'
          fieldKey='id'
          className={classes.width100}
        >
          <Datagrid empty={<TablePlaceholder title='Contactos' />}>
            <TextField
              emptyText='-'
              source='contactType.name'
              label={translate('resources.contacts.fields.contactType')}
            />
            <TextField
              emptyText='-'
              source='contact'
              label={translate('resources.contacts.fields.contact')}
            />
            <FunctionField
              emptyText='-'
              source='duplicate'
              label={translate('resources.contacts.fields.duplicate')}
              render={(record) =>
                record?.duplicate
                  ? `Este contacto esta registrado en la entidad ${
                      entities[record.duplicate.entity]
                    } ${record.duplicate.id}`
                  : '-'
              }
            />
          </Datagrid>
        </ArrayField>
        <FormTitle title='Direcciones' className={classes.width100} />
        <ArrayField
          addLabel={false}
          source='addresses'
          fieldKey='id'
          className={classes.width100}
        >
          <Datagrid empty={<TablePlaceholder title='Direcciones' />}>
            <TextField
              emptyText='-'
              source='addressType.name'
              label={translate('resources.addresses.fields.addressType')}
            />
            <TextField
              emptyText='-'
              source='country.name'
              label={translate('resources.addresses.fields.country')}
            />
            <TextField
              emptyText='-'
              source='province.name'
              label={translate('resources.addresses.fields.province')}
            />
            <TextField
              emptyText='-'
              source='municipality.name'
              label={translate('resources.addresses.fields.municipality')}
            />
            <TextField
              emptyText='-'
              source='neighborhood.name'
              label={translate('resources.addresses.fields.neighborhood')}
            />
            <TextField
              emptyText='-'
              source='anotherNeighborhood'
              label={'Otra colonia'}
            />
            <TextField
              emptyText='-'
              source='city.name'
              label={translate('resources.addresses.fields.city')}
            />
            <TextField
              emptyText='-'
              source='street'
              label={translate('resources.addresses.fields.street')}
            />
            <TextField
              emptyText='-'
              source='outsideNumber'
              label={translate('resources.addresses.fields.outsideNumber')}
            />
            <TextField
              emptyText='-'
              source='postalCode'
              label={translate('resources.addresses.fields.postalCode')}
            />
            <FunctionField
              emptyText='-'
              source='duplicate'
              label={translate('resources.contacts.fields.duplicate')}
              render={(record) =>
                record?.duplicate
                  ? `Esta dirección esta registrada en la entidad ${
                      entities[record.duplicate.entity]
                    } ${record.duplicate.id}`
                  : '-'
              }
            />
          </Datagrid>
        </ArrayField>
        <FormTitle title='Representantes' className={classes.width100} />
        <ArrayField
          fieldKey='id'
          addLabel={false}
          className={classes.width100}
          source='representatives'
        >
          <Datagrid empty={<TablePlaceholder title='Representantes' />}>
            <FunctionField
              source='firstName'
              render={(record) =>
                record.physicalPerson ? (
                  <MuiLink
                    component={Link}
                    to={`/people/${record.physicalPerson?.id}/show`}
                    variant='inherit'
                    color='textPrimary'
                    underline='none'
                  >
                    <FullNameField
                      record={record}
                      entity='physicalPerson'
                      source='person'
                      size={24}
                    />
                  </MuiLink>
                ) : (
                  <MuiLink
                    component={Link}
                    to={`/legal-people/${record.legalPerson?.id}/show`}
                    variant='inherit'
                    color='textPrimary'
                    underline='none'
                  >
                    <TextField source='legalPerson.businessName' />
                  </MuiLink>
                )
              }
            />
            <FunctionField
              source='documentNumber'
              render={(record) =>
                record?.legalPerson ? (
                  <TextField
                    source='legalPerson.documentNumber'
                    emptyText='-'
                    label={translate('resources.people.fields.documentNumber')}
                  />
                ) : (
                  <TextField
                    source='physicalPerson.documentNumber'
                    emptyText='-'
                    label={translate('resources.people.fields.documentNumber')}
                  />
                )
              }
            />
            <FunctionField
              source='representativeType'
              emptyText='-'
              label='resources.representatives.fields.representativeType'
              render={(record) =>
                RepresentativeTypeNames[record?.representativeType]
              }
            />
            <TextField
              source='observation'
              label='resources.representatives.fields.observation'
              emptyText='-'
            />
          </Datagrid>
        </ArrayField>
        <FormTitle
          title={translate('resources.information-bank.fields.name')}
          className={classes.width100}
        />
        <ArrayField
          fieldKey='id'
          addLabel={false}
          label=''
          source='bankInformation'
          className={classes.width100}
        >
          <Datagrid empty={<TablePlaceholder title='Información bancaria' />}>
            <TextField
              source='productType'
              emptyText='-'
              label={translate('resources.information-bank.fields.productType')}
            />
            <TextField
              source='bank.name'
              emptyText='-'
              label={translate('resources.information-bank.fields.bank')}
            />
            <TextField
              source='number'
              emptyText='-'
              label={translate('resources.information-bank.fields.number')}
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    );
  }

  function renderReferences() {
    return (
      <Tab
        label='Referencias'
        contentClassName={classes.flexParent}
        path='references'
      >
        {permissions?.['loan-references'].create.enabled && (
          <AddNewButton label='Agregar Referencia' path='references' />
        )}
        <ReferenceManyField
          className={classes.width100}
          reference='references'
          addLabel={false}
          target='person'
          filter={{ person: props.id }}
        >
          <Datagrid
            empty={<TablePlaceholder title='Referencias' />}
            expand={<ReferenceShow />}
          >
            <ReferenceField
              source='referenceType'
              emptyText='-'
              reference='reference-types'
              label={translate('resources.references.fields.referenceType')}
              link={false}
            >
              <TextField source='name' />
            </ReferenceField>
            <TextField source='name' emptyText='-' />
            <TextField source='contacts[0].contact' emptyText='-' />
            <FunctionField
              render={(record) => (
                <div style={{ textAlign: 'end' }}>
                  <EditButton
                    label=''
                    to={{
                      pathname: `/references/${record.id}`,
                      search: `?person=${props.id}`,
                    }}
                  />
                  <DeleteWithConfirmButton
                    label=''
                    record={record}
                    redirect={false}
                    confirmContent={translate(
                      'resources.references.fields.confirmDelete'
                    )}
                    confirmTitle='Eliminar'
                  />
                </div>
              )}
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }

  function renderRelateds() {
    return (
      <Tab
        label='Relacionados'
        contentClassName={classes.flexParent}
        path='relateds'
      >
        {permissions?.related.create.enabled && (
          <AddNewButton label='Agregar Relacionado' path='relateds' />
        )}
        <ReferenceManyField
          className={classes.width100}
          reference='relateds'
          addLabel={false}
          target='person'
          filter={{ person: props.id }}
        >
          <Datagrid empty={<TablePlaceholder title='Relacionados' />}>
            <TextField
              source='relatedType.name'
              emptyText='-'
              label={translate('resources.related.fields.relatedType')}
            />
            <FunctionField
              source='relatedPerson'
              label={translate('resources.related.fields.relatedPerson')}
              render={(record) =>
                record.relatedPerson ? (
                  <MuiLink
                    component={Link}
                    to={`/people/${record.relatedPerson?.id}/show`}
                    variant='inherit'
                    color='textPrimary'
                    underline='none'
                  >
                    <FullNameField
                      record={record}
                      entity='relatedPerson'
                      source='person'
                      size={24}
                    />
                  </MuiLink>
                ) : (
                  '-'
                )
              }
            />
            <FunctionField
              source='relatedLegalPerson'
              label={translate('resources.related.fields.relatedLegalPerson')}
              render={(record) =>
                record.relatedLegalPerson ? (
                  <MuiLink
                    component={Link}
                    to={`/legal-people/${record.relatedLegalPerson?.id}/show`}
                    variant='inherit'
                    color='textPrimary'
                    underline='none'
                  >
                    <TextField source='relatedLegalPerson.businessName' />
                  </MuiLink>
                ) : (
                  '-'
                )
              }
            />
            <FunctionField
              render={(record) => (
                <div style={{ textAlign: 'end' }}>
                  <EditButton
                    label=''
                    to={{
                      pathname: `/relateds/${record.id}`,
                      search: `?person=${props.id}`,
                    }}
                  />
                  <DeleteWithConfirmButton
                    record={record}
                    label=''
                    redirect={false}
                    confirmContent={translate(
                      'resources.related.fields.confirmDelete'
                    )}
                    confirmTitle='Eliminar'
                  />
                </div>
              )}
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }

  function renderIncomes() {
    return (
      <Tab
        label='Ingresos'
        contentClassName={classes.flexParent}
        path='incomes'
      >
        {permissions?.income.create.enabled && (
          <AddNewButton label='Agregar Ingreso' path='incomes' />
        )}
        <ReferenceManyField
          reference='incomes'
          target='person'
          addLabel={false}
          className={classes.width100}
          filter={{ person: props.id }}
        >
          <Datagrid
            rowClick='show'
            empty={<TablePlaceholder title='Ingresos' />}
          >
            <ReferenceField
              source='incomeType'
              resource='people'
              reference='income-types'
              className={classes.width25}
              link={false}
              emptyText='-'
              label={translate('resources.incomes.fields.incomeType')}
            >
              <TextField source='name' />
            </ReferenceField>
            <TextField
              source='companyName'
              emptyText='-'
              label={translate('resources.incomes.fields.companyName')}
            />
            <TextField
              source='position'
              emptyText='-'
              label={translate('resources.incomes.fields.position')}
            />
            <NumberField
              source='amount'
              emptyText='-'
              label={translate('resources.incomes.fields.incomes')}
              options={{ style: 'currency', currency: 'MXN' }}
            />
            <FunctionField
              source='effectiveDate'
              className={classes.width25}
              render={() => <CustomDateInput source='effectiveDate' />}
            />
            <DeleteWithConfirmButton
              label=''
              redirect={false}
              confirmContent={translate(
                'resources.incomes.fields.confirmDelete'
              )}
              confirmTitle='Eliminar'
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }

  function renderDocuments() {
    return (
      <Tab label='Documentos' path='documents'>
        <DocumentUploadButton
          documentSegment={DocumentSegment.Personal.id}
          segment={DocumentSegment.Personal.name}
          related={props.id}
        />
        <DocumentList segment={DocumentSegment.Personal.name} />
      </Tab>
    );
  }

  function renderApplications() {
    return (
      <Tab label='Solicitudes' path='loan-applications'>
        {permissions?.['loan-application'].create.enabled && (
          <AddNewButton label='Crear una solicitud' path='loan-applications' />
        )}
        <ReferenceManyField
          className={classes.width100}
          reference='loan-applications'
          addLabel={false}
          target='person'
          filter={{ person: props.id }}
        >
          <Datagrid
            rowClick='show'
            empty={<TablePlaceholder title='Solicitudes' />}
          >
            <TextField source='id' />
            <NumberField
              source='amount'
              emptyText='-'
              options={{ style: 'currency', currency: 'MXN' }}
            />
            <TextField source='months' emptyText='-' />
            <FunctionField
              source='interestRate'
              emptyText='-'
              render={(record) =>
                record.interestRate ? `${record.interestRate}%` : '-'
              }
            />
            <ReferenceField
              source='state.id'
              resource='people'
              reference='states'
              className={classes.width25}
              link={false}
              emptyText='-'
            >
              <ChipField source='name' emptyText='-' />
            </ReferenceField>
            <ReferenceField
              source='applicationType.id'
              resource='people'
              reference='application-types'
              className={classes.width25}
              link={false}
              emptyText='-'
            >
              <ChipField source='name' />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }

  function renderAssets(permissions) {
    deletePreviousPath();
    return (
      <Tab label='Bienes' path='assets'>
        {permissions?.asset.create.enabled && (
          <AddNewButton label='Crear bien' path='assets' />
        )}
        <ReferenceManyField
          className={classes.width100}
          reference='assets'
          addLabel={false}
          target='person'
          filter={{ person: props.id }}
        >
          <Datagrid rowClick='show' empty={<TablePlaceholder title='Bienes' />}>
            <ReferenceField
              source='assetType'
              resource='people'
              reference='asset-types'
              className={classes.width25}
              link={false}
              emptyText='-'
            >
              <TextField source='name' />
            </ReferenceField>
            <ReferenceField
              source='documentType'
              resource='people'
              reference='document-types'
              className={classes.width25}
              link={false}
              emptyText='-'
            >
              <ChipField source='name' />
            </ReferenceField>
            <TextField source='documentNumber' emptyText='-' />
            <NumberField
              emptyText='-'
              source='value'
              options={{ style: 'currency', currency: 'MXN' }}
            />
            <BooleanField source='legalOpposition' emptyText='-' />
            {permissions?.['person']?.delete.enabled && (
              <DeleteWithConfirmButton
                redirect={false}
                label=''
                onFailure={(error) => {
                  const code =
                    error?.body?.data?.errorCode || error?.message || '';
                  notify(
                    `${thereWasProblem}: ${
                      errors[code] ||
                      'Hubo un error con su solicitud, inténtelo mas tarde...'
                    }`,
                    'warning'
                  );
                }}
                confirmContent={translate(
                  'resources.assets.fields.confirmDelete'
                )}
                confirmTitle='Eliminar'
              />
            )}
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }

  function renderCC() {
    return (
      <Tab label='Consulta BC' path='credit-inquiry-unykoos'>
        <AddNewButton label='Consulta BC' path='credit-inquiry-unykoos' />
        <ReferenceManyField
          addLabel={false}
          reference='credit-inquiry-unykoos'
          target='person'
          className={classes.width100}
          filter={{ person: props.id }}
        >
          <Datagrid empty={<TablePlaceholder title='Consultas de crédito' />}>
            <TextField source='unykoo' />
            <NumberField
              emptyText='-'
              source='amount'
              options={{ style: 'currency', currency: 'MXN' }}
            />
            <DateField
              className={classes.width25}
              emptyText='-'
              source='created_at'
              options={{
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              }}
              showTime
            />
            <DateField
              className={classes.width25}
              emptyText='-'
              source='requestDate'
              showTime
            />
            <FunctionField
              render={(record) =>
                record.status === UnykooState.Progress ||
                record.status === UnykooState.Success ? (
                  record.error ? (
                    <ResendNipButton record={record} />
                  ) : (
                    <PersonNipButton record={record} />
                  )
                ) : (
                  <CreditCircleRetry record={record} />
                )
              }
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }
};

export default PersonShow;
