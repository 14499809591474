import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Hidden, Typography, makeStyles } from '@material-ui/core';
import type { Theme } from '../../../Theme';

interface HeaderProps {
  className?: string;
  altText?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  action: {
    backgroundColor: theme.palette.common.white,
  },
  image: {
    width: '80%',
    maxHeight: 400,
  },
}));

const Header: FC<HeaderProps> = ({ className, altText, ...rest }) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid alignItems='center' container justify='space-between' spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography variant='overline' color='textSecondary'>
            Dashboard
          </Typography>
          <Typography variant='h3' color='textPrimary'>
            Saludos {`${user?.person.firstName} ${user?.person.fatherLastName}`}
          </Typography>
          <Typography variant='subtitle1' color='textPrimary'>
            {altText ? `Esto es lo que está pasando con tus ${altText}.` : null}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item md={6}>
            <img
              alt='Cover'
              className={classes.image}
              src='/static/images/undraw_growth_analytics_8btt.svg'
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
