import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import UsersPermissionsRolesList from "./UsersPermissionsRolesList";
import UsersPermissionsRolesShow from './UsersPermissionsRolesShow';
import UsersPermissionsRolesEdit from './UsersPermissionsRolesEdit';
import UsersPermissionsRolesCreate from './UsersPermissionsRolesCreate';


export const UsersPermissionsRoles: object = {
  icon: SupervisorAccount,
  list: UsersPermissionsRolesList,
  show: UsersPermissionsRolesShow,
  edit: UsersPermissionsRolesEdit,
  create: UsersPermissionsRolesCreate,
};

export default UsersPermissionsRoles;