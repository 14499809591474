import React from 'react';
import { Edit, ListButton, TopToolbar, useNotify } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import EmployeeForm from './EmployeeForm';
import { thereWasProblem, errors } from '../../Utils/messages';

const EmployeeActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const EmployeeEdit = (props) => {
  const notify = useNotify();

  const onFailure = (error) => {
    const code = error?.body?.data?.errorCode;
    notify(
      `${thereWasProblem}: ${
        errors[code] || 'Hubo un error con su solicitud, inténtelo mas tarde...'
      }`,
      'warning'
    );
  };

  return (
    <Edit
      actions={<EmployeeActions {...props} />}
      onFailure={onFailure}
      {...props}
    >
      <EmployeeForm />
    </Edit>
  );
};

export default EmployeeEdit;
