import React from 'react';
import {
  Create,
  TabbedForm,
  FormTab,
  TopToolbar,
  ListButton,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { parse } from 'query-string';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import GuaranteeForm from './GuaranteeForm';
import {
  errors,
  guaranteeIsRegistered,
  thereWasProblem,
} from '../../Utils/messages';

const Actions = ({ loanApplication }) => (
  <TopToolbar>
    <ListButton
      to={
        loanApplication
          ? `/loan-applications/${loanApplication}/show/guarantees`
          : ''
      }
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const GuaranteeCreate = (props) => {
  const {
    loanApplication: loanApplicationString,
    person: personString,
    legalPerson: legalPersonString,
  } = parse(props.location.search);
  const loanApplication = loanApplicationString
    ? parseInt(loanApplicationString, 10)
    : '';
  const person = personString ? parseInt(personString, 10) : '';
  const legalPerson = legalPersonString ? parseInt(legalPersonString, 10) : '';
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('Garantía creada');
    redirect(
      loanApplication
        ? `/loan-applications/${loanApplication}/show/guarantees`
        : ``
    );
    refresh();
  };
  const onFailure = (error) => {
    if (error.body?.data?.errors === 'This asset is already registered') {
      notify(
        `${guaranteeIsRegistered}: ${error.body?.data?.errors}`,
        'warning'
      );
    } else {
      notify(
        `${errors[error.body?.data?.errorCode] || thereWasProblem}`,
        'warning'
      );
    }
  };

  return (
    <Create
      title=''
      {...props}
      onSuccess={onSuccess}
      onFailure={onFailure}
      actions={<Actions loanApplication={loanApplication} />}
    >
      <TabbedForm
        initialValues={{ loanApplication, person, legalPerson }}
        redirect={
          loanApplication
            ? `/loan-applications/${loanApplication}/show/guarantees`
            : ``
        }
        variant='outlined'
      >
        <FormTab label='Garantía'>
          <GuaranteeForm />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default GuaranteeCreate;
