import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';

import RelatedTypeList from './RelatedTypeList';

export const RelatedType: object = {
  list: RelatedTypeList,
  icon: AssignmentReturnedIcon,
};

export default RelatedType;
