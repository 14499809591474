import React from 'react';
import {
  ArrayInput,
  ReferenceInput,
  required,
  FormDataConsumer,
  SelectInput,
  SimpleFormIterator,
} from 'react-admin';
import { Grid } from '@material-ui/core';

const Documents = ({ className = '', source = 'documents', ...rest }) => {
  return (
    <ArrayInput
      source={source}
      validation={[required()]}
      label='Documentos'
      {...rest}
    >
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'documentSegment.id' : 'documentSegment'
                    )}
                    resource='document-types'
                    reference='document-segments'
                    validate={required()}
                    variant='outlined'
                    label='Entidad'
                    fullWidth
                  >
                    <SelectInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'documentType.id' : 'documentType'
                    )}
                    reference='document-types'
                    validate={required()}
                    variant='outlined'
                    label='Tipo'
                    fullWidth
                    filter={
                      scopedFormData?.documentSegment && {
                        documentSegment: rest.record?.id
                          ? scopedFormData.documentSegment.id
                          : scopedFormData.documentSegment,
                        segment: 'files',
                      }
                    }
                  >
                    <SelectInput />
                  </ReferenceInput>
                </Grid>
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default Documents;
