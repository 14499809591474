import React from "react";
import { EditButton, ListButton, Show, TopToolbar, useLoading } from "react-admin";
import { FormShow } from "../../styles";
import UsersPermissionsRolesAccordionForm from './UsersPermissionsRolesAccordionForm'
import { ChevronLeft } from "@material-ui/icons";

const UsersPermissionsRolesActions = ({ basePath, data, permissions }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
    {permissions?.['roles-catalog']?.update?.enabled && !data.isAdmin && (
      <EditButton basePath={basePath} record={data} />
    )}
  </TopToolbar>
);

const UsersPermissionsRolesShow = (props) => {
  const classes = FormShow();
  const loading  = useLoading();

  return (
    <Show
      {...props}
      classes={{ card: classes.show }}
      actions={<UsersPermissionsRolesActions {...props} />}
    >
      <UsersPermissionsRolesAccordionForm className={classes.width100} loading={loading} />
    </Show>
  );
};

export default UsersPermissionsRolesShow;