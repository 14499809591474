import SchoolIcon from '@material-ui/icons/School';

import AcademicLevelList from './AcademicLevelList';

export const AcademicLevel: object = {
  list: AcademicLevelList,
  icon: SchoolIcon,
};

export default AcademicLevel;
