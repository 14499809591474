import React from 'react';

const matchesEntityType = (type: string) => type === 'LINK';

const findLinkEntities = (
  contentBlock: any,
  callback: () => {},
  contentState: any
) => {
  if (!contentState) return;
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      matchesEntityType(contentState.getEntity(entityKey).getType())
    );
  }, callback);
};

const LinkDecorator = ({ href, children }) => {
  return (
    <a
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      style={{
        color: '#3b5998',
        textDecoration: 'underline',
      }}
    >
      {children}
    </a>
  );
};

export const linkDecoratorConfig = {
  strategy: findLinkEntities,
  component: (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return <LinkDecorator href={url} children={props.children} />;
  },
};
export default LinkDecorator;
