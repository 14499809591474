import React from 'react';
import { Create, ListButton, TopToolbar, useNotify } from 'react-admin';
import { parse } from 'query-string';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import LoanApplicationForm from './LoanApplicationForm';
import { errors, hasLoanActive, thereWasProblem } from '../../Utils/messages';

const LoanApplicationActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const LoanApplicationCreate = (props) => {
  const notify = useNotify();
  const { person: personString, 'legal-person': legalPersonString } = parse(
    props.location.search
  );
  const person = personString ? parseInt(personString, 10) : '';
  const legalPerson = legalPersonString ? parseInt(legalPersonString, 10) : '';

  const onFailure = (error) => {
    if (
      error.body?.data?.errors ===
      'The owner has another application active with same type'
    ) {
      notify(`${hasLoanActive}`, 'warning');
    } else {
      const errorMsg = errors[error.body?.data?.errorCode];
      notify(
        errorMsg
          ? `${errorMsg}`
          : `${thereWasProblem}: ${
              error.body?.data?.errors || 'Fallo general'
            }`,
        'warning'
      );
    }
  };

  return (
    <Create
      actions={<LoanApplicationActions {...props} />}
      {...props}
      onFailure={onFailure}
    >
      <LoanApplicationForm person={person} legalPerson={legalPerson} />
    </Create>
  );
};

export default LoanApplicationCreate;
