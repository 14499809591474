import React from 'react';
import {
  Datagrid,
  Filter,
  AutocompleteInput,
  ReferenceInput,
  TextField,
  TextInput,
  useTranslate,
  FunctionField,
  DateField,
} from 'react-admin';

import ContactField from '../../Components/ContactField';
import ListActions from '../../Components/ListActions';
import CustomList from '../../Components/CustomList';

import AgentAssignButton from './AgentAssignButton';
import AgentUnassignButton from './AgentUnassignButton';
import { Department, FranchiseState } from '../../Utils/enums';
import FullNameField from '../../Components/FullNameField';
import { BusinessNameField } from '../../Components/BusinessNameField';

const ENTITY_NAME = 'franchise';
const ENTITY_NAME_ASSIGN_FRANCHISE = 'assign-franchise';

const FranchiseFilter = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput
      source='code'
      label='resources.franchises.fields.code'
      resettable
      alwaysOn
    />
    <TextInput
      source='code_id'
      label='Id'
      resettable
    />
    <TextInput
      source='name'
      label='resources.franchises.fields.name'
      resettable
      alwaysOn
    />
    <ReferenceInput
      reference='people'
      source='manager'
      label='resources.franchises.fields.manager'
      resettable
    >
      <AutocompleteInput
        optionText={(record) =>
          record?.id &&
          `${record.documentNumber} - ${record.firstName} ${record.fatherLastName}`
        }
      />
    </ReferenceInput>
    <ReferenceInput
      reference='legal-people'
      source='moralManager'
      label='Encargado moral'
      resettable
    >
      <AutocompleteInput
        optionText={(record) =>
          record?.id &&
          `${record.documentNumber} - ${record.businessName}`
        }
      />
    </ReferenceInput>
    <ReferenceInput
      reference='employees'
      source='agent'
      resettable
      filter={{
        department: Department.CommercialLink,
      }}
    >
      <AutocompleteInput
        optionText={(record) =>
          record.id &&
          `${record?.code || '-'} | ${record.person?.firstName ?? ''} ${
            record.person?.fatherLastName ?? ''
          }`
        }
      />
    </ReferenceInput>
  </Filter>
);

const FranchiseBulkActionButtons = ({permissions, ...props}) => (
  <>
    {permissions?.[ENTITY_NAME_ASSIGN_FRANCHISE]?.assign?.enabled && <AgentAssignButton {...props} />}
    {permissions?.[ENTITY_NAME_ASSIGN_FRANCHISE]?.unassign?.enabled && <AgentUnassignButton {...props} />}
  </>
);

const FranchiseList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const role = JSON.parse(localStorage.getItem('user') || '{}').role.id;

  return (
    <CustomList
      bulkActionButtons={
        <FranchiseBulkActionButtons permissions={permissions} />
      }
      filter={{ state: FranchiseState.Active }}
      filters={<FranchiseFilter />}
      actions={
        <ListActions entity='franchise' permissions={permissions} {...props} />
      }
      {...props}
      hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}

    >
      <Datagrid rowClick='show'>
        <TextField source='code_id' label="Id" emptyText='-' />
        <TextField source='code' emptyText='-' />
        <TextField source='name' emptyText='-' />
        <FunctionField
          source='manager'
          render={(record) =>
            record?.manager ? (
              <FullNameField
                record={record}
                source='manager'
                entity='manager'
              />
            ) : (
              <TextField source='manager' emptyText='-' />
            )
          }
        />
        <FunctionField
          source='moralManager'
          label="Encargado moral"
          render={(record) =>
            record?.moralManager ? (
              <BusinessNameField
                record={record}
                source='moralManager'
                entity='moralManager'
              />
            ) : (
              <TextField source='moralManager' emptyText='-'  />
            )
          }
        />
        <TextField source='masterBroker.name' emptyText='-' />
        <ContactField
          label={translate('resources.people.fields.cellPhone')}
          source='contacts'
          contactType='Celular'
        />
        <TextField source='agent.code' emptyText='-' />
        <DateField source='created_at' showTime={false} locales='es-MX' />
      </Datagrid>
    </CustomList>
  );
};

export default FranchiseList;
