import React, { FC, ReactElement } from 'react';
import { DateField, useListContext } from 'react-admin'; //useListContext
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import ContactsIcon from '@material-ui/icons/Contacts';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import BlockIcon from '@material-ui/icons/Block';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CommentIcon from '@material-ui/icons/Comment';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import RemoveIcon from '@material-ui/icons/Remove';
import { timelineType } from '../../Utils/enums';
import { formatNumber } from '../../Utils/functions';

const useStyles = makeStyles((theme) => ({
  content: {
    borderLeft: '1px solid #bdbdbd',
    marginLeft: '9px',
    padding: '8px 15px',
  },
  titleI: {
    fontWeight: 'bold',
  },
  mb: {
    marginBottom: '10px',
  },
  titleNb: {
    fontWeight: 'normal',
  },
}));

const selectIconType = (typeChanged: string) => {
  let iconBasedTypeChanged: ReactElement | null = null;
  switch (typeChanged) {
    case timelineType.Months:
      iconBasedTypeChanged = (
        <DateRangeIcon fontSize='small' color='disabled' />
      );
      return;
    case timelineType.InterestRate:
      iconBasedTypeChanged = (
        <TrendingUpIcon fontSize='small' color='disabled' />
      );
      return;
    case timelineType.Referrer:
      iconBasedTypeChanged = (
        <SupervisorAccountIcon fontSize='small' color='disabled' />
      );
      return;
    case timelineType.BlacklistIn:
      iconBasedTypeChanged = <BlockIcon fontSize='small' color='disabled' />;
      return;
    case timelineType.BlacklistOut:
      iconBasedTypeChanged = (
        <ExitToAppIcon fontSize='small' color='disabled' />
      );
      return;
    case timelineType.Agent:
    case timelineType.Analyst:
    case timelineType.Lawyer:
    case timelineType.JrLawyer:
    case timelineType.Manager:
      iconBasedTypeChanged = (
        <AssignmentIndIcon fontSize='small' color='disabled' />
      );
      return;
    case timelineType.State:
    case timelineType.Substate:
      iconBasedTypeChanged = (
        <AssignmentTurnedInIcon fontSize='small' color='disabled' />
      );
      return;
    case timelineType.ApplicationReason:
      iconBasedTypeChanged = <CommentIcon fontSize='small' color='disabled' />;
      return;
    case timelineType.ClientDocumentNumber:
    case timelineType.AssetDocument:
      iconBasedTypeChanged = (
        <DescriptionIcon fontSize='small' color='disabled' />
      );
      break;
    case timelineType.PersonName:
    case timelineType.LegalPersonName:
      iconBasedTypeChanged = <PersonIcon fontSize='small' color='disabled' />;
      break;
    case timelineType.Addresses:
      iconBasedTypeChanged = <BusinessIcon fontSize='small' color='disabled' />;
      break;
    case timelineType.Contacts:
      iconBasedTypeChanged = <ContactsIcon fontSize='small' color='disabled' />;
      break;
    case timelineType.IncomeCompany:
    case timelineType.IncomeAmount:
    case timelineType.AssetValue:
    case timelineType.Amount:
      iconBasedTypeChanged = (
        <AttachMoneyIcon fontSize='small' color='disabled' />
      );
      break;
    case timelineType.IncomeCreate:
    case timelineType.AssetCreate:
      iconBasedTypeChanged = <AddIcon fontSize='small' color='disabled' />;
      break;
    case timelineType.IncomeDelete:
    case timelineType.AssetDelete:
      iconBasedTypeChanged = (
        <HighlightOffIcon fontSize='small' color='disabled' />
      );
      break;
    case timelineType.AddDocumentCheck:
      <DoneAllIcon fontSize='small' color='disabled' />;
      break;
    case timelineType.RemoveDocumentCheck:
      <RemoveIcon fontSize='small' color='disabled' />;
      break;
    default:
      return iconBasedTypeChanged;
  }

  return iconBasedTypeChanged;
};

const showTextDescription = (data: any) => {
  let textActionDescription = '';
  switch (data.action) {
    case timelineType.Amount:
      textActionDescription = `Cambió el monto de la solicitud de ${formatNumber(
        data.previousValue
      )} a ${formatNumber(data.currentValue)}.`;
      break;
    case timelineType.Months:
      textActionDescription = `Cambió el plazo de la solicitud de ${
        data.previousValue || 'vacío'
      } a ${data.currentValue} meses.`;
      break;
    case timelineType.InterestRate:
      textActionDescription = `Cambió la tasa de interés de la solicitud de ${
        data.previousValue || 'vacío'
      }% a ${data.currentValue}%.`;
      break;
    case timelineType.Referrer:
      textActionDescription = `Cambió el referidor de la solicitud de ${
        data.previousValue || 'vacío'
      } a ${data.currentValue}.`;
      break;
    case timelineType.Agent:
      textActionDescription = `Cambió el gestor de la solicitud de ${
        data.previousValue || 'vacío'
      } a ${data.currentValue}.`;
      break;
    case timelineType.BlacklistIn:
      textActionDescription = 'Entró a Blacklist';
      break;
    case timelineType.BlacklistOut:
      textActionDescription = 'Salió de Blacklist';
      break;
    case timelineType.Analyst:
      textActionDescription = `Cambió el analista de la solicitud de ${
        data.previousValue || 'vacío'
      } a ${data.currentValue}.`;
      break;
    case timelineType.Lawyer:
      textActionDescription = `Cambió el abogado Sr de la solicitud de ${
        data.previousValue || 'vacío'
      } a ${data.currentValue}.`;
      break;
    case timelineType.JrLawyer:
      textActionDescription = `Cambió el abogado Jr de la solicitud de ${
        data.previousValue || 'vacío'
      } a ${data.currentValue}.`;
      break;
    case timelineType.Manager:
      textActionDescription = `Cambió el manager de la solicitud de ${
        data.previousValue || 'vacío'
      } a ${data.currentValue}.`;
      break;
    case timelineType.State:
      textActionDescription = `Cambió el estado de la solicitud de ${
        data.previousValue || 'vacío'
      } a ${data.currentValue}.`;
      break;
    case timelineType.Substate:
      textActionDescription = `Cambió el subestado de la solicitud de ${
        data.previousValue || 'vacío'
      } a ${data.currentValue}.`;
      break;
    case timelineType.ApplicationReason:
      textActionDescription = `Cambió la razón de la solicitud de ${
        data.previousValue || 'vacío'
      } a ${data.currentValue}.`;
      break;
    case timelineType.ClientDocumentNumber:
      textActionDescription = `Cambió el número de documento, de ${data.previousValue} a ${data.currentValue}`;
      break;
    case timelineType.PersonName:
    case timelineType.LegalPersonName:
      textActionDescription = `Cambió el nombre de la persona, de ${data.previousValue} a ${data.currentValue}`;
      break;
    case timelineType.Addresses:
      textActionDescription = `Cambió la dirección de la persona, de ${data.previousValue} a ${data.currentValue}`;
      break;
    case timelineType.Contacts:
      textActionDescription = `Cambiaron los contactos, de ${data.previousValue} ${data.currentValue}`;
      break;
    case timelineType.IncomeCompany:
      textActionDescription = `Cambió el nombre de la compañia, de ${data.previousValue} a ${data.currentValue}`;
      break;
    case timelineType.IncomeAmount:
      textActionDescription = `Cambió el valor del ingreso, de ${formatNumber(
        data.previousValue
      )} a ${formatNumber(data.currentValue)}`;
      break;
    case timelineType.AssetDocument:
      textActionDescription = `Cambió en el numero del documento del recurso, de ${data.previousValue} a ${data.currentValue}`;
      break;
    case timelineType.AssetValue:
      textActionDescription = `Cambió el valor del bien, de ${formatNumber(
        data.previousValue
      )} a ${formatNumber(data.currentValue)}`;
      break;
    case timelineType.AssetDelete:
      textActionDescription = `Eliminó un bien del cliente`;
      break;
    case timelineType.AssetCreate:
      textActionDescription = `Creó un bien al cliente`;
      break;
    case timelineType.IncomeCreate:
      textActionDescription = `Creó un ingreso al cliente`;
      break;
    case timelineType.IncomeDelete:
      textActionDescription = `Eliminó un ingreso del cliente`;
      break;
    case timelineType.AddDocumentCheck:
    case timelineType.RemoveDocumentCheck:
      textActionDescription = data.currentValue;
      break;
    default:
      return textActionDescription;
  }
  return textActionDescription;
};

const nameFromUserData = (user: any) => {
  if (!user || (!user?.legalPerson && !user?.person)) return null;
  if (user.legalPerson) return (user.legalPerson?.businessName || '') as string;

  return `${user.person.firstName} ${user.person.secondName || ''}`;
};

export const HistoryTimeline: FC = () => {
  const classes = useStyles();
  const { data, ids } = useListContext();

  return (
    <>
      {ids.map((id) => (
        <Box key={id} mb='1em' paddingLeft='15px'>
          <Box display='flex'>
            <Box mr='5px'>{selectIconType(data[id].action)}</Box>
            <Box flexGrow={1} display='flex'>
              <Typography className={classes.titleI}>
                {nameFromUserData(data[id].user)}
                <span className={classes.titleNb}> en </span>
                <DateField
                  record={data[id]}
                  source='created_at'
                  options={{
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }}
                  showTime
                />
              </Typography>
            </Box>
          </Box>
          <div className={classes.content}>
            <Typography>{showTextDescription(data?.[id])}</Typography>
          </div>
        </Box>
      ))}
    </>
  );
};
