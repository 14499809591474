import React, { useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  FormWithRedirect,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Toolbar } from '@material-ui/core';
import Addresses from '../../Components/Addresses';
import Contacts from '../../Components/Contacts';
import Typography from '@material-ui/core/Typography';
import FormTitle from '../../Components/FormTitle';
import { PersonTypeAlias } from '../../Utils/enums';
import { nameManagerByTypeUser } from '../../Utils/franchise';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
  })
);

const isRequired = [required()];

const FranchiseForm = (props: any) => {
  const classes = useStyles();
  const [showDictum, setShowDictum] = useState(props?.record?.blacklist);
  const role = JSON.parse(localStorage.getItem('user') || '{}').role.id;
  const [messageIsEmployeeOrClient, setMessageIsEmployeeOrClient] =
    useState('');
  const [selectedPerson, setSelectedPerson] = useState<any>();
  const [selectedTypePerson, setSelectedTypePerson] = useState<any>(
    PersonTypeAlias.Physical
  );
  const notesEmployeeOrClient = (isEmployee: boolean, isClient: boolean) => {
    setMessageIsEmployeeOrClient('');
    if (isEmployee) {
      setMessageIsEmployeeOrClient(
        'NOTA: La persona seleccionada está dada de alta como empleado'
      );
    }
    if (isClient) {
      setMessageIsEmployeeOrClient(
        'NOTA: La persona seleccionada está dada de alta como cliente'
      );
    }
  };
  return (
    <FormWithRedirect
      variant='outlined'
      className={classes.root}
      {...props}
      render={(formProps) => {
        return (
          <form>
            <Grid item xs={12} classes={{ root: classes.root }}>
              <Typography variant='h3' gutterBottom className={classes.paper}>
                General
              </Typography>
              <Grid container spacing={1} classes={{ root: classes.root }}>
                <Grid item xs={4}>
                  <TextInput
                    source='code'
                    helperText='En caso de no llenar se generará automáticamente'
                    fullWidth
                    variant='outlined'
                    resource='franchises'
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    source='name'
                    validate={isRequired}
                    fullWidth
                    variant='outlined'
                    resource='franchises'
                    autoComplete='off'
                  />
                </Grid>
                <Grid
                  container
                  style={{
                    marginTop: '4px',
                  }}
                  alignItems='center'
                  xs={3}
                >
                  <Grid item xs={12}>
                    <ReferenceInput
                      source={
                        selectedTypePerson === PersonTypeAlias.Moral
                          ? 'moralManager'
                          : 'manager'
                      }
                      reference={
                        selectedTypePerson === PersonTypeAlias.Moral
                          ? 'franchises/available-people/moral'
                          : 'franchises/available-people'
                      }
                      validate={isRequired}
                      fullWidth
                      variant='outlined'
                      resource='franchises'
                      record={formProps.record}
                    >
                      <AutocompleteInput
                        helperText={messageIsEmployeeOrClient}
                        shouldRenderSuggestions={() => true}
                        onSelect={(selected) => {
                          setMessageIsEmployeeOrClient('');
                          notesEmployeeOrClient(
                            selected.isEmployee,
                            selected.isClient
                          );
                          setSelectedPerson(selected);
                        }}
                        optionText={(record) => nameManagerByTypeUser(record)}
                      />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={12}>
                    <RadioButtonGroupInput
                      style={{ marginLeft: '5%' }}
                      source='typePerson'
                      label='Tipo de persona'
                      defaultValue={PersonTypeAlias.Physical}
                      choices={[
                        {
                          id: PersonTypeAlias.Physical,
                          name: 'Persona fisica',
                        },
                        {
                          id: PersonTypeAlias.Moral,
                          name: 'Persona moral',
                        },
                      ]}
                      onChange={(e) => {
                        const value = e;
                        setMessageIsEmployeeOrClient('');
                        setSelectedTypePerson(value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <ReferenceInput
                    source='masterBroker'
                    reference='franchises'
                    fullWidth
                    variant='outlined'
                    resource='franchises'
                    sort={{ field: 'name', order: 'ASC' }}
                    disabled={props.record?.id}
                    filter={{ masterBroker: true }}
                  >
                    <AutocompleteInput optionText='name' />
                  </ReferenceInput>
                </Grid>
                {formProps.record?.id && (
                  <Grid item xs={3}>
                    <BooleanInput
                      source='blacklist'
                      resource='franchises'
                      defaultValue={false}
                      onChange={(val) => setShowDictum(val)}
                    />
                  </Grid>
                )}
                {showDictum ? (
                  <Grid item xs={3}>
                    <ReferenceInput
                      fullWidth
                      source='blacklistDictum'
                      variant='outlined'
                      reference='blacklist-dictums'
                      resource='blacklists'
                      validate={required()}
                    >
                      <SelectInput optionText='code' />
                    </ReferenceInput>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <FormTitle title='Contactos' />
                  <Contacts record={formProps.record} />
                </Grid>
                <Grid item xs={12}>
                  <FormTitle title='Direcciones' />
                  <Addresses record={formProps.record} />
                </Grid>
              </Grid>
              <Toolbar>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <SaveButton
                    saving={formProps.saving}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                    redirect='list'
                  />
                </Box>
              </Toolbar>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default FranchiseForm;
