import React from 'react';
import { Create, ListButton, TopToolbar } from 'react-admin';

import StateRuleForm from './StateRuleForm';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const StateRuleActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const StateRuleCreate = (props) => {
  return (
    <Create title=' ' {...props} actions={<StateRuleActions {...props} />}>
      <StateRuleForm />
    </Create>
  );
};

export default StateRuleCreate;
