import ListAltIcon from '@material-ui/icons/ListAlt';

import ApplicationReasonList from './ApplicationReasonList';

export const ApplicationReason: object = {
  list: ApplicationReasonList,
  icon: ListAltIcon,
};

export default ApplicationReason;
