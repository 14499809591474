import RecentActorsIcon from '@material-ui/icons/RecentActors';

import ContactTypeList from './ContactTypeList';

export const ContactType: object = {
  list: ContactTypeList,
  icon: RecentActorsIcon,
};

export default ContactType;
