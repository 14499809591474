import React from 'react';
import { Divider, Grid, List, Paper, Typography } from '@material-ui/core';

import {
  academicLevels,
  assetsType,
  contactType,
  documentTypes,
  economicActivities,
  incomeTypes,
  maritalStatuses,
  occupations,
  professions,
  relatedTypes,
  referenceTypes,
} from '../../../Views';
import ListItemLink from '../ListItemLink';
import { usePermissions } from 'react-admin'

const Person = ({ classes, iconColor }) => {
  const { permissions } = usePermissions();

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography gutterBottom variant='h4' component='h2'>
          Personas
        </Typography>
        <Divider />
        <List className={classes.list} aria-labelledby='conjuration subtitle'>
          { permissions?.['academic-level']?.find.enabled && 
            <ListItemLink
              icon={<academicLevels.icon />}
              primary='Niveles Académicos'
              to='/academic-levels'
              color={iconColor}
            />
          }
          { permissions?.['profession']?.find.enabled && 
            <ListItemLink
              icon={<professions.icon />}
              primary='Profesiones'
              to='/professions'
              color={iconColor}
            />
          }
          { permissions?.['position']?.find.enabled && 
            <ListItemLink
              icon={<occupations.icon />}
              primary='Ocupaciones'
              to='/occupations'
              color={iconColor}
            />
          }
          { permissions?.['contact-type']?.find.enabled && 
            <ListItemLink
              icon={<contactType.icon />}
              primary='Tipos de Contactos'
              to='/contact-types'
              color={iconColor}
            />
          }
          { permissions?.['marital-status']?.find.enabled && 
            <ListItemLink
              icon={<maritalStatuses.icon />}
              primary='Estados Civiles'
              to='/marital-statuses'
              color={iconColor}
            />
          }
          { permissions?.['related-types']?.find.enabled && 
            <ListItemLink
              icon={<relatedTypes.icon />}
              primary='Tipos de Relacionados'
              to='/related-types'
              color={iconColor}
            />
          }
          { permissions?.['document-type']?.find.enabled && 
            <ListItemLink
              icon={<documentTypes.icon />}
              primary='Tipos de Documentos'
              to='/document-types'
              color={iconColor}
            />
          }
          { permissions?.['economic-activity']?.find.enabled && 
            <ListItemLink
              icon={<economicActivities.icon />}
              primary='Actividades Económicas'
              to='/economic-activities'
              color={iconColor}
            />
          }
          { permissions?.['income-type']?.find.enabled && 
            <ListItemLink
              icon={<incomeTypes.icon />}
              primary='Tipos de Ingresos'
              to='/income-types'
              color={iconColor}
            />
          }
          { permissions?.['asset-type']?.find.enabled && 
            <ListItemLink
              icon={<assetsType.icon />}
              primary='Tipos de Bienes'
              to='/asset-types'
              color={iconColor}
            />
          }
          { permissions?.['reference-type']?.find.enabled && 
            <ListItemLink
              icon={<referenceTypes.icon />}
              primary='Tipos de Referencias'
              to='/reference-types'
              color={iconColor}
            />
          }
        </List>
      </Paper>
    </Grid>
  );
};

export default Person;
