import * as React from 'react';
import {
  ArrayField,
  Datagrid,
  TabbedShowLayout,
  Tab,
  TextField,
  useTranslate,
  FunctionField,
} from 'react-admin';

import { FormShow } from '../../styles';
import { entities } from '../../Utils/enums';
import TablePlaceholder from '../../Components/TablePlaceholder';
import FormTitle from '../../Components/FormTitle';

export const ReferenceShow = () => {
  const classes = FormShow();
  const translate = useTranslate();

  return (
    <TabbedShowLayout className={classes.borderBottom}>
      <Tab label='Información' contentClassName={classes.flexParent}>
        <FormTitle title='Contactos' className={classes.width100} />
        <ArrayField
          fieldKey='id'
          addLabel={false}
          label=''
          source='contacts'
          className={classes.width100}
        >
          <Datagrid empty={<TablePlaceholder title='Contactos' />}>
            <TextField
              emptyText='-'
              source='contactType.name'
              label={translate('resources.contacts.fields.contactType')}
            />
            <TextField
              emptyText='-'
              source='contact'
              label={translate('resources.contacts.fields.contact')}
            />
            <FunctionField
              emptyText='-'
              source='duplicate'
              label={translate('resources.contacts.fields.duplicate')}
              render={(record) =>
                record?.duplicate
                  ? `Este contacto esta registrado en la entidad ${
                      entities[record.duplicate.entity]
                    } ${record.duplicate.id}`
                  : '-'
              }
            />
          </Datagrid>
        </ArrayField>
        <FormTitle title='Direcciones' className={classes.width100} />
        <ArrayField
          addLabel={false}
          source='addresses'
          fieldKey='id'
          className={classes.width100}
        >
          <Datagrid empty={<TablePlaceholder title='Direcciones' />}>
            <TextField
              emptyText='-'
              source='addressType.name'
              label={translate('resources.addresses.fields.addressType')}
            />
            <TextField
              emptyText='-'
              source='country.name'
              label={translate('resources.addresses.fields.country')}
            />
            <TextField
              emptyText='-'
              source='province.name'
              label={translate('resources.addresses.fields.province')}
            />
            <TextField
              emptyText='-'
              source='municipality.name'
              label={translate('resources.addresses.fields.municipality')}
            />
            <TextField
              emptyText='-'
              source='neighborhood.name'
              label={translate('resources.addresses.fields.neighborhood')}
            />
            <TextField
              emptyText='-'
              source='city.name'
              label={translate('resources.addresses.fields.city')}
            />
            <TextField
              emptyText='-'
              source='street'
              label={translate('resources.addresses.fields.street')}
            />
            <TextField
              emptyText='-'
              source='outsideNumber'
              label={translate('resources.addresses.fields.outsideNumber')}
            />
            <TextField
              emptyText='-'
              source='postalCode'
              label={translate('resources.addresses.fields.postalCode')}
            />
            <FunctionField
              emptyText='-'
              source='duplicate'
              label={translate('resources.contacts.fields.duplicate')}
              render={(record) =>
                record?.duplicate
                  ? `Esta dirección esta registrada en la entidad ${
                      entities[record.duplicate.entity]
                    } ${record.duplicate.id}`
                  : '-'
              }
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  );
};

export default ReferenceShow;
