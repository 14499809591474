import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import IncomeTypeList from './IncomeTypeList';

export const IncomeType: object = {
  list: IncomeTypeList,
  icon: AccountBalanceWalletIcon,
};

export default IncomeType;
