import LocalAtmIcon from '@material-ui/icons/LocalAtm';

import EconomicActivityList from './EconomicActivityList';

export const EconomicActivity: object = {
  list: EconomicActivityList,
  icon: LocalAtmIcon,
};

export default EconomicActivity;
