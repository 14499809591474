import React from 'react';
import { useRedirect } from 'react-admin';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Typography,
  makeStyles,
  Checkbox,
  Tooltip,
} from '@material-ui/core';

import { useUpdate } from 'react-admin';
import { RefreshCw, Disc, Loader, Inbox } from 'react-feather';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DateRangeIcon from '@material-ui/icons/DateRange';
import NoteAddedIcon from '@material-ui/icons/NoteAdd';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CommentIcon from '@material-ui/icons/Comment';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import type { Theme } from '../../../../Theme';

import { distanceInWordsToNow } from 'date-fns';
import esLocale from 'date-fns/locale/es';

const formatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'MXN',
  maximumFractionDigits: 0,
});

const iconsMap = {
  change_amount: AttachMoneyIcon,
  change_months: DateRangeIcon,
  change_interest_rate: TrendingUpIcon,
  change_referrer: SupervisorAccountIcon,
  change_agent: AssignmentIndIcon,
  change_analyst: AssignmentIndIcon,
  change_state: AssignmentTurnedInIcon,
  change_substate: AssignmentTurnedInIcon,
  change_lawyer: AssignmentIndIcon,
  change_manager: AssignmentIndIcon,
  change_lawyer_jr: AssignmentIndIcon,
  change_application_reason: CommentIcon,
};

const actionsMap = {
  change_amount: { subject: 'El monto', suffix: '' },
  change_months: { subject: 'El plazo', suffix: 'meses' },
  change_interest_rate: { subject: 'El interes', suffix: '%' },
  change_referrer: { subject: 'El referidor', suffix: '' },
  change_agent: { subject: 'El gestor', suffix: '' },
  change_analyst: { subject: 'El analista de riesgos', suffix: '' },
  change_state: { subject: 'El estado', suffix: '' },
  change_substate: { subject: 'El subestado', suffix: '' },
  change_manager: { subject: 'El gerente', suffix: '' },
  change_lawyer: { subject: 'El abogado Sr', suffix: '' },
  change_lawyer_jr: { subject: 'El abogado Jr', suffix: '' },
  change_application_reason: { subject: 'La razón del préstamo', suffix: '' },
};

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    paddingBottom: '10px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    alignSelf: 'flex-start',
  },
  childIcon: {
    width: 20,
    height: 20,
    marginRight: '5px',
  },
  listHeader: {
    padding: '0',
    cursor: 'pointer',
  },
  listItem: {
    padding: '0 0 0 60px',
  },
}));

const ToggleReadButton = ({
  notification,
  getNotifications,
  updateNotification,
}) => {
  return (
    <Checkbox
      checked={!notification.isRead}
      icon={<Disc size={15} />}
      checkedIcon={<Disc size={18} />}
      onChange={(e) => {
        notification.isRead = !e.target.checked;
        updateNotification(
          'notifications',
          notification.id,
          {
            isRead: !e.target.checked,
          },
          {},
          { onSuccess: () => getNotifications() }
        );
      }}
    />
  );
};

const NotificationCards = ({
  loading,
  notifications,
  getNotifications,
  handleClose,
  unreadOnly,
}) => {
  const classes = useStyles();
  const [updateNotification] = useUpdate();
  const redirect = useRedirect();

  const notificationsToDisplay = unreadOnly
    ? notifications.filter((notification) => notification.isRead === false)
    : notifications;

  const handleRedirect = (notification, loanApplicationId) => {
    const getURL = () => {
      switch (notification.type) {
        case 'loanApplication':
          return `/loan-applications/${loanApplicationId}/show`;
        case 'note':
        case 'mentionedInNote':
          return `/loan-applications/${loanApplicationId}/show/notes`;
        default:
          return `/loan-applications/${loanApplicationId}/show`;
      }
    };
    updateNotification('notifications', notification.id, {
      isRead: true,
    });
    redirect(getURL());
    handleClose();
  };

  const LoanApplicationDetails = ({ notification }) => (
    <>
      {notification.details.changes.map((change) => {
        const Icon = iconsMap[change.action];
        return (
          <ListItem key={change.action} className={classes.listItem}>
            <Icon className={classes.childIcon} color='disabled' />
            <ListItemText
              secondary={`${
                actionsMap[change.action].subject
              } se actualizó de ${
                change.action === 'change_amount'
                  ? formatter.format(change.previousValue)
                  : change.previousValue
              } a ${
                change.action === 'change_amount'
                  ? formatter.format(change.currentValue)
                  : change.currentValue
              } ${actionsMap[change.action].suffix}`}
              secondaryTypographyProps={{
                variant: 'caption',
              }}
            />
          </ListItem>
        );
      })}
    </>
  );

  const NoteDetails = () => (
    <ListItem className={classes.listItem}>
      <NoteAddedIcon className={classes.childIcon} color='disabled' />
      <ListItemText
        secondary='Se agregó una nota'
        secondaryTypographyProps={{
          variant: 'caption',
        }}
      />
    </ListItem>
  );

  const MentionedInNote = () => (
    <ListItem className={classes.listItem}>
      <PersonAddIcon className={classes.childIcon} color='disabled' />
      <ListItemText
        secondary='Fue mencionado en una nota'
        secondaryTypographyProps={{
          variant: 'caption',
        }}
      />
    </ListItem>
  );

  const Notification = ({ notification, NotificationDetails }) => (
    <ListItem
      divider
      key={notification.id}
      style={{ justifyContent: 'space-between' }}
    >
      <List disablePadding className={classes.list}>
        <ListItem
          key={notification.id}
          className={classes.listHeader}
          onClick={() =>
            handleRedirect(notification, notification.details.loanApplication)
          }
        >
          <ListItemAvatar>
            <Avatar className={classes.icon}>
              <SvgIcon fontSize='small'>
                <RefreshCw />
              </SvgIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`La solicitud de ${
              notification.legalPerson
                ? notification.legalPerson?.businessName
                : `${notification.person?.firstName} ${notification.person?.fatherLastName}`
            } fue actualizada`}
            primaryTypographyProps={{
              variant: 'subtitle2',
              color: 'textPrimary',
            }}
            secondary={distanceInWordsToNow(new Date(notification.created_at), {
              includeSeconds: true,
              locale: esLocale,
            })}
          />
        </ListItem>
        <NotificationDetails notification={notification} />
      </List>
      <Tooltip
        title={
          notification.isRead ? 'Marcar como no leído' : 'Marcar como leído'
        }
      >
        <ToggleReadButton
          updateNotification={updateNotification}
          notification={notification}
          getNotifications={getNotifications}
        />
      </Tooltip>
    </ListItem>
  );

  return (
    <>
      {loading ? (
        <Box p={3} className={classes.flexCenter}>
          <Loader size={50} />
          <Typography variant='h6' color='textPrimary'>
            Cargando...
          </Typography>
        </Box>
      ) : (
        <>
          {notificationsToDisplay.length === 0 ? (
            <Box p={3} className={classes.flexCenter}>
              <Inbox alignmentBaseline='central' size={40} />
              <Typography variant='h6' color='textPrimary'>
                No hay notificaciones para mostrar...
              </Typography>
            </Box>
          ) : (
            <List disablePadding>
              {notificationsToDisplay.map((notification) => {
                switch (notification.type) {
                  case 'loanApplication':
                    return (
                      <Notification
                        notification={notification}
                        NotificationDetails={LoanApplicationDetails}
                      />
                    );
                  case 'note':
                    return (
                      <Notification
                        notification={notification}
                        NotificationDetails={NoteDetails}
                      />
                    );
                  case 'mentionedInNote':
                    return (
                      <Notification
                        notification={notification}
                        NotificationDetails={MentionedInNote}
                      />
                    );
                  default:
                    return (
                      <Notification
                        notification={notification}
                        NotificationDetails={LoanApplicationDetails}
                      />
                    );
                }
              })}
            </List>
          )}
        </>
      )}
    </>
  );
};

export default NotificationCards;
