import React from 'react';
import {
  Create,
  FormTab,
  ListButton,
  TextInput,
  required,
  TabbedForm,
  TopToolbar,
  useNotify,
  useRedirect,
} from 'react-admin';
import { parse } from 'query-string';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { FormGeneral } from '../../styles';
import { errors, thereWasProblem } from '../../Utils/messages';
import { formatInputNumber, parseInputNumber } from '../../Utils/functions';

const Actions = ({ person, legalPerson }) => (
  <TopToolbar>
    <ListButton
      to={
        person
          ? `/people/${person}/show/credit-inquiry-unykoos`
          : `/legal-people/${legalPerson}/show/credit-inquiry-unykoos`
      }
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const BureausCreate = (props) => {
  const classes = FormGeneral();
  const notify = useNotify();
  const redirect = useRedirect();
  const { person: personString, 'legal-person': legalPersonString } = parse(
    props.location.search
  );
  const person = personString ? parseInt(personString, 10) : '';
  const legalPerson = legalPersonString ? parseInt(legalPersonString, 10) : '';

  const onSuccess = () => {
    notify('Solicitud creada');
    redirect(
      person
        ? `/people/${person}/show/credit-inquiry-unykoos`
        : `/legal-people/${legalPerson}/show/credit-inquiry-unykoos`
    );
  };
  const onFailure = (error) => {
    const code = error.body.data.errorCode;
    notify(
      `${thereWasProblem}: ${
        errors[code] || 'Hubo un error con su solicitud, inténtelo mas tarde...'
      }`,
      'warning'
    );
  };

  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      onFailure={onFailure}
      actions={<Actions person={person} legalPerson={legalPerson} />}
    >
      <TabbedForm initialValues={{ person, legalPerson }} variant='outlined'>
        <FormTab label='Buró de crédito' contentClassName={classes.root}>
          <TextInput
            formClassName={classes.width50}
            fullWidth
            defaultValue='0'
            format={(v) => formatInputNumber(v)}
            parse={(v) => parseInputNumber(v)}
            source='amount'
            validate={required()}
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default BureausCreate;
