import React from 'react';
import PropTypes from 'prop-types';

interface ContactInterface {
  contactType: string;
  emptyText?: string;
  label?: string;
  record?: object;
  source: string;
  sortable?: boolean;
}

export const ContactField: React.FC<ContactInterface> = (props) => {
  const { record, source, contactType, emptyText = '-' } = props;
  const data: any = record?.[source]?.find(
    (res) => res && res.contactType?.name === contactType
  );
  return (
    <React.Fragment>
      <span>{data?.contact ? data.contact : emptyText}</span>
    </React.Fragment>
  );
};

ContactField.propTypes = {
  label: PropTypes.string,
};

ContactField.defaultProps = {
  sortable: false,
};

export default ContactField;
