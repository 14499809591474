import React, { useState } from 'react';
import {
  SaveButton,
  useCreate,
  useNotify,
  useRefresh,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';

import DraftTextInputNotes from './DraftTextInputNotes';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& div.ql-editor': {
      minHeight: '80px',
    },
  },
}));

const NoteForm = ({ segment, related, input }) => {
  const classes = useStyles();
  const [create, { loading }] = useCreate('contact-histories');
  const notify = useNotify();
  const refresh = useRefresh();
  const employee = JSON.parse(localStorage.getItem('employee') || '{}');
  const [mentionData, setMentionData] = useState({} as any);
  const [mentionTouched, setMentionTouched] = useState(false);

  const handleSubmit = async (values) => {
    const { htmlText, mentions, errors: mentionErrors } = mentionData;
    if (!mentionTouched) {
      setMentionTouched(true);
      return;
    }
    if (mentionErrors?.required) return;

    values.description = htmlText;
    values.mentions = mentions;

    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  const handleChangeMention = (htmlText, mentions, errors) => {
    setMentionData({
      htmlText,
      mentions,
      errors,
    });
  };

  return (
    <>
      <FormWithRedirect
        className={classes.root}
        initialValues={{
          segment,
          related,
          contactHistoryType: null,
          noteSource: 'manual',
          employee: employee.id,
        }}
        save={handleSubmit}
        render={({ handleSubmitWithRedirect, saving }) => (
          <>
            <ReferenceInput
              label='Tipo de nota'
              reference='contact-history-types'
              source='contactHistoryType'
              fullWidth
              variant='outlined'
              filter={{ isVisible: true }}
            >
              <SelectInput optionText='name' />
            </ReferenceInput>
            <DraftTextInputNotes
              id='textInputNotesNew'
              key='textInputNotesNew'
              onChange={handleChangeMention}
              touched={mentionTouched}
              setTouched={setMentionTouched}
              isRequired
            />
            <SaveButton
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              saving={saving}
              disabled={loading}
            />
          </>
        )}
      />
    </>
  );
};

export default NoteForm;
