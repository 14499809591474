import React from 'react';
import { FunctionField } from 'react-admin';

const CustomDateInput = ({ source }) => {
  return (
    <FunctionField
      render={(record) =>
        record[source]
          ? `${record[source].split('-')[2]}/${record[source].split('-')[1]}/${
              record[source].split('-')[0]
            }`
          : '-'
      }
    />
  );
};

export default CustomDateInput;
