import React, { Fragment } from 'react';
import { TextField } from 'react-admin';
import DescriptionIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import PdfIcon from '../../assets/icons/file-pdf-box.png';

const useStyles = makeStyles(() => ({
  Icon: {
    position: 'relative ',
    top: '6.8px ',
  },
}));

const DocumentIconNameField = (props) => {
  const classes = useStyles();
  switch (props.record?.documents[0]?.ext) {
    case '.pdf':
      return (
        <Fragment>
          <img src={PdfIcon} alt='' className={classes.Icon} />
          <TextField {...props} />
        </Fragment>
      );
    case '.jpeg':
    case '.png':
    case '.jpg':
      return (
        <Fragment>
          <ImageIcon
            alt={props.record?.name}
            className={classes.Icon}
            color='secondary'
          />
          <TextField {...props} />
        </Fragment>
      );
    default:
      return (
        <Fragment>
          <DescriptionIcon
            alt={props.record?.name}
            className={classes.Icon}
            style={{ color: green[500] }}
          />
          <TextField {...props} />
        </Fragment>
      );
  }
};

export default DocumentIconNameField;
