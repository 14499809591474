export const getSimpleText = (rawObject: any) =>
  rawObject.blocks
    .map((block) => (!block.text.trim() && '\n') || block.text)
    .join('\n');

export const getErrors = (getSimpleText: string, errors: any) => {
  const { required, ...rest } = errors;
  if (getSimpleText && getSimpleText.length > 0 && getSimpleText !== '\n')
    return rest;
  return {
    required: 'IsRequired',
    ...rest,
  };
};
