import React from 'react';
import {
  Datagrid,
  FunctionField,
  ReferenceManyField,
  useTranslate,
} from 'react-admin';

import { FormGeneral } from '../../styles';
import TablePlaceholder from '../../Components/TablePlaceholder';
import DocumentIconNameField from '../Documents/DocumentIconNameField';
import DocumentViewButton from '../Documents/DocumentViewButton';

const ImageList = (props) => {
  const classes = FormGeneral();
  const translate = useTranslate();
  const { id } = props.record;
  const segment = props.segment;

  return (
    <ReferenceManyField
      reference='documents'
      target='person'
      filter={{
        segment,
        related: id,
      }}
      perPage={-1}
      addLabel={false}
      className={classes.width100}
    >
      <Datagrid empty={<TablePlaceholder title='Imágenes' />}>
        <DocumentIconNameField
          source='documents[0].name'
          emptyText='-'
          label={translate('resources.promotions.fields.document')}
          sortable={false}
        />
        <FunctionField
          render={(record) => <DocumentViewButton record={record} />}
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ImageList;
