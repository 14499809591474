import React from 'react';
import { Edit, FormTab, TabbedForm, TopToolbar, ListButton } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AssetsForm from './AssetsForm';
import CustomToolbar from '../../Components/CustomToolbar';

const Actions = ({ data }) => (
  <TopToolbar>
    <ListButton
      to={`/assets/${data?.id}/show`}
      icon={<ChevronLeft />}
      label='ra.action.back'
    />
  </TopToolbar>
);

const AssetsEdit = (props) => {
  return (
    <Edit title=' ' {...props} actions={<Actions {...props} />}>
      <TabbedForm
        redirect='show'
        variant='outlined'
        toolbar={<CustomToolbar />}
      >
        <FormTab label='Bien'>
          <AssetsForm />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default AssetsEdit;
