import React from 'react';
import { List, ListSubheader } from '@material-ui/core';

import { Section } from './interface';
import { NavSectionItem } from './NavSectionItem';

export const NavSection = ({ subheader, items }: Section) => (
  <List
    subheader={
      <ListSubheader disableGutters disableSticky>
        {subheader}
      </ListSubheader>
    }
  >
    {items.map((item) =>
      item.hasItemPermission ? (
        <NavSectionItem key={item.title} {...item} />
      ) : null
    )}
  </List>
);
