import React, { useState } from 'react';
import {
  Button,
  useMutation,
  useNotify,
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  useRefresh,
  TextInput,
  required,
} from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';

import { makeStyles } from '@material-ui/styles';
import { thereWasProblem, errors } from '../../Utils/messages';
import { Theme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingInline: theme.spacing(0.5),
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
  },
  content: { '& > *': { padding: '0 !important' } },
  buttonSpacing: {
    margin: '0rem 0rem 1rem 0rem;',
  },
}));

const FranchiseAssignButton = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [referrer, setReferrer] = useState('');
  const [justification, setJustification] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setReferrer('');
    setJustification('');
    setOpen(false);
  };

  const [handleSubmit, { loading }] = useMutation(
    {
      type: 'create',
      resource: 'loan-applications/assign-referrer',
      payload: {
        data: { loanApplications: props.selectedIds, referrer, justification },
      },
    },
    {
      onSuccess: () => {
        handleClose();
        notify('Referidor asignado exitosamente');
        refresh();
      },
      onFailure: (error) => {
        notify(
          `${thereWasProblem}: ${
            (error.body.data ? errors[error.body?.data?.errorCode] : error.body.error) ||
            'Fallo general'
          }`,
          'warning'
        );
      },
    }
  );

  return (
    <div className={classes.root}>
      <Button
        className={classes.buttonSpacing}
        endIcon={<AssignmentTurnedInRoundedIcon />}
        variant='contained'
        label='Asignar referidor'
        onClick={handleOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        maxWidth='xs'
        fullWidth
      >
        <MuiDialogTitle id='form-dialog-title' disableTypography>
          <Typography variant='h4'>Brokers</Typography>
        </MuiDialogTitle>
        <DialogContent>
          <SimpleForm toolbar={<></>} className={classes.content}>
            <ReferenceInput
              source='franchise'
              reference='franchises'
              fullWidth
              variant='outlined'
              resource='loan-applications'
            >
              <AutocompleteInput
                onSelect={(record) => setReferrer(record.id)}
                optionText={(record) =>
                  record && `${record.code} | ${record.name ?? ''}`
                }
              />
            </ReferenceInput>
            <TextInput
              fullWidth
              variant='outlined'
              validate={required()}
              source='justification'
              resource='loan-applications'
              onBlur={(e) => setJustification(e.target.value)}
            />
          </SimpleForm>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            label='Cancelar'
            onClick={() => handleClose()}
            color='primary'
          />
          <Button
            label='Asignar'
            onClick={() => {
              if (justification && referrer) {
                handleSubmit();
                handleClose();
              }
            }}
            color='primary'
            disabled={loading}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FranchiseAssignButton;
