import React, { useState, useRef } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';

import documentProvider from '../../Providers/documentProvider';
import { changeMade, thereWasProblem } from '../../Utils/messages';
import useSelectDataProvider from '../../hooks/useSelectDataProvider';

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    padding: '4px 10px 4px 10px',
    width: '100%',
  },
}));

interface UploadI {
  basePath?: any;
  record?: any;
  segment?: string;
  related?: number;
  documentSegment: number;
  reserved?: boolean;
}

const DocumentUploadButton = ({
  basePath,
  record,
  segment,
  related,
  documentSegment,
  reserved = false,
}: UploadI) => {
  const filter = {
    segment: 'files',
    documentSegment,
    active:  true,
  };
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const documentTypes = useSelectDataProvider('document-types', filter);
  const [selectedIndex, setSelectedIndex] = useState();
  const anchorRef = useRef(null);
  const classes = useStyles();

  filter[reserved ? 'private' : 'private_ne'] = true;

  const handleClick = (event) => {
    setOpen(false);
    setLoading(true);

    let data = {
      related,
      documentType: selectedIndex,
      segment,
      private: reserved,
    };
    const formData = new FormData();
    if (event.target.files.length === 1) {
      const file = event.target.files[0];
      formData.append(`files.documents`, file, file.name);
    } else {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        formData.append(`files.documents`, file, file.name);
      }
    }
    formData.append('data', JSON.stringify(data));

    documentProvider
      .uploadDocument(formData)
      .then(() => {
        notify(changeMade, 'success');
      })
      .catch((error) => {
        notify(
          `${thereWasProblem}: ${error.body?.data?.errors || 'Fallo general'}`,
          'warning'
        );
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container direction='column' alignItems='flex-end'>
      <Grid item xs={12}>
        <ButtonGroup
          aria-label='split button'
          color='secondary'
          ref={anchorRef}
          variant='contained'
          disabled={loading}
        >
          <Button onClick={handleToggle} startIcon={<CloudUploadIcon />}>
            Subir archivo
          </Button>
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='menu'
            aria-label='select merge strategy'
            onClick={handleToggle}
            size='small'
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          anchorEl={anchorRef.current}
          placement='top'
          disablePortal
          open={open}
          role={undefined}
          style={{ zIndex: 3 }}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id='split-button-menu'>
                    {documentTypes.map((value) => (
                      <MenuItem
                        key={value?.name}
                        selected={value?.id === selectedIndex}
                        style={{ padding: '0px' }}
                        onClick={(event) =>
                          handleMenuItemClick(event, value.id)
                        }
                        className={classes.root}
                        htmlFor={value?.name}
                      >
                        <label
                          className={classes.root}
                          htmlFor={`${value?.id}`}
                        >
                          {value?.name}
                          <input
                            hidden
                            id={`${value?.id}`}
                            name={`${value?.name}`}
                            onChange={handleClick}
                            title={value?.name}
                            type='file'
                            multiple
                          />
                        </label>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default DocumentUploadButton;
