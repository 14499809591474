import React from 'react';
import { Button, useMutation, useNotify, useRefresh } from 'react-admin';

import Retry from '@material-ui/icons/Cached';

import { makeStyles } from '@material-ui/styles';
import { errors, thereWasProblem } from '../Utils/messages';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const CreditCircleRetry = ({ record }) => {
  const notify = useNotify();
  const classes = useStyles();
  const refresh = useRefresh();

  const [handleSubmit, { loading }] = useMutation(
    {
      type: 'create',
      resource: 'credit-inquiry-unykoos',
      payload: {
        data: {
          person: record.person,
          legalPerson: record.legalPerson,
          amount: record.amount,
        },
      },
    },
    {
      onSuccess: () => {
        notify('Actualizado correctamente');
        refresh();
      },
      onFailure: (error) => {
        const code = error.body.data.errorCode;
        notify(
          `${thereWasProblem}: ${
            errors[code] ||
            'Hubo un error con su solicitud, inténtelo mas tarde...'
          }`,
          'warning'
        );
      },
    }
  );

  return (
    <div className={classes.root}>
      <Button
        variant='contained'
        startIcon={<Retry />}
        color='secondary'
        label='Reintentar'
        onClick={handleSubmit}
        disabled={loading}
      />
    </div>
  );
};

export default CreditCircleRetry;
