import { Edit, ListButton, TopToolbar } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import ReferenceTypeForm from './ReferenceTypeForm';

const ReferenceTypeActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const ReferenceTypeEdit = (props: any) => {
  return (
    <Edit actions={<ReferenceTypeActions {...props} />} {...props}>
      <ReferenceTypeForm {...props} />
    </Edit>
  );
};

export default ReferenceTypeEdit;
