import React from 'react';
import {
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin';

import { Grid } from '@material-ui/core';
import FormTitle from '../../Components/FormTitle';
import Contacts from '../../Components/Contacts';
import Addresses from '../../Components/Addresses';

const ReferencesForm = ({ className = '', ...rest }) => {
  const translate = useTranslate();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <ReferenceInput
            source={'referenceType'}
            reference='reference-types'
            fullWidth
            label={translate('resources.references.fields.referenceType')}
            validate={[required()]}
            variant='outlined'
          >
            <SelectInput />
          </ReferenceInput>
        </Grid>
        <Grid item xs={7}>
          <TextInput
            source='name'
            variant='outlined'
            resource='references'
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormTitle title='Contactos' />
          <Contacts record={rest.record} isValidate={true} />
        </Grid>
        <Grid item xs={12}>
          <FormTitle title='Direcciones' />
          <Addresses record={rest.record} isValidate={true} />
        </Grid>
      </Grid>
    </>
  );
};

export default ReferencesForm;
