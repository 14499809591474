import * as React from 'react';
import { FormShow } from '../../styles';
import { ArrayField, Tab, TabbedShowLayout, TextField, Datagrid, BooleanField } from 'react-admin';
import TablePlaceholder from '../../Components/TablePlaceholder';

export const RoleStatusInfoPanelShow = () => {
  const classes = FormShow();

  return (
    <TabbedShowLayout>
      <Tab label="Estados de solicitudes" contentClassName={classes.flexParent}>
        <ArrayField
          fieldKey='id'
          addLabel={false}
          label=""
          source='state'
          className={classes.width100}
        >
          <Datagrid
            empty={<TablePlaceholder title="Estados" />}
          >
            <TextField source='code_id' resource='states' label='Id' />
            <TextField source='name' resource='states' label='Nombre'/>
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  );
};

export default RoleStatusInfoPanelShow;