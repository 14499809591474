import HomeIcon from '@material-ui/icons/Home';

import DepartmentList from './DepartmentList';

export const Department: object = {
  list: DepartmentList,
  icon: HomeIcon,
};

export default Department;
