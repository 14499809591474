import spanishMessages from './ra-language-spanish';

const esTranslations = {
  ...spanishMessages,
  resources: {
    people: {
      name: 'Persona |||| Personas',
      fields: {
        fullName: 'Nombre Completo',
        documentNumber: 'Número de documento',
        cellPhone: 'Celular',
        email: 'Email',
        documentType: 'Tipo de documento',
        'documentType.id': 'Tipo de documento',
        'documentType.name': 'Tipo de documento',
        firstName: 'Nombre',
        secondName: 'Segundo Nombre',
        sex: 'Sexo',
        fatherLastName: 'Apellido Paterno',
        motherLastName: 'Apellido Materno',
        birthday: 'Fecha de nacimiento',
        maritalStatus: 'Estado civil',
        'maritalStatus.id': 'Estado civil',
        'maritalStatus.name': 'Estado civil',
        academicLevel: 'Nivel académico',
        'academicLevel.id': 'Nivel académico',
        'academicLevel.name': 'Nivel académico',
        countryOfBirth: 'País de nacimiento',
        'countryOfBirth.id': 'País de nacimiento',
        'countryOfBirth.name': 'País de nacimiento',
        nationality: 'Nacionalidad',
        'nationality.id': 'Nacionalidad',
        'nationality.nationality': 'Nacionalidad',
        occupation: 'Ocupación',
        'occupation.id': 'Ocupación',
        'occupation.name': 'Ocupación',
        economicActivity: 'Actividad económica',
        'economicActivity.id': 'Actividad económica',
        'economicActivity.name': 'Actividad económica',
        profession: 'Profesión',
        'profession.id': 'Profesión',
        'profession.name': 'Profesión',
        isEmployee: 'Es empleado?',
        isClient: 'Es Cliente?',
        blacklist: 'Blacklist?',
        onboardingGuaranteeType: 'Tipo de garantía',
        onboardingGuaranteeValue: 'Valor estimado de garantía',
        billingIncome: 'Ingresos por facturación',
        statementIncome: 'Ingresos por estado de cuenta',
        financialInformationIncome: 'Ingresos por información financiera',
        agent: 'Gestor',
        hasGuarantee: '¿Cuentas con garantía?',
        isGuaranteeOwner: '¿Eres propietario de esta garantía?',
      },
    },
    dashboard: {
      zone: 'Zona o equipo',
    },
    cities: {
      name: 'Ciudad |||| Ciudades',
      fields: {
        id: 'id',
        name: 'Ciudad',
      },
    },
    'legal-people': {
      name: 'Persona Moral |||| Personas Morales',
      fields: {
        firstName: 'Nombre',
        businessName: 'Nombre de compañía',
        documentType: 'Tipo de documento',
        documentNumber: 'Número de documento',
        constitutionDate: 'Fecha de constitución',
        constitutionCountry: 'País de constitución',
        'constitutionCountry.id': 'País de constitución',
        registerIssueDate: 'Fecha de emisión de registro',
        tradeName: 'Razón social',
        commercialRegister: 'Registro comercial',
        constitutionCountries: 'Paises de constitución',
        registerExpirationDate: 'Fecha de expiración de registro',
        legalRepresentatives: 'Representantes Morales',
        physicalRepresentatives: 'Representantes Físicos',
        blacklist: 'Blacklist?',
        billingIncome: 'Ingresos por facturación',
        onboardingGuaranteeType: 'Tipo de garantía',
        onboardingGuaranteeValue: 'Valor estimado de garantía',
        statementIncome: 'Ingresos por estado de cuenta',
        financialInformationIncome: 'Ingresos por información financiera',
        agent: 'Gestor',
        hasGuarantee: '¿Cuentas con garantía?',
        isGuaranteeOwner: '¿Eres propietario de esta garantía?',
      },
      validation: {
        rfc: 'Formato de RFC inválido'
      }
    },
    'credit-inquiry-unykoos': {
      name: 'Buró de crédito |||| Buró de crédito',
      fields: {
        name: 'Nombre',
        amount: 'Monto',
        person: 'Nombre completo',
        created_at: 'Fecha de creación',
        requestDate: 'Fecha de confirmación',
      },
    },
    'loan-applications': {
      name: 'Solicitud |||| Solicitudes',
      fields: {
        person: 'Persona',
        'person.id': 'Persona',
        company: 'Compañía',
        'company.name': 'Compañía',
        amount: 'Monto',
        interestRate: 'Tasa de interés',
        months: 'Meses',
        agent: 'Gestor',
        analyst: 'Analista de riesgo',
        manager: 'Gerente',
        lawyer: 'Abogado formalización',
        lawyerJr: 'Abogado Jr',
        'agent.id': 'Gestor',
        'agent.code': 'Gestor',
        cbLoan: 'ID de Préstamo',
        cbApprovalDate: 'Fecha de aprobación',
        'agent.person': 'Gestor',
        referrerCode: 'Código de referidor',
        referrer: 'Referidor',
        contractNumber: 'Número de contrato',
        'referrer.id': 'Referidor',
        'referrer.code': 'Referidor',
        state: 'Estado',
        'state.id': 'Estado',
        'state.name': 'Estado',
        substate: 'Subestado',
        'substate.name': 'Subestado',
        status: 'Subestado',
        justification: 'Justificación',
        applicationType: 'Tipo de solicitud',
        'applicationType.id': 'Tipo de solicitud',
        'applicationType.name': 'Tipo de solicitud',
        applicationReason: 'Razón de solicitud',
        'applicationReason.id': 'Razón de solicitud',
        'applicationReason.name': 'Razón de solicitud',
        applicationSource: 'Fuente de la solicitud',
        'applicationSource.id': 'Fuente de la solicitud',
        'applicationSource.name': 'Fuente de la solicitud',
        clientSource: 'Fuente del cliente',
        'clientSource.id': 'Fuente del cliente',
        'clientSource.name': 'Fuente del cliente',
        fundingCompany: 'Institución financiera',
        'fundingCompany.id': 'Institución financiera',
        'fundingCompany.name': 'Institución financiera',
        country: 'País',
        legalPerson: 'Persona moral',
        'legalPerson.id': 'Persona moral',
        personType: 'Tipo de persona',
        created_at_lt: 'Creación antes de',
        created_at_gt: 'Creación a partir de',
        updated_at: 'Última edición',
        created_at: 'Fecha de creación',
        stateChangeDate: 'Fecha en bandeja',
        requestedAmount: 'Monto',
        requestedTerm: 'Plazo (meses)',
        requestedRate: 'Tasa mensual (%)',
        promoInterest: 'Tasa promoción (%)',
        requestedExtensionTerm: 'Meses de prórroga',
        feeWithoutExtension: 'Cuota sin prórroga',
        feeWithExtension: 'Cuota con prórroga',
        withIVA: 'Con IVA',
        expensesIVA: 'Gastos',
        interestIVA: 'Interés',
        evaluationUnit: 'Unidad de evaluación',
        'evaluationUnit.id': 'Unidad de evaluación',
        'evaluationUnit.name': 'Unidad de evaluación',
        notary: 'Notaría',
        'notary.id': 'Notaría',
        'notary.name': 'Notaría',
        appraisedValue: 'Valor de avalúo',
        franchise: 'Broker',
        currentBalance: 'Saldo actual',
        'legalRepresentative.currentBalance': 'Saldo actual',
        'legalRepresentative.currentPay': 'Pago actual',
        'legalRepresentative.score': 'Score',
        'coacreedor.score': 'Score',
        'guarantee.score': 'Score',
        pastDueBalance: 'Saldo vencido',
        'legalRepresentative.pastDueBalance': 'Saldo vencido',
        'coacreedor.pastDueBalance': 'Saldo vencido',
        'guarantee.pastDueBalance': 'Saldo vencido',
        currentPay: 'Pago actual',
        'coacreedor.currentPay': 'Pago actual',
        'guarantee.currentPay': 'Pago actual',
        'probability.amount': 'Monto',
        'probability.months': 'Plazo',
        'probability.interestRate': 'Tasa',
        maxCreditCapacity: 'Capacidad maxima de credito',
        casesWatched: 'Caso visto en comite',
        ciecIncomes: 'Ingresos por ciec',
        comment: 'Comentarios',
        pending: 'Pendiente',
        openingCommission: 'Comisión de apertura',
        department: 'Regional / Equipo',
        grantDate: 'Fecha de otorgamiento',
        hasGuarantee: 'Credito con garantía',
        guaranteeAmount: 'Monto en garantía',
        credit_type: 'Tipo de crédito',
        'credit_type.name': 'Tipo de crédito',
        'credit_type.id': 'Tipo de crédito',
      },
    },
    franchises: {
      name: 'Franquicia |||| Franquicias',
      fields: {
        created_at: 'Fecha de creación',
        fullName: 'Nombre Completo',
        documentNumber: 'Número de documento',
        cellPhone: 'Celular',
        name: 'Nombre',
        code: 'Código',
        contacts: 'Contactos',
        manager: 'Encargado',
        masterBroker: 'Master Broker',
        'masterBroker.name': 'Master Broker',
        agent: 'Gestor',
        'agent.id': 'Gestor',
        'agent.code': 'Gestor',
      },
    },
    blacklists: {
      name: 'Blacklist |||| Blacklists',
      fields: {
        created_at: 'Fecha de ingreso',
        exitDate: 'Fecha de salida',
        data: 'Nombre',
        agent: 'Gestor',
        blacklistDictum: 'Dictamen',
        'data.id': 'Id',
        'data.blacklist': 'Sigue en blacklist?',
        'data.code': 'Código',
        'dictum.code': 'Código',
        'data.documentNumber': 'Número de documento',
      },
    },
    addresses: {
      name: 'Dirección |||| Direcciones',
      fields: {
        addressType: 'Tipo de Dirección',
        country: 'País',
        province: 'Estado',
        municipality: 'Municipio',
        neighborhood: 'Colonia',
        city: 'Ciudad',
        street: 'Calle',
        reference: 'Referencia',
        outsideNumber: 'Número Exterior',
        insideNumber: 'Número Interior',
        postalCode: 'Código Postal',
        name: 'Direcciones',
      },
    },
    contacts: {
      name: 'Contacto |||| Contactos',
      fields: {
        contactType: 'Tipo de Contacto',
        contact: 'Contacto',
        description: 'Descripción',
        name: 'Contactos',
        duplicate: 'Duplicado',
      },
    },
    promotions: {
      name: 'Promoción |||| Promociones',
      fields: {
        name: 'Nombre',
        initialDate: 'Fecha de inicio',
        finalDate: 'Fecha de fin',
        document: 'Imágenes',
      },
    },
    'other-documents': {
      name: 'Otros documentos',
      fields: {
        documentType: 'Tipo de documento',
        documentNumber: 'Número de documento',
        name: 'Otros documentos',
      },
    },
    references: {
      name: 'Referencia |||| Referencias',
      fields: {
        referenceType: 'Tipo',
        'contacts[0].contact': 'Contacto',
        confirmDelete: '¿Está seguro que desea eliminar esta referencia?',
        name: 'Nombre',
      },
    },
    'related-documents': {
      name: 'Documento relacionado |||| Documentos relacionados',
      fields: {
        'documents[0].name': 'Nombre',
        'documentType.name': 'Tipo de documento',
        size: 'Tamaño',
        created_at: 'Fecha de creación',
      },
    },
    representatives: {
      name: 'Representante |||| Representantes',
      fields: {
        representativeType: 'Tipo',
        legalPerson: 'Persona moral',
        physicalPerson: 'Persona física',
        observation: 'Observación',
        personType: 'Tipo de persona',
        person: 'Persona',
        name: 'Representantes',
      },
    },
    employees: {
      name: 'Empleado |||| Empleados',
      fields: {
        code: 'Código',
        person: 'Persona',
        'person.id': 'Persona',
        company: 'Compañía',
        department: 'Departamento',
        position: 'Posición',
        state: 'Estado',
        'department.id': 'Departamento',
        'department.name': 'Departamento',
        'position.name': 'Posición',
        'state.name': 'Estado',
      },
    },
    users: {
      name: 'Usuario |||| Usuarios',
      fields: {
        person: 'Persona',
        email: 'Correo',
        password: 'Contraseña',
        confirmed: 'Confirmado',
        blocked: 'Bloqueado',
        userType: 'Tipo de usuario',
        'userType.id': 'Tipo de usuario',
        'userType.name': 'Tipo de usuario',
      },
    },
    related: {
      name: 'Relacionado |||| Relacionados',
      fields: {
        relatedType: 'Tipo',
        relatedLegalPerson: 'Persona moral',
        relatedPerson: 'Persona física',
        personType: 'Tipo de persona',
        person: 'Persona',
        confirmDelete: '¿Está seguro que desea eliminar este relacionado?',
        name: 'Relacionados',
      },
    },
    incomes: {
      name: 'Ingreso |||| Ingresos',
      fields: {
        name: 'Ingreso',
        incomeType: 'Tipo',
        companyName: 'Compañía',
        position: 'Posición',
        incomes: 'Ingresos',
        effectiveDate: 'Fecha de inicio',
        expirationDate: 'Fecha de expiración',
        confirmDelete: '¿Está seguro que desea eliminar este ingreso?',
        supervisor: 'Supervisor',
      },
    },
    documents: {
      name: 'Documento |||| Documentos',
      fields: {
        name: 'Nombre',
        documentType: 'Tipo de documento',
        size: 'Tamaño',
        created_at: 'Fecha de creación',
      },
    },
    'state-rules': {
      name: 'Regla de estado |||| Reglas de estado',
      fields: {
        name: 'Nombre',
        applicationType: 'Tipo de solicitud',
        actualState: 'Estado actual',
        futureState: 'Estado futuro',
        position: 'Posición',
        'position.id': 'Posición',
        'position.name': 'Posición',
        operator: 'Operador',
        'operator.id': 'Operador',
        'operator.name': 'Operador',
        parameter: 'Parámetro',
        'parameter.id': 'Parámetro',
        'parameter.name': 'Parámetro',
        documentType: 'Documento',
        'documentType.id': 'Documento',
        'documentType.name': 'Documento',
        documentSegment: 'Segmento',
        'documentSegment.id': 'Segmento',
        'documentSegment.name': 'Segmento',
        value: 'Valor',
        state: 'Estado',
      },
    },
    'academic-levels': {
      name: 'Nivel academico |||| Niveles academicos',
      fields: {
        name: 'Nombre',
      },
    },
    'asset-types': {
      name: 'Tipo de bien |||| Tipos de bienes',
      fields: {
        name: 'Nombre',
        segment: 'Tipo',
      },
    },
    'address-types': {
      name: 'Tipo de ingreso |||| Tipos de ingresos',
      fields: {
        name: 'Nombre',
      },
    },
    'contact-types': {
      name: 'Tipo de contacto |||| Tipos de contacto',
      fields: {
        name: 'Nombre',
      },
    },
    'document-types': {
      name: 'Tipo de documento |||| Tipos de documentos',
      fields: {
        name: 'Nombre',
        documentSegment: 'Entidad',
        'documentSegment.id': 'Entidad',
        'documentSegment.name': 'Entidad',
        segment: 'Segmento',
        private: 'Privado',
        active: 'Activo',
      },
    },
    notaries: {
      name: 'Notaría |||| Notarías',
      fields: {
        name: 'Nombre',
        number: 'Número',
        country: 'País',
        'country.id': 'País',
        'country.name': 'País',
        province: 'Estado',
        'province.id': 'Estado',
        'province.name': 'Estado',
        municipality: 'Municipio',
        'municipality.id': 'Municipio',
        'municipality.name': 'Municipio',
      },
    },
    'evaluation-units': {
      name: 'Unidad de evaluación |||| Unidades de evaluación',
      fields: {
        name: 'Nombre',
      },
    },
    professions: {
      name: 'Profesión |||| Profesiones',
      fields: {
        name: 'Nombre',
      },
    },
    departments: {
      name: 'Departamento |||| Departamentos',
      fields: {
        name: 'Nombre',
        company: 'Compañía',
        'company.id': 'Compañía',
        'company.name': 'Compañía',
        parentDepartment: 'Departamento padre',
        'parentDepartment.id': 'Departamento padre',
        'parentDepartment.name': 'Departamento padre',
        lawyer: 'Abogado Sr',
        'lawyer.id': 'Abogado Sr',
      },
    },
    positions: {
      name: 'Posición |||| Posiciones',
      fields: {
        name: 'Nombre',
        company: 'Compañía',
        'company.id': 'Compañía',
        'company.name': 'Compañía',
        department: 'Departamento',
        'department.id': 'Departamento',
        'department.name': 'Departamento',
        supervisor: 'Posición supervisora',
        'supervisor.id': 'Posición supervisora',
        'supervisor.name': 'Posición supervisora',
      },
    },
    'marital-statuses': {
      name: 'Estado civil |||| Estados civiles',
      fields: {
        name: 'Nombre',
      },
    },
    'economic-activities': {
      name: 'Actividad económica |||| Actividades económicas',
      fields: {
        name: 'Nombre',
      },
    },
    'reference-types': {
      name: 'Tipo de referencia |||| Tipos de referencias',
      fields: {
        name: 'Nombre',
      },
    },
    occupations: {
      name: 'Ocupación |||| Ocupaciones',
      fields: {
        name: 'Nombre',
      },
    },
    'related-types': {
      name: 'Tipo de relacionado |||| Tipos de relacionados',
      fields: {
        name: 'Nombre',
      },
    },
    'income-types': {
      name: 'Tipo de ingreso |||| Tipos de ingresos',
      fields: {
        name: 'Nombre',
      },
    },
    'application-reasons': {
      name: 'Razón de solicitud |||| Razones de solicitud',
      fields: {
        name: 'Nombre',
      },
    },
    'application-sources': {
      name: 'Fuente de solicitud |||| Fuentes de solicitud',
      fields: {
        name: 'Nombre',
      },
    },
    'application-types': {
      name: 'Tipo de solicitud |||| Tipos de solicitud',
      fields: {
        ApplicationType: 'Tipo de solicitud',
        name: 'Nombre',
        id: 'Id',
        code_id: 'Id',
        minAmount: 'Monto mínimo',
        maxAmount: 'Monto máximo',
        amountStep: 'Rango de monto',
        minTerm: 'Plazo mínimo',
        maxTerm: 'Plazo máximo',
        termStep: 'Rangos de meses',
        allowDuplicates: 'Permite Solicitudes duplicadas',
      },
    },
    'client-sources': {
      name: 'Fuente de cliente |||| Fuentes de cliente',
      fields: {
        name: 'Nombre',
      },
    },
    banks: {
      name: 'Banco |||| Bancos',
      fields: {
        name: 'Nombre',
      },
    },
    substates: {
      name: 'Subestado |||| Subestados',
      fields: {
        name: 'Nombre',
        parentState: 'Estado padre',
        'parentState.id': 'Estado padre',
        'parentState.name': 'Estado padre',
      },
    },
    assets: {
      name: 'Bien |||| Bienes',
      fields: {
        state: 'Estado',
        assetTypeFilter: 'Bien',
        assetType: 'Tipo',
        assetTypeParameter: 'Detalle',
        documentType: 'Tipo de documento',
        documentNumber: 'Número de documento',
        brand: 'Marca',
        model: 'Modelo',
        year: 'Año',
        value: 'Valor del avalúo',
        isInsured: '¿Tiene seguro?',
        legalOpposition: '¿Oposición legal?',
        confirmDelete: '¿Está seguro que desea eliminar este bien?',
        haveDeed: '¿Tiene título?',
        propertyUse: 'Uso de la propiedad',
        'documentType.name': 'Tipo de documento',
        'assetType.name': 'Tipo',
      },
    },
    guarantees: {
      name: 'Garantía |||| Garantías',
      fields: {
        asset: 'Bien',
        guarantor: 'Garante',
        owner: 'Dueño',
        documentNumber: 'Número de documento',
        'asset.documentNumber': 'Número de documento',
        value: 'Valor',
        'asset.value': 'Valor',
        confirmDelete: '¿Está seguro que desea eliminar esta garantía?',
        observation: 'Observación',
        created_at: 'Fecha de creación',
        legalOpposition: '¿Oposición legal?',
        'asset.legalOpposition': '¿Oposición legal?',
        'name': 'Nombre',
        'guarantee_type': 'Tipo de Garantía',
        'aforo_percentage': 'Porcentaje de Aforo'
      },
      titles: {
        'GuaranteeType': 'Tipos de Garantía'
      },
    },
    'information-bank': {
      name: 'Información bancaria |||| Informaciones bancarias',
      fields: {
        name: 'Información bancaria',
        productType: 'Tipo de producto',
        bank: 'Banco',
        number: 'Número de cuenta',
      },
    },
    rating: {
      name: 'Rating |||| Ratings',

      fields: {
        rating: 'Rating',
        ratingScore: 'Score de crédito',
        cat: 'CAT (%)',
        income: 'Estimación de ingresos',
        quota: 'Cuota',
        otherQuota: 'Otras cuotas',
        guaranteeValue: 'Monto en garantía',
        contractValue: 'Valor del contrato',
        amount: 'Monto solicitado',
        term: 'Plazo (meses)',
        interest: 'Interés (%)',
        extraPayments: 'Otros pagos',
      },
    },
    'segment-roles': {
      fields: {
        'code': 'Código',
        'defaultRole': 'Rol por default',
        'defaultRole.id': 'Rol por default',
        'description': 'Descripción',
        'id': 'Id',
      },
    },
    'roles-catalog': {
      titles: {
        'person': 'Clientes personales',
        'legal-people': 'Clientes Morales',
        'blacklist': 'Blacklist',
        'loan-application': 'Solicitudes',
        'contact-history': 'Notas',
        'guarantee': 'Garantías',
        'document': 'Documentos',
        'rating': 'Rating',
        'amortization-table': 'Tabla de Amortización',
        'loan-references': 'Referencias',
        'franchise': 'Franquicias',
        'assign-franchise': 'Asignar Gestor Franquicias',
        'employee': 'Empleados',
        'state-rule': 'Reglas de estado',
        'user': 'Usuarios',
        'auth': 'Authenticación',
        'maintenance': 'Maintenimiento',
        'application-reason': 'Razones de las solicitudes',
        'application-source': 'Fuentes de las solicitudes',
        'application-type': 'Tipos de solicitudes',
        'application-type-settings': 'Configuración de Tipos de solicitudes',
        'client-source': 'Fuentes de clientes',
        'evaluation-unit': 'Unidades de Evaluación',
        'notary': 'Notarías',
        'loan-application-state': 'Estados de las solicitudes',
        'substates': 'Subestados',
        'bank': 'Bancos',
        'department': 'Departamentos',
        'position': 'Posiciones',
        'address-type': 'Tipos de direcciones',
        'promotion': 'Promociones',
        'segment-role': 'Rol - Segmento',
        'role-states': 'Rol - Estado',
        'contact-history-type': 'Tipos de notas',
        'roles-catalog': 'Roles y permisos',
        'selectAll': 'Seleccionar Todos',
        'reference': 'Referencias',
        'related': 'Relacionados',
        'income': 'Ingresos',
        'asset': 'Bienes',
        'client': 'Clientes',
      },
      fields: {
        'find': 'Consultar',
        'findall': 'Consultar Todo',
        'create': 'Crear',
        'update': 'Editar',
        'delete': 'Eliminar',
        'assignagent': 'Asignar Agente',
        'unassignagent': 'Desasignar Agente',
        'requestciec': 'Solicitar CIEC',
        'statechange': 'Cambiar Estado',
        'assignsubstate': 'Asignar Subestado',
        'assignreferrer': 'Asignar Referidor',
        'calculate': 'Calcular',
        'save': 'Guardar',
        'getloansimulation': 'Calcular',
        'generateloanprojectionpdf': 'Descargar',
        'veredicto': 'Veredicto',
        'assign': 'Asignar',
        'unassign': 'Desasignar',
        'forgotpassword': 'Reiniciar contraseña',
      },
    },
    'credit-types': {
      titles: {
        'CreditType': 'Tipos de Crédito'
      },
      fields: {
        'name': 'Tipo de Crédito',
        'active': 'Activo',
        'personType': 'Tipo de persona',
      }
    },
    'guarantee-types': {
      titles: {
        'GuaranteeType': 'Tipos de Garantía'
      },
      fields: {
        'name': 'Tipo de Garantía',
        'active': 'Activo',
      }
    },
    'validators': {
      'validation': {
        'invalid-format': 'Formato inválido'
      }
    }
  },
};
export default esTranslations;
