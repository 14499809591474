import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { withStyles } from '@material-ui/core';

const styles = {
  button: {
    marginTop: '1em',
  },
};

const searchIdUserFromRecord = (record: any) => {
  if (!record) return '';
  if (record.person) return `person=${record.person.id}`;
  return `legal-person=${record.legalPerson.id}`;
};

const AddNewButton = withStyles(styles)(
  ({ classes, record, label, path }: any) => {
    return (
      <Button
        className={classes.button}
        component={Link}
        to={{
          pathname: `/${path}/create`,
          search: `?${searchIdUserFromRecord(record)}&loanApplication=${
            record.id
          }`,
        }}
        label={label}
      >
        <AddCircleOutlineIcon />
      </Button>
    );
  }
);

export const ReferenceContact = ({
  loanApplicationId,
  userName,
  userRole,
  filterClient,
  ...props
}) => {
  return (
    <>
      <AddNewButton
        label='Agregar Referencia'
        path='loan-references'
        {...props}
      />
    </>
  );
};
