import React, { useState } from 'react';
import {
  Button,
  useMutation,
  useNotify,
  SelectInput,
  required,
  SimpleForm,
  useRedirect,
} from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import Cookies from '../../Utils/cookies';
import { makeStyles } from '@material-ui/styles';
import { changeMade, errors, thereWasProblem } from '../../Utils/messages';
import { Theme } from '../../Theme';
import { ErrorCode } from '../../Utils/enums';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    paddingInline: theme.spacing(0.5),
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
  },
  content: { '& > *': { padding: '0 !important' } },
  buttonSpacing: {
    margin: '0rem 0rem 1rem 0rem;',
  },
}));

const StateChangeButton = ({ record }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();
  const [state, setState] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [futureStates, setFutureStates] = useState([]);

  const getFutureStatesByActualStateId = (actualStateId) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/permitted-states?actual_eq=${actualStateId}`,
      {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${Cookies.getCookie('token')}`,
        },
      }
    )
      .then(async (res) => {
        const permittedStates = await res.json();
        if (permittedStates.statusCode === 403) {
          window.location.href = `/#/403`;
          return;
        }

        setFutureStates([]);
        permittedStates.map((permittedState) =>
          setFutureStates((state) => [...state, permittedState.future])
        );
      })
      .catch(() => {
        notify('Hubo un error.', 'warning');
      });
  };

  const handleOpen = () => {
    setOpen(true);
    getFutureStatesByActualStateId(record.loanApplicationState.id);
  };

  const handleClose = () => {
    setState(null);
    setOpen(false);
  };

  const [handleSubmit, { loading }] = useMutation(
    {
      type: 'update',
      resource: 'loan-applications/state-change',
      payload: {
        id: record?.id,
        data: {
          ...record,
          loanApplicationState: state,
        },
      },
    },
    {
      mutationMode: 'pessimistic',
      onSuccess: () => {
        handleClose();
        redirect('/loan-applications');
        notify(changeMade);
      },
      onFailure: (error) => {
        const code = error.body.data.errorCode;
        if (code === ErrorCode.MissingDocumentsErrorCode) {
          const document = error.body.data.errorMessage;
          notify(
            `${thereWasProblem}: ${errors[code]} Agregar ${document}`,
            'warning'
          );
        } else {
          notify(
            `${thereWasProblem}: ${
              errors[code] ||
              'Hubo un error con su solicitud, inténtelo mas tarde...'
            }`,
            'warning'
          );
        }
      },
    }
  );

  return (
    <div className={classes.root}>
      <Button
        className={classes.buttonSpacing}
        endIcon={<SwapHorizIcon />}
        variant='contained'
        label='Cambiar de estado'
        onClick={handleOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        maxWidth='xs'
        fullWidth
      >
        <MuiDialogTitle id='form-dialog-title' disableTypography>
          <Typography variant='h4'>Estados</Typography>
        </MuiDialogTitle>
        <DialogContent>
          <SimpleForm toolbar={<></>} className={classes.content}>
            <SelectInput
              onChange={(e: any) => setState(e.target.value)}
              source='state'
              label='resources.state-rules.fields.state'
              validate={[required()]}
              choices={futureStates}
              fullWidth
              variant='outlined'
              optionText='name'
            />
          </SimpleForm>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            label='Cancelar'
            onClick={() => handleClose()}
            color='primary'
          />
          <Button
            label='Cambiar'
            onClick={handleSubmit}
            color='primary'
            disabled={loading || !state}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StateChangeButton;
