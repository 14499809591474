import BusinessIcon from '@material-ui/icons/Business';
import FranchiseCreate from './FranchiseCreate';
import FranchiseEdit from './FranchiseEdit';
import FranchiseList from './FranchiseList';
import FranchiseShow from './FranchiseShow';

export const Franchises: object = {
  edit: FranchiseEdit,
  list: FranchiseList,
  create: FranchiseCreate,
  show: FranchiseShow,
  icon: BusinessIcon,
};

export default Franchises;
