import StateRuleList from './StateRuleList';
import StateRuleCreate from './StateRuleCreate';
import GavelIcon from '@material-ui/icons/Gavel';
import StateRuleEdit from './StateRuleEdit';
import StateRuleShow from './StateRuleShow';

export const StateRules: object = {
  list: StateRuleList,
  create: StateRuleCreate,
  edit: StateRuleEdit,
  icon: GavelIcon,
  show: StateRuleShow,
};

export default StateRules;
