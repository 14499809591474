import React, { useState } from 'react';
import { useMutation, useNotify, useRefresh, Button } from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';

import { changeMade, thereWasProblem } from '../../Utils/messages';
import { Theme } from '../../Theme';

interface DeleteI {
  record?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: {
    padding: theme.spacing(1, 3),
  },
}));
const DocumentDeleteButton = ({ record }: DeleteI) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [handleDelete, { loading }] = useMutation(
    {
      type: 'create',
      resource: `documents/${record?.archived ? 'unarchive' : 'archive'}`,
      payload: { id: record?.id, data: { id: record?.id } },
    },
    {
      onSuccess: ({ data }) => {
        notify(changeMade, 'info', {}, true);
        refresh();
      },
      onFailure: (error) => {
        notify(
          `${thereWasProblem}: ${error.body?.data?.errors || 'Fallo general'}`,
          'warning'
        );
        refresh();
      },
    }
  );

  return (
    <div>
      <Tooltip title={record?.archived ? 'Desarchivar' : 'Archivar'}>
        <IconButton size='small' color='primary' onClick={handleClickOpen}>
          {record?.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
      >
        <MuiDialogTitle id='form-dialog-title' disableTypography>
          <Typography variant='h4'>
            {record?.archived ? 'Desarchivar' : 'Archivar'}
          </Typography>
        </MuiDialogTitle>
        <DialogContent>
          <Typography variant='h6'>
            ¿Estás seguro de que deseas{' '}
            {record?.archived ? 'desarchivar' : 'archivar'} este documento?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            label='CANCELAR'
            onClick={() => handleClose()}
            color='primary'
          />
          <Button
            label='ACEPTAR'
            onClick={handleDelete}
            color='primary'
            disabled={loading}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DocumentDeleteButton;
