import React from 'react';
import { Divider, Grid, List, Paper, Typography } from '@material-ui/core';

import {
  addressTypes,
  banks,
  departments,
  positions,
  promotions,
  segmentRole,
  roleStates,
  contactHistoryTypes,
  usersPermissionsRoles,
} from '../../../Views';
import ListItemLink from '../ListItemLink';
import { usePermissions } from 'ra-core';

const Others = ({ classes, iconColor }) => {
  const { permissions } = usePermissions();
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography gutterBottom variant='h4' component='h2'>
          Otros
        </Typography>
        <Divider />
        <List className={classes.list} aria-labelledby='conjuration subtitle'>
          { permissions?.['bank']?.find.enabled && 
            <ListItemLink
              icon={<banks.icon />}
              primary='Bancos'
              to='/banks'
              color={iconColor}
            />
          }
          { permissions?.['department']?.find.enabled && 
            <ListItemLink
              icon={<departments.icon />}
              primary='Departamentos'
              to='/departments'
              color={iconColor}
            />
          }
          { permissions?.['position']?.find.enabled && 
            <ListItemLink
              icon={<positions.icon />}
              primary='Posiciones'
              to='/positions'
              color={iconColor}
            />
          }
          { permissions?.['address-type']?.find.enabled && 
            <ListItemLink
              icon={<addressTypes.icon />}
              primary='Tipos de Direcciones'
              to='/address-types'
              color={iconColor}
            />
          }
          { permissions?.['promotion']?.find.enabled && 
            <ListItemLink
              icon={<promotions.icon />}
              primary='Promociones'
              to='/promotions'
              color={iconColor}
            />
          }
          { permissions?.['segment-role']?.find.enabled && 
            <ListItemLink
              icon={<segmentRole.icon />}
              primary='Rol - Segmentos'
              to='/segment-roles'
              color={iconColor}
            />
          }
          { permissions?.['role-states']?.find.enabled && 
            <ListItemLink
            icon={<roleStates.icon />}
            primary='Rol - Estados'
            to='/role-states'
            color={iconColor}
          />
          }
          { permissions?.['contact-history-type']?.find.enabled && 
            <ListItemLink
            icon={<contactHistoryTypes.icon />}
            primary='Tipos de Nota'
            to='/contact-history-types'
            color={iconColor}
          />
          }
          { permissions?.['roles-catalog']?.find.enabled && 
            <ListItemLink
            icon={<usersPermissionsRoles.icon />}
            primary='Roles y Permisos'
            to='/roles-catalog'
            color={iconColor}
          />
          }
        </List>
      </Paper>
    </Grid>
  );
};

export default Others;
