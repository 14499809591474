import React, { useState } from 'react';
import { Button, useMutation, useNotify } from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';

import { useRefresh } from 'ra-core';
import { makeStyles } from '@material-ui/styles';
import { thereWasProblem } from '../../Utils/messages';
import { Theme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingInline: theme.spacing(0.5),
  },
  buttonSpacing: {
    margin: '0rem 0rem 1rem 0rem;',
  },
}));

const AgentUnassignButton = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [handleSubmit, { loading }] = useMutation(
    {
      type: 'create',
      resource: 'legal-people/unassign-agent',
      payload: {
        data: { legalPeople: props.selectedIds },
      },
    },
    {
      onSuccess: () => {
        notify('Gestor desasignado correctamente');
        refresh();
      },
      onFailure: (error) => {
        notify(
          `${thereWasProblem}: ${
            (error.body.data ? error.body.data.error : error.body.error) ||
            'Fallo general'
          }`,
          'warning'
        );
      },
    }
  );

  return (
    <div className={classes.root}>
      <Button
        className={classes.buttonSpacing}
        endIcon={<AssignmentReturnIcon />}
        variant='contained'
        disabled={loading}
        label='Desasignar gestor'
        onClick={handleOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        maxWidth='xs'
        fullWidth
      >
        <MuiDialogTitle id='form-dialog-title' disableTypography>
          <Typography variant='h4'>Desasignar gestores</Typography>
        </MuiDialogTitle>
        <DialogContent>
          <Typography variant='h6'>Seguro que deseas continuar?</Typography>
        </DialogContent>
        <DialogActions>
          <Button label='Cancelar' onClick={handleClose} color='primary' />
          <Button
            label='Confirmar'
            onClick={handleSubmit}
            color='primary'
            disabled={loading}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AgentUnassignButton;
