import React, { useState } from 'react';
import {
  Button,
  useMutation,
  useNotify,
  TextInput,
  FormWithRedirect,
  Toolbar,
  NumberInput,
  maxValue,
  useRefresh,
} from 'react-admin';
import { Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DescriptionIcon from '@material-ui/icons/Description';

import { errors, thereWasProblem } from '../../Utils/messages';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../Theme';
import FormatNumberInput from '../../Components/FormatNumberInput';
import FormTitle from '../../Components/FormTitle';
import { FormShow } from '../../styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingInline: theme.spacing(0.5),
  },
  buttonSpacing: {
    margin: '0rem 0rem 1rem 0rem;',
  },
}));

const VeredictButton = (props) => {
  const classes = useStyles();
  const titleCLass = FormShow();
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const [handleSubmit, { loading }] = useMutation(
    { type: 'create', resource: 'documents/veredicto' },
    {
      onSuccess: () => {
        handleClose();
        notify('Documento creado');
      },
      onFailure: (error) => {
        const code = error.body.data.errorCode;
        notify(
          `${thereWasProblem}: ${
            errors[code] ||
            'Hubo un error con su solicitud, inténtelo mas tarde...'
          }`,
          'warning'
        );
      },
    }
  );

  return (
    <div className={classes.root}>
      <Button
        className={classes.buttonSpacing}
        endIcon={<DescriptionIcon />}
        variant='contained'
        label='Documento de veredicto'
        onClick={handleOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='lg'
        fullWidth
        aria-labelledby='form-dialog-title'
      >
        <MuiDialogTitle id='form-dialog-title' disableTypography>
          <Typography variant='h3'>Documento de veredicto</Typography>
        </MuiDialogTitle>
        <DialogContent>
          <FormWithRedirect
            record={{ id: props.record?.id, loanApplication: props.record?.id }}
            render={(formProps) => {
              return (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormTitle
                        title='Solicitante'
                        className={titleCLass.width100}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormatNumberInput
                        source='currentBalance'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormatNumberInput
                        source='pastDueBalance'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormatNumberInput
                        source='currentPay'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <NumberInput
                        fullWidth
                        source='score'
                        variant='outlined'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTitle
                        title='Representante legal'
                        className={titleCLass.width100}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormatNumberInput
                        source='legalRepresentative.currentBalance'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormatNumberInput
                        source='legalRepresentative.pastDueBalance'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormatNumberInput
                        source='legalRepresentative.currentPay'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <NumberInput
                        fullWidth
                        source='legalRepresentative.score'
                        variant='outlined'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTitle
                        title='Coacreditado'
                        className={titleCLass.width100}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormatNumberInput
                        source='coacreedor.pastDueBalance'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormatNumberInput
                        source='coacreedor.currentPay'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <NumberInput
                        fullWidth
                        source='coacreedor.score'
                        variant='outlined'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTitle
                        title='Garante'
                        className={titleCLass.width100}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormatNumberInput
                        source='guarantee.pastDueBalance'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormatNumberInput
                        source='guarantee.currentPay'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <NumberInput
                        fullWidth
                        source='guarantee.score'
                        variant='outlined'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTitle
                        title='Probabilidad'
                        className={titleCLass.width100}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormatNumberInput
                        source='probability.amount'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <NumberInput
                        fullWidth
                        source='probability.months'
                        resource='loan-applications'
                        variant='outlined'
                        validate={[
                          maxValue(120, 'La cantidad máxima es 120 meses'),
                        ]}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <NumberInput
                        fullWidth
                        source='probability.interestRate'
                        variant='outlined'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTitle
                        title='Notas de credito'
                        className={titleCLass.width100}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormatNumberInput
                        source='maxCreditCapacity'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <NumberInput
                        fullWidth
                        source='casesWatched'
                        variant='outlined'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormatNumberInput
                        source='openingCommission'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormatNumberInput
                        source='ciecIncomes'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextInput
                        fullWidth
                        multiline
                        variant='outlined'
                        source='pending'
                        resource='loan-applications'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextInput
                        fullWidth
                        multiline
                        variant='outlined'
                        source='comment'
                        resource='loan-applications'
                      />
                    </Grid>
                  </Grid>

                  <Toolbar
                    style={{
                      backgroundColor: 'transparent',
                      justifyContent: 'flex-end',
                      padding: 0,
                    }}
                  >
                    <Button
                      label='Guardar'
                      variant='contained'
                      disabled={formProps.invalid || loading}
                      onClick={() =>
                        handleSubmit({
                          payload: {
                            data: formProps.form.getState().values,
                          },
                        })
                      }
                    />
                  </Toolbar>
                </>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VeredictButton;
