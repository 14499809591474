import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

import ApplicationTypeList from './ApplicationTypeList';

export const ApplicationType: object = {
  list: ApplicationTypeList,
  icon: CreateNewFolderIcon,
};

export default ApplicationType;
