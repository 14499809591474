/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { Button } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import documentProvider from '../../Providers/documentProvider';

const AWS = require('aws-sdk');

type File = {
  ext: string;
  id: number;
  name: string;
  url: string;
};

type AwsFileData = {
  fileUrl: string;
  fileName: string;
};

const DocumentDownloadButton = ({ related }) => {
  const zip = new JSZip();
  const s3 = new AWS.S3();

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const notify = useNotify();

  const loanApplicationId = related;
  const awsFilesData: AwsFileData[] = [];

  AWS.config.update({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });

  const handleDownload = () => {
    if (!awsFilesData.length) {
      files.forEach((file) => {
        awsFilesData.push({
          fileUrl: file.url.split('/')[3],
          fileName: file.url.split('/')[3],
        });
      });
    }

    S3Connection().catch((e) => console.error(e));
  };

  const S3Connection = async () => {
    try {
      for (const fileData of awsFilesData) {
        const params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: fileData.fileUrl,
        };
        const fileFromAWS = await s3.getObject(params).promise();
        const fileBlob = new Blob([fileFromAWS.Body], {
          type: 'image/png;application/pdf;charset=utf8-8;',
        });
        zip.file(fileData.fileName, fileBlob, { base64: true });
      }
      const generatedZip = await zip.generateAsync({ type: 'blob' });
      saveAs(generatedZip, `${related}.zip`);
    } catch (error) {
      console.error(error);
      notify('Hubo un error', 'error');
    }
  };

  useEffect(() => {
    documentProvider.downloadDocumentZip(loanApplicationId).then((res) => {
      if (res.statusCode >= 400) {
        notify('Hubo un error con la descarga de documentos.', 'warning');
      } else {
        setFiles(res);
      }
      setLoading(false);
    });
  }, []);

  return (
    <Button
      variant='contained'
      color='secondary'
      onClick={handleDownload}
      startIcon={<CloudDownload />}
      disabled={loading || !files.length}
    >
      Descargar archivos
    </Button>
  );
};

export default DocumentDownloadButton;
