import React, { useState } from 'react';
import { useDelete, useNotify, useRefresh, Button } from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';

interface DeleteI {
  note?: any;
}

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: theme.spacing(1, 3),
  },
}));
const NoteDeleteButton = ({ note }: DeleteI) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [handleDelete] = useDelete('contact-histories', note.id, note, {
    onSuccess: () => {
      handleClose();
      notify('Nota Eliminada', undefined, undefined, false);
      refresh();
    },
  });

  return (
    <div>
      <Tooltip title='Eliminar documento'>
        <IconButton size='small' color='primary' onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
      >
        <MuiDialogTitle id='form-dialog-title' disableTypography>
          <Typography variant='h4'>Eliminar</Typography>
        </MuiDialogTitle>
        <DialogContent>
          <Typography variant='h6'>
            ¿Estás seguro de que deseas eliminar esta Nota?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            label='CANCELAR'
            onClick={() => handleClose()}
            color='primary'
          />
          <Button label='ACEPTAR' onClick={handleDelete} color='primary' />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NoteDeleteButton;
