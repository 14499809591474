import React, { FC } from 'react';
import {
  useTranslate,
  DateField,
  useRecordContext,
  ReferenceManyField,
} from 'react-admin';
import { Card, CardContent, Typography, Box } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { makeStyles } from '@material-ui/styles';
import { HistoryTimeline } from './HistoryTimeline';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '75vh',
    padding: '1px 5px',
    marginBottom: 0,
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  card: {
    padding: '15px',
    maxHeight: '40%',
  },
  timeline: {
    overflowY: 'scroll',
    maxHeight: '65%',
    height: '65%',
  },
  spacing: {
    marginBottom: '25px',
  },
  font: {
    fontSize: '14px',
  },
}));

interface TimelineProps {
  segment: string;
}

export const Timeline: FC<TimelineProps> = ({ segment }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const record = useRecordContext();

  return (
    <>
      <Box m='0 0 1em 1em' width={250} minWidth={250} className={classes.root}>
        <Card className={classes.spacing}>
          <CardContent className={classes.card}>
            <Typography variant='h6' gutterBottom className={classes.title}>
              {translate('Historial')}
            </Typography>
            <Box display='flex' flexDirection='column'>
              <Box flexGrow={3}>
                <Box display='flex' mb='1em'>
                  <Box mr='5px'>
                    <AccessTimeIcon fontSize='small' color='disabled' />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography className={classes.font}>
                      {translate('Fecha de creación')}
                    </Typography>
                    <DateField
                      record={record}
                      source='created_at'
                      options={{
                        timeZone:
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                      }}
                      showTime
                    />
                  </Box>
                </Box>
              </Box>
              <Box flexGrow={1}>
                <Box display='flex'>
                  <Box mr='5px'>
                    <AccessTimeIcon fontSize='small' color='disabled' />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography className={classes.font}>
                      {translate('Última actualización')}
                    </Typography>
                    <DateField
                      record={record}
                      source='updated_at'
                      options={{
                        timeZone:
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                      }}
                      showTime
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Box className={classes.timeline}>
          {record ? (
            <ReferenceManyField
              reference='timelines'
              target='related'
              source='user'
              addLabel={false}
              filter={{
                related: record?.id,
                segment: segment,
              }}
            >
              <HistoryTimeline />
            </ReferenceManyField>
          ) : null}
        </Box>
      </Box>
    </>
  );
};
