import React from 'react';
import {
  ArrayInput,
  ReferenceInput,
  required,
  FormDataConsumer,
  AutocompleteInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';

const isRequired = [required()];

const Conditions = ({
  className = '',
  source = 'conditions',
  columns = 4,
  ...rest
}) => {
  return (
    <ArrayInput source={source} validation={isRequired} label='' {...rest}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return (
              <Grid container spacing={1}>
                <Grid item xs={columns}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'parameter.id' : 'parameter'
                    )}
                    reference='parameters'
                    validate={isRequired}
                    variant='outlined'
                    fullWidth
                    label='Parámetro'
                  >
                    <AutocompleteInput optionText='name' />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={columns}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'operator.id' : 'operator'
                    )}
                    reference='operators'
                    validate={isRequired}
                    variant='outlined'
                    fullWidth
                    label='Operador'
                  >
                    <AutocompleteInput optionText='name' />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={columns}>
                  <TextInput
                    source={getSource('value')}
                    validate={isRequired}
                    variant='outlined'
                    fullWidth
                    label='Valor'
                  />
                </Grid>
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default Conditions;
