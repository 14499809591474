import React from 'react';
import { Route } from 'react-router-dom';
import { Maintenances } from './Views/Maintenances';
import { ForbiddenPage } from './403';

const routes = [
  <Route exact path='/403' component={ForbiddenPage} />,
  <Route exact path='/maintenances' component={Maintenances} />,
];

export default routes;
