import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  ReferenceInput,
  required,
  FormDataConsumer,
  SimpleFormIterator,
  SelectInput,
  TextInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-final-form';
import {
  PersonType,
  RepresentativeType,
  RepresentativeTypeNames,
} from '../Utils/enums';

const options = [
  {
    id: RepresentativeType.LegalRepresentative,
    name: RepresentativeTypeNames.LegalRepresentative,
  },
  {
    id: RepresentativeType.Proxy,
    name: RepresentativeTypeNames.Proxy,
  },
];

const optionsLegalPeople = [
  {
    id: RepresentativeType.LegalRepresentative,
    name: RepresentativeTypeNames.LegalRepresentative,
  },
  {
    id: RepresentativeType.Proxy,
    name: RepresentativeTypeNames.Proxy,
  },
  {
    id: RepresentativeType.Shareholder,
    name: RepresentativeTypeNames.Shareholder,
  },
];

const Representatives = (props) => {
  const form = useForm();
  const { record } = props;

  return (
    <ArrayInput source='representatives' label='' {...record}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <SelectInput
                    source={getSource('personType')}
                    fullWidth
                    variant='outlined'
                    label='resources.representatives.fields.personType'
                    validate={[required()]}
                    onChange={() => {
                      form.change(getSource('physicalPerson'), null);
                      form.change(getSource('legalPerson'), null);
                    }}
                    initialValue={
                      scopedFormData?.legalPerson
                        ? PersonType.LegalPerson
                        : PersonType.PhysicalPerson
                    }
                    choices={[
                      {
                        id: PersonType.PhysicalPerson,
                        name: 'Persona física',
                      },
                      { id: PersonType.LegalPerson, name: 'Persona moral' },
                    ]}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectInput
                    source={getSource('representativeType')}
                    fullWidth
                    validate={[required()]}
                    label='resources.representatives.fields.representativeType'
                    variant='outlined'
                    choices={
                      props.basePath === '/people'
                        ? options
                        : optionsLegalPeople
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  {scopedFormData?.personType?.id === PersonType.LegalPerson ||
                  scopedFormData?.personType === PersonType.LegalPerson ? (
                    <ReferenceInput
                      source={getSource(
                        record?.id ? 'legalPerson.id' : 'legalPerson'
                      )}
                      reference='legal-people'
                      fullWidth
                      label='resources.representatives.fields.legalPerson'
                      variant='outlined'
                      filter={
                        props.resource === 'legal-people' && {
                          id_ne: record.id,
                        }
                      }
                    >
                      <AutocompleteInput
                        optionText={(record) =>
                          record &&
                          `${record.documentNumber} - ${record.businessName}`
                        }
                      />
                    </ReferenceInput>
                  ) : (
                    <ReferenceInput
                      source={getSource(
                        record?.id ? 'physicalPerson.id' : 'physicalPerson'
                      )}
                      reference='people'
                      fullWidth
                      label='resources.representatives.fields.physicalPerson'
                      variant='outlined'
                      filter={
                        props.resource === 'people' && {
                          id_ne: record.id,
                        }
                      }
                    >
                      <AutocompleteInput
                        optionText={(record) =>
                          record &&
                          `${record.documentNumber} - ${record.firstName} ${record.fatherLastName}`
                        }
                      />
                    </ReferenceInput>
                  )}
                </Grid>
                {scopedFormData?.representativeType ===
                  RepresentativeType.Shareholder && (
                  <Grid item xs={12}>
                    <TextInput
                      source={getSource('observation')}
                      label='resources.representatives.fields.observation'
                      variant='outlined'
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default Representatives;
