import React from 'react';
import useSelectDataProvider from '../../hooks/useSelectDataProvider';
import DraftTextInput from '../../Components/DraftTextInput/DraftTextInput';

const DraftTextInputNotes = (props) => {
  const suggestionFilterCondition = (suggestion, value) =>
    suggestion.name?.toLowerCase() === value?.toLowerCase() ||
    suggestion.name?.toLowerCase().includes(value?.toLowerCase());

  const suggestionFilter = (suggestion, value) =>
    suggestionFilterCondition(suggestion, value) ? suggestion : null;

  const getName = (employee) => {
    const { person, code } = employee;
    return `${code} ${person?.firstName} ${person?.fatherLastName || ''} ${
      person?.motherLastName || ''
    }`;
  };

  const employeeSuggestions = useSelectDataProvider(
    'notifications/suggested/employees'
  )?.map((s: any) => ({ name: getName(s), employee: s, department: null }));
  const departmentsSuggestions = useSelectDataProvider(
    'notifications/suggested/departments'
  )?.map((s: any) => ({ name: s.name, employee: null, department: s }));

  const suggestionsList = [...employeeSuggestions, ...departmentsSuggestions];

  return (
    <>
      <DraftTextInput
        suggestionsList={suggestionsList}
        suggestionFilter={suggestionFilter}
        {...props}
      />
    </>
  );
};

export default DraftTextInputNotes;
