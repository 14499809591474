import React from 'react';
import type { FC } from 'react';
import { Container, makeStyles } from '@material-ui/core';

import type { Theme } from '../../../theme';
import Page from '../../../Components/Page';
import Header from './Header';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
}));

const ShowHeader: FC = () => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title='DataBridge'>
      <Container maxWidth='lg'>
        <Header />
      </Container>
    </Page>
  );
};

export default ShowHeader;
