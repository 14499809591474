import React, { useState } from 'react';
import {
  Button,
  Datagrid,
  FunctionField,
  DateField,
  ChipField,
  useMutation,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import { errors, thereWasProblem } from '../../../Utils/messages';
import { convertTheValueToBytes } from '../../../Utils/convertTheValueToBytes';
import CustomList from '../../../Components/CustomList';
import DocumentIconNameField from '../../Documents/DocumentIconNameField';

const ConfirmValidationButton = ({ related, selectedIds, resource }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(resource);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    unselectAll();
  };

  const [handleSubmit, { loading }] = useMutation(
    {
      type: 'create',
      resource: 'watermark-documents',
      payload: {
        data: { selectedIds, related },
      },
    },
    {
      onSuccess: () => {
        handleClose();
        notify('Documentos cotejados correctamente');
        refresh();
      },
      onFailure: (error) => {
        handleClose();
        const code = error?.body?.data?.errorCode;
        notify(
          `${thereWasProblem}: ${
            errors[code] || 'Hubo un error, inténtelo mas tarde...'
          }`,
          'warning'
        );
      },
    }
  );

  return (
    <div>
      <Button
        variant='contained'
        color='secondary'
        label='Cotejado contra original'
        onClick={handleOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id='form-dialog-title' disableTypography>
          <Typography variant='h4'>Continuar</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant='h6'>
            ¿Estás seguro que deseas continuar?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button label='Cancelar' onClick={handleClose} color='primary' />
          <Button
            label='Confirmar'
            onClick={handleSubmit}
            color='primary'
            disabled={loading}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const RelatedDocumentsList = (props) => (
  <CustomList
    bulkActionButtons={<ConfirmValidationButton {...props} />}
    hasCreate={false}
    hasEdit={false}
    actions={<></>}
    filter={{ related: props.related }}
    basePath='/related-documents'
    resource='related-documents'
    {...props}
  >
    <Datagrid>
      <DocumentIconNameField
        source='documents[0].name'
        emptyText='-'
        resource='related-documents'
        sortable={false}
      />
      <ChipField
        source='documentType.name'
        resource='related-documents'
        emptyText='-'
      />
      <FunctionField
        render={(record) => convertTheValueToBytes(record?.documents[0]?.size)}
        source='size'
        resource='related-documents'
        emptyText='-'
        sortable={false}
      />
      <DateField
        source='created_at'
        showTime={false}
        emptyText='-'
        locales='es-MX'
        resource='related-documents'
      />
    </Datagrid>
  </CustomList>
);

export default RelatedDocumentsList;
