import React from 'react';
import { Button, useMutation, useNotify } from 'react-admin';
import PasswordIcon from '@material-ui/icons/Lock';

import { makeStyles } from '@material-ui/styles';
import { errors, thereWasProblem } from '../../Utils/messages';
import { Theme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) => ({
  buttonSpacing: {
    margin: '0rem;',
  },
}));

const CIECRequestButton = ({ legalPerson }) => {
  const notify = useNotify();
  const classes = useStyles();

  const [handleSubmit, { loading }] = useMutation(
    {
      type: 'create',
      resource: 'legal-people/ciec',
      payload: {
        data: { legalPerson },
      },
    },
    {
      onSuccess: () => {
        notify('Se le ha enviado un correo al cliente solicitando su CIEC.');
      },
      onFailure: (error) => {
        const msg = error.body?.data
          ? error.body?.data.errorCode
          : error.body?.message;

        notify(
          `${thereWasProblem}: ${
            errors[msg] ||
            msg ||
            'Error con su solicitud, inténtelo mas tarde..'
          }`,
          'warning'
        );
      },
    }
  );

  return (
    <Button
      className={classes.buttonSpacing}
      startIcon={<PasswordIcon />}
      disabled={loading}
      label='Solicitar CIEC'
      onClick={handleSubmit}
    />
  );
};

export default CIECRequestButton;
