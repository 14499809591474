import { Grid } from '@material-ui/core';
import React from 'react';
import {
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  useTranslate,
} from 'react-admin';

import Addresses from '../../Components/Addresses';
import Contacts from '../../Components/Contacts';
import FormTitle from '../../Components/FormTitle';
import { FormShow } from '../../styles';
import { formatInputNumber, parseInputNumber } from '../../Utils/functions';

const isRequired = [required()];

const IncomeForm = ({ className, formClassName, ...props }: any) => {
  const translate = useTranslate();
  const classes = FormShow();

  return (
    <Grid container spacing={2} classes={{ root: classes.root }}>
      <Grid item xs={4}>
        <ReferenceInput
          source='incomeType'
          reference='income-types'
          variant='outlined'
          fullWidth
          label={translate('resources.incomes.fields.incomeType')}
          validate={isRequired}
        >
          <SelectInput optionText='name' />
        </ReferenceInput>
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source='companyName'
          variant='outlined'
          fullWidth
          label={translate('resources.incomes.fields.companyName')}
          validate={isRequired}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source='position'
          variant='outlined'
          fullWidth
          label={translate('resources.incomes.fields.position')}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source='amount'
          validate={isRequired}
          defaultValue='0'
          format={(v) => formatInputNumber(v)}
          parse={(v) => parseInputNumber(v)}
          variant='outlined'
          fullWidth
          label={translate('resources.incomes.fields.incomes')}
        />
      </Grid>
      <Grid item xs={4}>
        <DateInput
          source='effectiveDate'
          variant='outlined'
          fullWidth
          label={translate('resources.incomes.fields.effectiveDate')}
          inputProps={{
            max: new Date().toISOString().split('T')[0],
          }}
          validate={isRequired}
        />
      </Grid>
      <Grid item xs={4}>
        <DateInput
          source='expirationDate'
          variant='outlined'
          fullWidth
          label={translate('resources.incomes.fields.expirationDate')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTitle title='Contactos' />
        <Contacts record={props.record} columns={6} />
      </Grid>
      <Grid item xs={12}>
        <FormTitle title='Direcciones' />
        <Addresses record={props.record} />
      </Grid>
      <Grid item xs={12}>
        <FormTitle title='Supervisor' />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          source='supervisor'
          variant='outlined'
          fullWidth
          label={translate('resources.incomes.fields.supervisor')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTitle title='Contactos del supervisor' />
        <Contacts
          record={props.record}
          source='supervisorContacts'
          columns={6}
        />
      </Grid>
    </Grid>
  );
};

export default IncomeForm;
