import React from 'react';
import Avatar from '@material-ui/core/Avatar';

interface AvatarInterface {
  record: any;
  size: number;
  className: any;
}

export const AvatarField: React.FC<AvatarInterface> = (props) => {
  const { record, size, className } = props;
  return record ? (
    <Avatar
      src={record.avatar}
      style={{ width: size, height: size }}
      className={className}
    />
  ) : null;
};

export default AvatarField;
