import { Edit, ListButton, TopToolbar } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import ContactTypeForm from './ContactTypeForm';

const ContactTypeActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const ContactTypeEdit = (props: any) => {
  return (
    <Edit actions={<ContactTypeActions {...props} />} {...props}>
      <ContactTypeForm {...props} />
    </Edit>
  );
};

export default ContactTypeEdit;
