import { Layout } from 'react-admin';

import TopBar from './TopBar';
import NavBar from './NavBar';

const AdminLayout = (props) => (
  <Layout {...props} appBar={TopBar} menu={NavBar} />
);

export default AdminLayout;
