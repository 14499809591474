import React from 'react';
import { makeStyles, Typography, Card, Grid } from '@material-ui/core';
import EmptyIcon from '@material-ui/icons/Inbox';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '2rem',
    marginTop: '1rem',
  },
  title: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    color: theme.palette.text.secondary,
    padding: '0.1rem 0',
    marginTop: '0.5rem',
    fontSize: '0.85rem',
    textAlign: 'center',
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: '0.8rem',
    textAlign: 'center',
  },
}));

const TablePlaceholder = ({ title, description = '', ...props }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid justifyContent='center' container xs={12}>
        {props.icon || <EmptyIcon />}
      </Grid>
      <Typography
        gutterBottom
        className={classes.title}
        component='div'
        variant='h5'
      >
        {title}
      </Typography>
      <Typography
        gutterBottom
        className={classes.description}
        component='div'
        variant='h6'
      >
        {description ||
          `No hay ${title.toLowerCase()} para mostrar, cuando se agreguen saldrán aquí.`}
      </Typography>
    </Card>
  );
};

export default TablePlaceholder;
