import React from "react";
import { Drawer, makeStyles } from "@material-ui/core";
import { Fragment, useCallback } from "react";
import { useHistory, Route } from 'react-router-dom'
import CustomList from "../../Components/CustomList";
import { Datagrid, Filter, TextField, TextInput } from "react-admin";
import ListActions from "../../Components/ListActions";
import TablePlaceholder from "../../Components/TablePlaceholder";
import SegmentRoleCreate from './SegmentRoleCreate'
import SegmentRoleEdit from './SegmentRoleEdit'

const ENTITY_NAME = 'segment-role';

const useStyles = makeStyles(() => ({
  drawerContent: { width: '50vw', padding: '0 1rem' },
}));

const Filters = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput label='ra.action.search' source='name' alwaysOn />
  </Filter>
);


const SegmentRoleList = ({ permissions, hasCreate, ...props }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/segment-roles');
  }, [history]);

  return (
    <Fragment>
      <CustomList
        { ...props }
        filters={<Filters />}
        actions={
          <ListActions
            // hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
            hasCreate={false}
            isMaintenance
            entity={ ENTITY_NAME }
            permissions={ permissions }
            { ...props }
          />
        }
      >
        <Datagrid
          rowClick='edit'
          empty={<TablePlaceholder title='Segmento - Rol' />}
        >
          <TextField source='code' label="Código" />
          <TextField source='description' label="Descripción" />
          <TextField source='defaultRole.name' label="Rol" />
        </Datagrid>
      </CustomList>
      <Route path="/segment-roles/create">
        {({ match }) => (
          <Drawer
          open={ !!match }
          anchor='right'
          onClose={handleClose}
          >
            <SegmentRoleCreate
              className={classes.drawerContent}
              onCancel={handleClose}
              {...props}
              entity={ENTITY_NAME}
              hasCreate={false}
            />
          </Drawer>
        )}
      </Route>
      <Route path='/segment-roles/:id'>
        {({match}) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          );

          return (
            <Drawer
            open={isMatch} 
            anchor='right' 
            onClose={handleClose}
            >
              {isMatch ? (
                <SegmentRoleEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                  entity={ENTITY_NAME}
                  hasEdit={permissions?.[ENTITY_NAME]?.update?.enabled}
                />
              ): (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default SegmentRoleList;

