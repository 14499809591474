import React, { Fragment, useCallback } from "react";
import CustomList from "../../Components/CustomList";
import { BooleanField, Datagrid, Filter, FunctionField, TextField, TextInput } from "react-admin";
import ListActions from "../../Components/ListActions";
import TablePlaceholder from "../../Components/TablePlaceholder";
import { Route, useHistory } from 'react-router-dom'
import { Drawer, makeStyles } from "@material-ui/core";
import CreditTypeCreate from "./CreditTypeCreate";
import CreditTypeEdit from "./CreditTypeEdit";
import { personTypes } from "../../Utils/enums";

const ENTITY_NAME = 'credit-types';

const useStyles = makeStyles(() => ({
  drawerContent: { width: '50vw', padding: '0 1rem' },
}));

const Filters = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput label='ra.action.search' source='name' alwaysOn />
  </Filter>
);

const CreditTypeList = ({permissions, ...props}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/credit-types');
  }, [history]);

  return(
    <Fragment>
      <CustomList
        {...props}
        hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
        filters={<Filters />}
        actions={
          <ListActions
            isMaintenance
            entity={ENTITY_NAME}
            permissions={permissions}
            {...props}
            hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
          />
        }
      >
        <Datagrid
          rowClick='edit'
          empty={<TablePlaceholder title='CreditType' />}
        >
          <FunctionField 
            source='personType'
            // render={(record) => ((personTypes.map(p => p.id == record?.personType) as any)?.name)}
            render={(record) => {
              const personType = personTypes.find(p => p.id == record?.personType) as any;
              return personType?.name || '';
            }}
          />
          <TextField source='name' />
          <BooleanField source='active' />
        </Datagrid>
      </CustomList>
      <Route path='/credit-types/create'>
        {({match}) => (
          <Drawer open={!!match} anchor="right" onClose={handleClose}>
            <CreditTypeCreate
              className={classes.drawerContent}
              onCancel={handleClose}
              {...props}
              entity={ENTITY_NAME}
              hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
            />
          </Drawer>
        )}
      </Route>
      <Route path='/credit-types/:id'>
        {({match}) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          );

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose}>
              {isMatch ? (
                <CreditTypeEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                  entity={ENTITY_NAME}
                  hasEdit={permissions?.[ENTITY_NAME]?.update?.enabled}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default CreditTypeList;