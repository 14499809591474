import React from 'react';
import {
  ArrayInput,
  ReferenceInput,
  required,
  FormDataConsumer,
  AutocompleteInput,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { DocumentSegment } from '../Utils/enums';

const OtherDocuments = ({ record, resource, ...rest }) => {
  const translate = useTranslate();

  return (
    <ArrayInput source='otherDocuments' label='' {...rest}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ReferenceInput
                    source={getSource(
                      record?.id ? 'documentType.id' : 'documentType'
                    )}
                    reference='document-types'
                    resource='other-documents'
                    variant='outlined'
                    validate={[required()]}
                    label={translate(
                      'resources.other-documents.fields.documentType'
                    )}
                    fullWidth
                    filter={{
                      segment: 'info',
                      documentSegment:
                        resource === 'people'
                          ? DocumentSegment.Personal.id
                          : DocumentSegment.Business.id,
                    }}
                  >
                    <AutocompleteInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    source={getSource('documentNumber')}
                    validate={[required()]}
                    resource='other-documents'
                    label={translate(
                      'resources.other-documents.fields.documentNumber'
                    )}
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default OtherDocuments;
