import React from "react";
import { ChevronLeft } from "@material-ui/icons";
import { Edit, ListButton, TopToolbar } from "react-admin";
import ContactHistoryTypeForm from "./ContactHistoryTypeForm";


const ContactHistoryTypeActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const ContactHistoryTypeEdit = (props: any) => {
  return (
    <Edit actions={<ContactHistoryTypeActions {...props} />} {...props}>
      <ContactHistoryTypeForm {...props} />
    </Edit>
  );
};

export default ContactHistoryTypeEdit;