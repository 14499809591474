import React, { useState, useRef, useEffect } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  maxLength,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { Grid } from '@material-ui/core';
import { PersonTypeAlias } from '../Utils/enums';
import { findDuplicateAddresses } from '../Utils/PhysicalPerson';

const isRequired = [required()];

const Addresses = ({
  className = '',
  isValidated = false,
  source = 'addresses',
  ...rest
}) => {
  const [isOtherNeighborhood, setIsOtherNeighborhood] = useState(false);
  const translate = useTranslate();
  const form = useForm();
  const typePerson = rest['typePerson'];
  return (
    <ArrayInput
      source={source}
      label=''
      validation={isValidated ? isRequired : false}
      {...rest}
    >
      <SimpleFormIterator>
        <ReferenceInput
          source={rest.record?.id ? 'addressType.id' : 'addressType'}
          reference='address-types'
          variant='outlined'
          validate={isRequired}
          fullWidth
          label={translate('resources.addresses.fields.addressType')}
          onChange={(c) => {
            console.log('on change parent', c);
          }}
        >
          <SelectInput />
        </ReferenceInput>
        <FormDataConsumer>
          {({ scopedFormData, getSource, formData, ...rest }) => {
            const validateAddress = (values: any) => {
              if (typePerson !== PersonTypeAlias.Physical) {
                return undefined;
              }
              const messageForDuplicate =
                findDuplicateAddresses(
                  rest['id'],
                  formData.addresses,
                  scopedFormData
                )[rest['id']]?.message ?? '';
              if (messageForDuplicate) {
                return 'Dirección duplicado';
              }
              return undefined;
            };

            return (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'country.id' : 'country'
                    )}
                    reference='countries'
                    validate={isRequired}
                    perPage={100}
                    sort={{
                      field: 'id',
                      order: 'ASC',
                    }}
                    onChange={() => {
                      form.change(
                        getSource(rest.record?.id ? 'province.id' : 'province'),
                        ''
                      );
                      form.change(
                        getSource(
                          rest.record?.id ? 'municipality.id' : 'municipality'
                        ),
                        ''
                      );
                      form.change(
                        getSource(
                          rest.record?.id ? 'neighborhood.id' : 'neighborhood'
                        ),
                        ''
                      );
                      form.change(
                        getSource(rest.record?.id ? 'city.id' : 'city'),
                        null
                      );
                    }}
                    variant='outlined'
                    fullWidth
                    label={translate('resources.addresses.fields.country')}
                  >
                    <AutocompleteInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'province.id' : 'province'
                    )}
                    disabled={!scopedFormData?.country}
                    reference='provinces'
                    validate={isRequired}
                    variant='outlined'
                    onChange={() => {
                      form.change(
                        getSource(
                          rest.record?.id ? 'municipality.id' : 'municipality'
                        ),
                        ''
                      );
                      form.change(
                        getSource(
                          rest.record?.id ? 'neighborhood.id' : 'neighborhood'
                        ),
                        ''
                      );
                      form.change(
                        getSource(rest.record?.id ? 'city.id' : 'city'),
                        null
                      );
                    }}
                    fullWidth
                    label={translate('resources.addresses.fields.province')}
                    filter={
                      scopedFormData?.country && {
                        country: rest.record?.id
                          ? scopedFormData.country.id
                          : scopedFormData.country,
                      }
                    }
                  >
                    <AutocompleteInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'municipality.id' : 'municipality'
                    )}
                    disabled={!scopedFormData?.province}
                    reference='municipalities'
                    validate={isRequired}
                    variant='outlined'
                    onChange={() => {
                      form.change(
                        getSource(
                          rest.record?.id ? 'neighborhood.id' : 'neighborhood'
                        ),
                        ''
                      );
                      form.change(
                        getSource(rest.record?.id ? 'city.id' : 'city'),
                        null
                      );
                    }}
                    fullWidth
                    label={translate('resources.addresses.fields.municipality')}
                    filter={
                      scopedFormData?.province && {
                        province: rest.record?.id
                          ? scopedFormData.province.id
                          : scopedFormData.province,
                      }
                    }
                  >
                    <AutocompleteInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInput
                    source={getSource(rest.record?.id ? 'city.id' : 'city')}
                    reference='cities'
                    disabled={!scopedFormData?.municipality}
                    variant='outlined'
                    fullWidth
                    filter={
                      scopedFormData?.municipality && {
                        municipality: rest.record?.id
                          ? scopedFormData.municipality.id
                          : scopedFormData.municipality,
                      }
                    }
                    label={translate('resources.addresses.fields.city')}
                  >
                    <AutocompleteInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'neighborhood.id' : 'neighborhood'
                    )}
                    disabled={!scopedFormData?.municipality}
                    reference='neighborhoods'
                    variant='outlined'
                    validate={isRequired}
                    fullWidth
                    label={translate('resources.addresses.fields.neighborhood')}
                    filter={
                      scopedFormData?.municipality && {
                        municipality: rest.record?.id
                          ? scopedFormData.municipality.id
                          : scopedFormData.municipality,
                      }
                    }
                  >
                    <AutocompleteInput
                      onSelect={(neighborhood) => {
                        const conditionOther = neighborhood?.name === 'Otro';
                        if (scopedFormData) {
                          scopedFormData.anotherNeighborhood = '';
                        }
                        setIsOtherNeighborhood(conditionOther);
                        if (scopedFormData?.anotherNeighborhood) {
                          scopedFormData.anotherNeighborhood = '';
                        }
                      }}
                    />
                  </ReferenceInput>
                </Grid>
                {isOtherNeighborhood ? (
                  <Grid item xs={4}>
                    <TextInput
                      source={getSource('anotherNeighborhood')}
                      validate={[required(), maxLength(40)]}
                      variant='outlined'
                      fullWidth
                      value={scopedFormData?.anotherNeighborhood}
                      label={'Especifique colonia'}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <TextInput
                    source={getSource('street')}
                    validate={[required(), maxLength(40)]}
                    variant='outlined'
                    fullWidth
                    label={translate('resources.addresses.fields.street')}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextInput
                    source={getSource('outsideNumber')}
                    validate={isRequired}
                    variant='outlined'
                    fullWidth
                    label={translate(
                      'resources.addresses.fields.outsideNumber'
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    source={getSource('insideNumber')}
                    variant='outlined'
                    fullWidth
                    label={translate('resources.addresses.fields.insideNumber')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    source={getSource('postalCode')}
                    variant='outlined'
                    validate={[...isRequired, validateAddress]}
                    fullWidth
                    label={translate('resources.addresses.fields.postalCode')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    source={getSource('reference')}
                    variant='outlined'
                    fullWidth
                    label={translate('resources.addresses.fields.reference')}
                  />
                </Grid>
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default Addresses;
