import React, { useState } from 'react';
import {
  AutocompleteInput,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  required,
  maxValue,
  SaveButton,
  SelectInput,
  useTranslate,
  TextInput,
} from 'react-admin';
import { Grid, Box, Toolbar } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  ApplicationSource,
  ApplicationType,
  Company,
  MessageTypePersonForLoanApplication,
  PersonType,
  monthsP2P,
  yesNo,
} from '../../Utils/enums';
import {
  formatInputNumber,
  messageWhenIsEmployeeOrFranchiseManager,
  parseInputNumber,
} from '../../Utils/functions';
import Typography from '@material-ui/core/Typography';
import FormatNumberInput from '../../Components/FormatNumberInput';
import { LOAN_APPLICATION_CONF } from './LoanApplicationConfig';
import { useForm } from 'react-final-form';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    mr: {
      marginRight: '5px',
    },
    mt: {
      marginTop: 'inherit',
    },
  })
);

const LoanApplicationElement = ({ formProps, props }) => {
  const { person, legalPerson } = props;
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const translate = useTranslate();
  const classes = useStyles();
  const [applicationType, setApplicationType] = useState<number>();

  const record = (props as any).record || {};

  const isNew = !record?.id;
  const company =
    (isNew ? user.company?.id : record.company?.id) || Company.Company;

  const form = useForm();
  const [
    messageManagerFranchiseOrEmployee,
    setMessageManagerFranchiseOrEmployee,
  ] = useState(
    messageWhenIsEmployeeOrFranchiseManager(person, formProps?.record?.person)
  );
  const [values, setValues] = useState(form.getState().values);
  const personType = values?.person
    ? PersonType.PhysicalPerson
    : PersonType.LegalPerson;
  const notesForIsFranchiseManagerOrEmployee = (
    isFranchiseManager: boolean,
    isEmployee: boolean
  ) => {
    setMessageManagerFranchiseOrEmployee('');
    if (isFranchiseManager && isEmployee) {
      setMessageManagerFranchiseOrEmployee(
        MessageTypePersonForLoanApplication.IsEmployeeAndFranchiseManager
      );
    }
    if (isFranchiseManager) {
      setMessageManagerFranchiseOrEmployee(
        MessageTypePersonForLoanApplication.IsFranchiseManager
      );
    }
    if (isEmployee) {
      setMessageManagerFranchiseOrEmployee(
        MessageTypePersonForLoanApplication.IsEmployee
      );
    }
  };
  return (
    <>
      <Grid container spacing={1} classes={{ root: classes.root }}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            General
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={1} classes={{ root: classes.mr }}>
          {person || formProps?.record?.person ? (
            <ReferenceInput
              fullWidth
              source='person.id'
              resource='loan-applications'
              reference='people'
              variant='outlined'
              validate={[required()]}
              defaultValue={person ? person : formProps?.record?.person}
            >
              <AutocompleteInput
                helperText={messageManagerFranchiseOrEmployee}
                optionText={(record) =>
                  record &&
                  `${record.firstName} ${record.fatherLastName ?? ''} – ${
                    record.documentNumber
                  }`
                }
                onSelect={(selected) => {
                  setMessageManagerFranchiseOrEmployee('');
                  notesForIsFranchiseManagerOrEmployee(
                    selected.isFranchiseManager,
                    selected.isEmployee
                  );
                }}
              />
            </ReferenceInput>
          ) : legalPerson || formProps?.record?.legalPerson ? (
            <ReferenceInput
              fullWidth
              source='legalPerson.id'
              resource='loan-applications'
              reference='legal-people'
              variant='outlined'
              validate={[required()]}
              defaultValue={
                legalPerson ? legalPerson : formProps?.record?.legalPerson
              }
            >
              <AutocompleteInput
                optionText={(record) =>
                  record && `${record.documentNumber} - ${record.businessName}`
                }
              />
            </ReferenceInput>
          ) : (
            <>
              <Grid item xs={6} classes={{ root: classes.mt }}>
                <SelectInput
                  source={props.record?.id ? 'personType.id' : 'personType'}
                  fullWidth
                  label={translate(
                    'resources.loan-applications.fields.personType'
                  )}
                  variant='outlined'
                  onChange={(e) => {
                    setMessageManagerFranchiseOrEmployee('');
                    form.change('credit_type', null);
                    setValues(form.getState().values);
                  }}
                  validate={[required()]}
                  choices={[
                    { id: 1, name: 'Persona física' },
                    { id: 2, name: 'Persona moral' },
                  ]}
                />
              </Grid>
              <Grid item xs={6} classes={{ root: classes.mt }}>
                {values?.personType === PersonType.LegalPerson ? (
                  <ReferenceInput
                    source={'legalPerson'}
                    reference='legal-people'
                    label={translate(
                      'resources.loan-applications.fields.legalPerson'
                    )}
                    validate={[required()]}
                    fullWidth
                    variant='outlined'
                  >
                    <AutocompleteInput
                      optionText={(record) =>
                        record &&
                        `${record.documentNumber} - ${record.businessName}`
                      }
                    />
                  </ReferenceInput>
                ) : (
                  <ReferenceInput
                    source={'person'}
                    reference='people'
                    label={translate(
                      'resources.loan-applications.fields.person'
                    )}
                    validate={[required()]}
                    fullWidth
                    variant='outlined'
                  >
                    <AutocompleteInput
                      helperText={messageManagerFranchiseOrEmployee}
                      onSelect={(selected) => {
                        notesForIsFranchiseManagerOrEmployee(
                          selected.isFranchiseManager,
                          selected.isEmployee
                        );
                      }}
                      optionText={(record) =>
                        record &&
                        `${record.documentNumber} - ${record.firstName} ${record.fatherLastName}`
                      }
                    />
                  </ReferenceInput>
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            fullWidth
            source={props.record?.id ? 'applicationType.id' : 'applicationType'}
            resource='loan-applications'
            reference='application-types'
            onChange={(e) => setApplicationType(e.target.value)}
            variant='outlined'
            validate={[required()]}
            filter={{
              company: company,
            }}
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            fullWidth
            source='amount'
            defaultValue='0'
            format={(v) => formatInputNumber(v)}
            parse={(v) => parseInputNumber(v)}
            resource='loan-applications'
            variant='outlined'
            validate={[required()]}
          />
        </Grid>
        <Grid item xs={4}>
          {applicationType === ApplicationType.P2P ||
          formProps.form.getState().values?.applicationType?.id ===
            ApplicationType.P2P ? (
            <SelectInput
              fullWidth
              source='months'
              resource='loan-applications'
              variant='outlined'
              choices={monthsP2P}
              validate={[
                required(),
                maxValue(360, 'La cantidad máxima es 360 meses'),
              ]}
            />
          ) : (
            <NumberInput
              fullWidth
              source='months'
              resource='loan-applications'
              variant='outlined'
              validate={[
                required(),
                maxValue(360, 'La cantidad máxima es 360 meses'),
              ]}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            fullWidth
            source='interestRate'
            variant='outlined'
            resource='loan-applications'
          />
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            fullWidth
            source={
              props.record?.id ? 'applicationReason.id' : 'applicationReason'
            }
            resource='loan-applications'
            reference='application-reasons'
            variant='outlined'
            filter={{
              company: company,
            }}
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            fullWidth
            source={props.record?.id ? 'clientSource.id' : 'clientSource'}
            resource='loan-applications'
            reference='client-sources'
            variant='outlined'
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            fullWidth
            source='fundingCompany.id'
            resource='loan-applications'
            reference='funding-companies'
            variant='outlined'
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
        </Grid>
        {LOAN_APPLICATION_CONF.fields.notary && (
          <Grid item xs={4}>
            <ReferenceInput
              fullWidth
              source={props.record?.id ? 'notary.id' : 'notary'}
              resource='loan-applications'
              reference='notaries'
              variant='outlined'
            >
              <SelectInput />
            </ReferenceInput>
          </Grid>
        )}
        <Grid item xs={4}>
          <ReferenceInput
            fullWidth
            source={props.record?.id ? 'clientSource.id' : 'clientSource'}
            resource='loan-applications'
            reference='client-sources'
            variant='outlined'
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            fullWidth
            source='fundingCompany.id'
            resource='loan-applications'
            reference='funding-companies'
            variant='outlined'
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
        </Grid>
        {LOAN_APPLICATION_CONF.fields.notary && (
          <Grid item xs={4}>
            <ReferenceInput
              fullWidth
              source={props.record?.id ? 'notary.id' : 'notary'}
              resource='loan-applications'
              reference='notaries'
              variant='outlined'
            >
              <SelectInput />
            </ReferenceInput>
          </Grid>
        )}
        <Grid item xs={4}>
          <SelectInput
            fullWidth
            source='hasGuarantee'
            resource='loan-applications'
            variant='outlined'
            choices={yesNo}
            defaultValue={false}
            onChange={() => {
              form.change('guaranteeAmount', 0);
              setValues(form.getState().values);
            }}
          />
        </Grid>
        {values.hasGuarantee && (
          <Grid item xs={4}>
            <FormatNumberInput
              label='Valor estimado de garantía'
              source='guaranteeAmount'
              resource='loan-applications'
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <ReferenceInput
            fullWidth
            source={props.record?.id ? 'credit_type.id' : 'credit_type'}
            reference='credit-types'
            resource='loan-applications'
            variant='outlined'
            filter={{
              personType: values.personType || personType || 0,
              active: true,
            }}
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Toolbar>
        <Box display='flex' justifyContent='space-between' width='100%'>
          <SaveButton
            saving={formProps.saving}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            redirect='list'
          />
        </Box>
      </Toolbar>
    </>
  );
};

const LoanApplicationForm = (props) => {
  const { person, legalPerson } = props;

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        person,
        legalPerson,
        applicationSource: ApplicationSource.CRM,
      }}
      render={(formProps) => {
        return (
          <form>
            <LoanApplicationElement props={props} formProps={formProps} />
          </form>
        );
      }}
    />
  );
};
export default LoanApplicationForm;
