import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AvatarField from './AvatarField';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

interface BusinessNameFieldProps {
    record?: any;
    entity?: string;
    source?: string;
    size?: number;
    label?: string;
}


export const BusinessNameField = (props: BusinessNameFieldProps) => {
    const { record = {}, size = 22 } = props;
    const classes = useStyles();

    return record ? (
        <div className={classes.root}>
          <AvatarField record={record} size={size} className={classes.avatar} />
          {record[props.entity]
            ? `${record[props.entity].businessName}`
            : '-'}
        </div>
      ) : null;

}