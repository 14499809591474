import React from 'react';
import {
  Datagrid,
  TextField,
  useTranslate,
  Filter,
  TextInput,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';

import ContactField from '../../Components/ContactField';
import FullNameField from '../../Components/FullNameField';
import ListActions from '../../Components/ListActions';
import CustomList from '../../Components/CustomList';
import AgentAssignButton from './AgentAssignButton';
import AgentUnassignButton from './AgentUnassignButton';
import { getUserFromLS } from '../../Utils/functions';

const ENTITY_NAME = 'person';

const useStyles = makeStyles({
  small: {
    width: '120px',
  },
  medium: {
    width: '200px',
  },
});

const PersonBulkActionButtons = ({permissions, ...props}) => (
  <>
    {permissions?.[ENTITY_NAME]?.assignagent?.enabled && <AgentAssignButton {...props} />}
    {permissions?.[ENTITY_NAME]?.unassignagent?.enabled && <AgentUnassignButton {...props} />}
  </>
);

const PersonFilter = (props) => {
  const classes = useStyles(props);

  return (
    <Filter {...props} variant='outlined'>
      <TextInput
        source='code_id'
        label='Id'
        className={classes.small}
        resettable
        alwaysOn
      />
      <TextInput
        source='q'
        label='resources.people.fields.firstName'
        className={classes.medium}
        resettable
        alwaysOn
      />
      <TextInput
        source='documentNumber'
        label='resources.people.fields.documentNumber'
        resettable
      />
      <ReferenceInput
        reference='employees'
        className={classes.medium}
        source='agent'
        label='resources.people.fields.agent'
        resettable
      >
        <AutocompleteInput
          optionText={(record) =>
            record.id &&
            `${record?.code || ''} | ${record.person?.firstName ?? ''} ${
              record.person?.fatherLastName ?? ''
            }`
          }
        />
      </ReferenceInput>
    </Filter>
  );
};

export const PersonList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const user = getUserFromLS();
  const role = user?.role.id;

  return (
    <CustomList
      filters={<PersonFilter />}
      bulkActionButtons={
        <PersonBulkActionButtons permissions={permissions} {...props} />
      }
      actions={
        <ListActions entity='person' permissions={permissions} {...props} />
      }
      {...props}
      hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
    >
      <Datagrid rowClick='show'>
        <TextField source='code_id' label="Id" />
        <FullNameField source='firstName' />
        <TextField source='documentNumber' />
        <FunctionField
          source='agent'
          emptyText='-'
          render={(record) =>
            record.agent
              ? `${record?.agent?.code ?? ''} | ${
                  record?.agent?.person?.firstName ?? ''
                } ${record?.agent?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <ContactField
          label={translate('resources.people.fields.cellPhone')}
          source='contacts'
          contactType='Celular'
        />
      </Datagrid>
    </CustomList>
  );
};

export default PersonList;
