import React from 'react';
import { Divider, Grid, List, Paper, Typography } from '@material-ui/core';

import {
  applicationReasons,
  applicationSource,
  applicationTypes,
  clientSource,
  evaluationUnits,
  notaries,
  loanApplicationStates,
  substates,
  applicationTypeSettings,
  guaranteeType,
  creditType,
} from '../../../Views';
import ListItemLink from '../ListItemLink';
import { usePermissions } from 'ra-core';

const LoanApplication = ({ classes, iconColor }) => {
  const { permissions } = usePermissions();
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography gutterBottom variant='h4' component='h2'>
          Solicitudes
        </Typography>
        <Divider />
        <List className={classes.list} aria-labelledby='conjuration subtitle'>
          { permissions?.['application-reason']?.find.enabled && 
            <ListItemLink
              icon={<applicationReasons.icon />}
              primary='Razones de las Solicitudes'
              to='/application-reasons'
              color={iconColor}
            />
          }
          { permissions?.['application-source']?.find.enabled && 
            <ListItemLink
              icon={<applicationSource.icon />}
              primary='Fuentes de las Solicitudes'
              to='/application-sources'
              color={iconColor}
            />
          }
          { permissions?.['application-type']?.find.enabled && 
            <ListItemLink
              icon={<applicationTypes.icon />}
              primary='Tipos de Solicitudes'
              to='/application-types'
              color={iconColor}
            />
          }
          { permissions?.['application-type-settings']?.find.enabled && 
            <ListItemLink
              icon={<applicationTypeSettings.icon />}
              primary='Configuración Tipos de Solicitudes'
              to='/application-type-settings'
              color={iconColor}
            />
          }
          { permissions?.['client-source']?.find.enabled && 
            <ListItemLink
              icon={<clientSource.icon />}
              primary='Fuentes de Clientes'
              to='/client-sources'
              color={iconColor}
            />
          }
          { permissions?.['evaluation-unit']?.find.enabled && 
            <ListItemLink
              icon={<evaluationUnits.icon />}
              primary='Unidades de evaluación'
              to='/evaluation-units'
              color={iconColor}
            />
          }
          { permissions?.['notary']?.find.enabled && 
            <ListItemLink
              icon={<notaries.icon />}
              primary='Notarías'
              to='/notaries'
              color={iconColor}
            />
          }
          { permissions?.['loan-application-state']?.find.enabled && 
            <ListItemLink
              icon={<loanApplicationStates.icon />}
              primary='Estados de Solicitudes'
              to='/loan-application-states'
              color={iconColor}
            />
          }
          { permissions?.['substates']?.find.enabled && 
            <ListItemLink
              icon={<substates.icon />}
              primary='Subestados'
              to='/substates'
              color={iconColor}
            />
          }
          { permissions?.['guarantee-types']?.find.enabled && 
            <ListItemLink
              icon={<guaranteeType.icon />}
              primary='Tipos de Garantía'
              to='/guarantee-types'
              color={iconColor}
            />
          }
          { permissions?.['credit-types']?.find.enabled && 
            <ListItemLink
              icon={<creditType.icon />}
              primary='Tipos de Crédito'
              to='/credit-types'
              color={iconColor}
            />
          }
        </List>
      </Paper>
    </Grid>
  );
};

export default LoanApplication;
