import * as React from 'react';
import { useListContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Note from './Note';
import NoteForm from './NoteForm';

const useStyles = makeStyles({
  root: {
    marginTop: '0.5em',
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    width: '100%',
  },
  flex: {
    display: 'flex',
  },
  width100: {
    width: '100%',
    marginBottom: '25px',
    '& div.ql-editor': {
      minHeight: '80px',
    },
  },
});

const NotesIterator = ({
  showStatus,
  reference,
  segment,
  related,
}: {
  showStatus?: boolean;
  reference: string;
  segment: string;
  related: number;
}) => {
  const classes = useStyles();
  const { data, ids, loaded } = useListContext();
  const userConnect = JSON.parse(localStorage.getItem('user') || '{}');

  if (!loaded) return null;

  return (
    <div>
      <div className={classes.width100}>
        <NoteForm segment={segment} related={related} />
      </div>
      <div className={classes.root}>
        {ids.map((id, index) =>
          data[id].isDeleted ? null : (
            <Note
              note={data[id]}
              isLast={index === ids.length - 1}
              showStatus={showStatus}
              reference={reference}
              key={index}
              userConnect={userConnect}
            />
          )
        )}
      </div>
    </div>
  );
};

export default NotesIterator;
