import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import ApplicationSourceList from './ApplicationSourceList';

export const ApplicationSource: object = {
  list: ApplicationSourceList,
  icon: PlaylistAddCheckIcon,
};

export default ApplicationSource;
