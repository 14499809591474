import AccountBoxIcon from '@material-ui/icons/AccountBox';

import PositionList from './PositionList';

export const Position: object = {
  list: PositionList,
  icon: AccountBoxIcon,
};

export default Position;
