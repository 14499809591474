import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  BooleanField,
  SelectInput,
  FunctionField,
} from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { Drawer, makeStyles } from '@material-ui/core';

import UserEdit from './UserEdit';
import ListActions from '../../Components/ListActions';
import FullNameField from '../../Components/FullNameField';
import CustomList from '../../Components/CustomList';
import { ApplicationSource } from '../../Utils/enums';

const useStyles = makeStyles(() => ({
  drawerContent: { width: '48vw', padding: '0 1rem 1rem 1rem' },
}));

const Filters = (props) => {
  const [wasChanged, setWasChanged] = useState(false);
  return (
    <Filter {...props} variant='outlined'>
      <TextInput
        label='ra.action.search'
        source='username'
        resettable
        alwaysOn
      />
      <ReferenceInput
        reference='user-types'
        source='userType'
        resettable
        alwaysOn
        onChange={(e) => {
          setWasChanged(typeof e === 'string');
        }}
      >
        <SelectInput
          source='name'
          defaultValue={wasChanged ? undefined : ApplicationSource.CRM}
        />
      </ReferenceInput>
      <ReferenceInput reference='people' source='person' resettable>
        <AutocompleteInput
          optionText={(record) =>
            record.id &&
            `${record.documentNumber || ''} | ${record.firstName || ''} ${
              record.fatherLastName ?? ''
            }`
          }
        />
      </ReferenceInput>
    </Filter>
  );
};

const UserList = ({ permissions, ...props }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/users');
  }, [history]);
  console.log('filters values', props);

  return (
    <Fragment>
      <CustomList
        filters={<Filters />}
        actions={
          <ListActions {...props} entity='user' permissions={permissions} />
        }
        perPage={10}
        {...props}
      >
        <Datagrid rowClick={permissions?.['user'].update.enabled ? 'edit' : ''}>
          <TextField source='code_id' label='Id' />
          <FunctionField
            source='person'
            render={(record) =>
              record?.person ? (
                <FullNameField
                  record={record}
                  source='person'
                  entity='person'
                />
              ) : (
                <TextField source='legalPerson.businessName' emptyText='-' />
              )
            }
          />
          <TextField source='username' />
          <BooleanField source='confirmed' />
          <BooleanField source='blocked' />
          <TextField source='userType.name' />
          <TextField source='role.name' label='Rol' />
        </Datagrid>
      </CustomList>
      <Route path='/users/:id'>
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          );

          return (
            <Drawer open={isMatch} anchor='right' onClose={handleClose}>
              {isMatch && permissions?.['user'].update.enabled ? (
                <UserEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default UserList;
