import GroupIcon from '@material-ui/icons/Group';

import ClientSourceList from './ClientSourceList';

export const ClientSource: object = {
  list: ClientSourceList,
  icon: GroupIcon,
};

export default ClientSource;
