import React, { useState } from 'react';
import { useLocation, NavLink as RouterLink } from 'react-router-dom';
import { Button, Collapse, ListItem, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { Item, SubItem } from './interface';
import type { Theme } from '../../../Theme';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  item: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
    letterSpacing: 0,
  },
  active: {
    color: theme.palette.secondary.main,
  },
}));

const NavSubItem = ({ href, title }: SubItem) => {
  const classes = useStyles();

  return (
    <Button
      exact
      to={href}
      component={RouterLink}
      className={classes.item}
      style={{ paddingLeft: 40 }}
      activeClassName={classes.active}
    >
      <span className={classes.title}>{title}</span>
    </Button>
  );
};

export const NavSectionItem = ({
  icon: Icon,
  title,
  href,
  resource,
  subItems,
}: Item) => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(location?.pathname.includes(href));

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  return subItems ? (
    <ListItem disableGutters className={classes.list}>
      <Button className={classes.item} onClick={handleToggle}>
        <Icon className={classes.icon} size='20' />
        <span className={classes.title}>{title}</span>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Collapse in={open}>
        {subItems?.map((subItem) =>
          subItem.hasItemPermission ? <NavSubItem {...subItem} /> : null
        )}
      </Collapse>
    </ListItem>
  ) : (
    <Button
      exact
      to={href}
      component={RouterLink}
      className={classes.item}
      activeClassName={classes.active}
    >
      <Icon className={classes.icon} size='20' />
      <span className={classes.title}>{title}</span>
    </Button>
  );
};
