import React, { useState } from 'react';
import { Dialog, DialogContent, Button } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RelatedDocumentsList from './RelatedDocumentsList';

const DocumentValidateButton = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        startIcon={<CheckCircle />}
        variant='contained'
        color='secondary'
        onClick={handleOpen}
      >
        Validar documentos
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='lg'
        fullWidth
        aria-labelledby='form-dialog-title'
      >
        <DialogContent>
          <RelatedDocumentsList {...props} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DocumentValidateButton;
