import * as React from 'react';
import { cloneElement } from 'react';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  ListButton,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

/**
 * The ListActions component renders a actions table.
 *
 * Props:
 *  - className?: string;
 *  - exporter?: any;
 *  - filters?: any;
 *  - hasCreate?: boolean;
 *  - hasEdit?: boolean;
 *  - hasList?: boolean;
 *  - hasShow?: boolean;
 *  - maxResults?: any;
 *  - rest?: any;
 */
export const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    basePath,
    location,
    defaultTitle,
    isMaintenance,
    ...rest
  } = props;

  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      {isMaintenance && (
        <ListButton
          basePath='/maintenances'
          label='ra.action.back'
          icon={<ChevronLeft />}
        />
      )}
      {hasCreate && props.permissions?.[props.entity]?.create?.enabled && (
        <CreateButton basePath={basePath} />
      )}
    </TopToolbar>
  );
};

export default ListActions;
