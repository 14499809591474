import React from 'react';
import { Create, ListButton, TopToolbar, useNotify } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import PersonForm from './PersonForm';
import { errors, thereWasProblem } from '../../Utils/messages';

const PersonActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const PersonCreate = (props) => {
  const notify = useNotify();

  const onFailure = (error) => {
    const code = error?.body?.data?.errorCode || error?.data?.errorCode;
    const errorMessage = error?.body?.data?.errorMessage || errors[code];
    notify(
      `${thereWasProblem}: ${
        errorMessage || 'Hubo un error con su solicitud, inténtelo mas tarde...'
      }`,
      'warning'
    );
  };

  return (
    <Create
      actions={<PersonActions {...props} />}
      onFailure={onFailure}
      {...props}
    >
      <PersonForm />
    </Create>
  );
};

export default PersonCreate;
