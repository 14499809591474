import PersonPinIcon from '@material-ui/icons/PersonPin';

import OccupationList from './OccupationList';

export const Occupation: object = {
  list: OccupationList,
  icon: PersonPinIcon,
};

export default Occupation;
