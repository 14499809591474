import EmployeeList from './EmployeesList';
import EmployeeCreate from './EmployeeCreate';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EmployeeEdit from './EmployeeEdit';
import EmployeeShow from './EmployeeShow';

export const Employee: object = {
  list: EmployeeList,
  create: EmployeeCreate,
  edit: EmployeeEdit,
  show: EmployeeShow,
  icon: AssignmentIndIcon,
};

export default Employee;
