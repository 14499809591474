export const draftTextInputStylesDark = {
  toolbar: {
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '0.5rem',
    background: '#282c34',
  },
  toolbarSelect: {
    background: '#282c34',
    color: 'white',
    border: 'none',
    padding: '0.5rem',
    cursor: 'pointer',
  },
  toolbarButton: {
    background: '#282c34',
    color: '#e6e5e8',
    fontWeight: 'bold',
    border: 'none',
    padding: '0.5rem',
    cursor: 'pointer',
  },
  toolbarSelectedButton: {
    background: '#3d4555',
    color: '#CB1482',
    fontWeight: 'bold',
    border: 'none',
    padding: '0.5rem',
    cursor: 'pointer',
  },
  draftLinkWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  draftLinkForm: {
    marginLeft: '1rem',
    display: 'flex',
    flexDirection: 'row',
  },
};
