import React, { useState } from 'react';
import {
  FormWithRedirect,
  required,
  SaveButton,
  TextInput,
  AutocompleteInput,
  SelectInput,
  ReferenceInput,
} from 'react-admin';
import { Box, Toolbar, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { canShowSaveButton } from '../../Utils/MaintenancePersmissionsUtils';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      marginBottom: '10px',
      '& > p': {
        display: 'none',
      },
    },
  })
);

interface NotaryInterface {
  props?: object;
}

const isRequired = [required()];

export const NotaryForm: React.FC<NotaryInterface> = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState();

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        if (!values) setValues(formProps.record);

        return (
          <>
            <Grid container spacing={2} classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  General
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput
                      fullWidth
                      source='name'
                      resource='notaries'
                      validate={isRequired}
                      variant='outlined'
                    />
                    <TextInput
                      fullWidth
                      source='number'
                      resource='notaries'
                      validate={isRequired}
                      variant='outlined'
                    />
                    <ReferenceInput
                      source={formProps.record?.id ? 'country.id' : 'country'}
                      resource='notaries'
                      reference='countries'
                      fullWidth
                      validate={isRequired}
                      onChange={() => {
                        setValues(formProps.form.getState().values);
                        formProps.form.change('province', '');
                        formProps.form.change('municipality', '');
                      }}
                      variant='outlined'
                    >
                      <SelectInput />
                    </ReferenceInput>
                    <ReferenceInput
                      source={formProps.record?.id ? 'province.id' : 'province'}
                      resource='notaries'
                      reference='provinces'
                      fullWidth
                      validate={isRequired}
                      disabled={!values?.country}
                      onChange={() => {
                        setValues(formProps.form.getState().values);
                        formProps.form.change('municipality', '');
                      }}
                      filter={
                        values?.country && {
                          country: formProps.record.id
                            ? values.country.id
                            : values.country,
                        }
                      }
                      variant='outlined'
                    >
                      <AutocompleteInput />
                    </ReferenceInput>
                    <ReferenceInput
                      source={
                        formProps.record?.id
                          ? 'municipality.id'
                          : 'municipality'
                      }
                      resource='notaries'
                      reference='municipalities'
                      fullWidth
                      validate={isRequired}
                      disabled={!values?.province}
                      filter={
                        values?.province && {
                          province: formProps.record.id
                            ? values.province.id
                            : values.province,
                        }
                      }
                      variant='outlined'
                    >
                      <AutocompleteInput />
                    </ReferenceInput>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            { canShowSaveButton(props) && 
              <Toolbar>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <SaveButton
                    saving={formProps.saving}
                    disabled={formProps.invalid}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    redirect='list'
                  />
                </Box>
              </Toolbar>
            }
          </>
        );
      }}
    />
  );
};

export default NotaryForm;
