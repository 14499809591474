import React, { useContext, useRef, useState } from "react";
import { BooleanField, BooleanInput, FormWithRedirect, SaveButton, Tab, TabbedShowLayout, TextInput, Toolbar, required, useTranslate } from "react-admin";
import { FormShow } from "../../styles";
import { Accordion, AccordionSummaryProps, Box, FormControlLabel, Grid, GridProps, Switch, TypographyProps, styled } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";
import { usersPermissionsRolesConfig } from './UsersPermissionsRolesConfig'
import { Build } from "@material-ui/icons";
import { useForm } from 'react-final-form';


const UsersPermissionsRolesShowField = ({type = 'application', resource = 'roles-catalog', controller = '', action = '', translate, actionScreen, onChange, loading}: any) => {
  return (
    <Grid item xs={6} sm={4} md={3} lg={2}>
      <Typography variant="caption" >
        {translate(`resources.${resource}.fields.${action}`)}
      </Typography>
      {
        (actionScreen === 'create' || actionScreen === 'edit') ? 
        <BooleanInput 
          source={`permissions.${type}.controllers.${controller}.${action}.enabled` }
          label=''
          resource={resource} 
          defaultValue={false}
          onChange={onChange}
          disabled={loading}
        />
        :
        <BooleanField 
          source={`permissions.${type}.controllers.${controller}.${action}.enabled` }
          resource={resource} 
        />
      }
      
    </Grid>
  )
};

const AccordionSummaryStyled = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette?.type === 'dark'
      ? '#1c2025'
      : 'rgba(0, 0, 0, .03)',
  borderBottom:  theme.palette?.type === 'dark' 
      ? 'rgba(255, 255, 255, 0.12) 1px solid' 
      :  'rgba(0, 0, 0, 0.12) 1px solid',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const GridSubtitleStyled = styled((props: GridProps) => (
  <Grid
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette?.type === 'dark'
      ? 'rgba(255, 255, 255, .03)'
      : 'rgba(0, 0, 0, .03)',
  borderBottom:  theme.palette?.type === 'dark' 
      ? 'rgba(255, 255, 255, 0.3) 1px solid' 
      :  'rgba(0, 0, 0, 0.3) 1px solid',
  padding: '1px 5px'
}));

const RolesAccordion = (props: any) => {
  const {
    translate, 
    expanded = '', 
    handleChange, 
    item, 
    index, 
    tabPrefix = 'panel', 
    type, 
    actionScreen,
    loading
  } = props;
  const form = useForm();
  const inputElement = useRef(null);
  const [allChecked, setAllChecked] = useState(false);


  const onChangeAll = (e) => {
    const checked = e.target?.checked;
    setAllChecked(checked);
    item.permissions.forEach(permission => {
      permission.actions.forEach(action => {
        form.change(`permissions.${type}.controllers.${permission.controller}.${action}.enabled`, checked);
      });
    });
  };

  const onChangeOne = (e) => {
    const checked = e.target?.checked;
    if (!checked) setAllChecked(false);
  }

  return (
  <Accordion 
    expanded={expanded === `${tabPrefix}${index + 1}`} 
    onChange={handleChange(`${tabPrefix}${index + 1}`)}
  >
    <AccordionSummaryStyled>
      <Typography>{translate(`resources.${item.resource}.titles.${item.name}`)}</Typography>
    </AccordionSummaryStyled>
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          {
            (actionScreen === 'create' || actionScreen === 'edit') &&
            <FormControlLabel 
              control={<Switch
                  onChange={onChangeAll}
                  ref={inputElement}
                  checked={allChecked}
                  disabled={loading}
                  size="small"
                />} 
              label={
                <Typography variant="caption" >
                  {translate(`resources.${item.resource}.titles.selectAll`)}
                </Typography>
              }
              />
          }
        </Grid>
        {item.permissions.map(permission => (
          <>
            <GridSubtitleStyled xs={12} style={{marginTop: '10px'}}>
              <Typography variant="h6" >
                {translate(`resources.${item.resource}.titles.${permission.controller}`)}
              </Typography>
            </GridSubtitleStyled>
            {
              permission.actions.map(action => (
                <UsersPermissionsRolesShowField
                  controller={permission.controller}
                  action={action}
                  translate={translate}
                  resource={item.resource}
                  type={type}
                  actionScreen={actionScreen}
                  onChange={onChangeOne}
                  loading={loading}
                ></UsersPermissionsRolesShowField>
              ))
            }
          </>
        ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
  );
};


const PermissionsAccordion = (props: any) => {
  const [expanded, setExpanded] = React.useState<string | false>('panelPermissions1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return <>
    {
      usersPermissionsRolesConfig.application.map((item, index) => 
        <RolesAccordion type='application' item={item} index={index} tabPrefix='panelPermissions' expanded={expanded} handleChange={handleChange} {...props} />
      )
    }
  </>
}

const UserPermissionsAccordion = (props: any) => {
  const [expanded, setExpanded] = React.useState<string | false>('panelUserPermissions1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return <>
    {
      usersPermissionsRolesConfig['users-permissions'].map((item, index) => 
        <RolesAccordion type='users-permissions' item={item} index={index} tabPrefix='panelUserPermissions' expanded={expanded} handleChange={handleChange} {...props} />
      )
    }
  </>
}


const UsersPermissionsRolesAccordionForm = ({loading, ...props}: any) => {
  const classes = FormShow();
  const translate = useTranslate();
  const [values, setValues] = useState<any>();
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        if (!values) setValues(formProps.record);
        setIsAdmin(formProps.record?.isAdmin || false);
        
        return (
          <Grid container spacing={1}>
            <Grid item container spacing={2} xs={12} style={{padding: '1rem 1rem 0rem 1rem'}}>
              <Grid item xs={12} md={5}>
                <TextInput
                  source='name'
                  resource='roles-catalog'
                  validate={required()}
                  fullWidth
                  disabled={props.record?.id || loading}
                  className={classes.width100}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <TextInput
                  source='description'
                  resource='roles-catalog'
                  validate={required()}
                  fullWidth
                  disabled={props.record?.id || loading}
                  className={classes.width100}
                  variant='outlined'
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TabbedShowLayout className={classes.borderBottom}>
                <Tab label='Permisos' contentClassName={classes.flexParent} style={{'width': '100%'}}>
                  <PermissionsAccordion actionScreen={props.actionScreen} className={classes.width100} translate={translate} loading={loading} />
                </Tab>
                <Tab label='Usuarios / Auth' contentClassName={classes.flexParent} style={{'width': '100%'}}>
                  <UserPermissionsAccordion actionScreen={props.actionScreen} className={classes.width100} translate={translate} loading={loading} />
                </Tab>
              </TabbedShowLayout>
            </Grid>
            <Grid item xs={12}>
              { 
                (props.actionScreen === 'create' || props.actionScreen === 'edit') && (!isAdmin) &&
                <Toolbar>
                  <Box display='flex' justifyContent='space-between' width='100%'>
                    <SaveButton
                      saving={formProps.saving}
                      disabled={formProps.invalid}
                      handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                      redirect='list'
                    />
                  </Box>
                </Toolbar>
              }
            </Grid>
          </Grid>
        )
      }}
    />
  );
};

export default UsersPermissionsRolesAccordionForm;