import React, { useState } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CustomCircularProgress from '../../Components/CustomCircularProgress';

interface ViewI {
  record?: any;
}
const DocumentViewButton = ({ record }: ViewI) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    window.open(record.documents[0].url);
    setLoading(false);
  };

  return (
    <Tooltip title='Ver documento'>
      <IconButton size='small' color='primary' onClick={handleClick}>
        {loading ? <CustomCircularProgress size={24} /> : <VisibilityIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default DocumentViewButton;
