import React from 'react';
import {
  Edit,
  ListButton,
  TopToolbar,
  useNotify,
  useRefresh,
} from 'react-admin';
import LoanApplicationForm from './LoanApplicationForm';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  thereWasProblem,
  thisApplicationIsNotToYou,
} from '../../Utils/messages';

const LoanApplicationActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const LoanApplicationEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const onFailure = (error) => {
    if (error.body?.data?.errors === 'This application is no assigned to you') {
      notify(`${thisApplicationIsNotToYou}`, 'warning');
    } else if (
      error.body?.data?.errors ===
      'Does not meet the conditions to make a states change'
    ) {
      notify(
        `${thereWasProblem}: No cumple con las condiciones para cambiar de estado.`,
        'warning'
      );
    } else if (
      error.body?.data?.errors ===
      "You don't have permissions to make a states change"
    ) {
      notify(
        `${thereWasProblem}: No tiene permiso para cambiar este estado.`,
        'warning'
      );
    } else if (
      error.body?.data?.errors ===
      'The months field is invalid for a P2P Application'
    ) {
      notify(
        `${thereWasProblem}: Plazo inválido para aplicación P2P.`,
        'warning'
      );
    } else {
      notify(
        `${thereWasProblem}: ${error.body?.data?.errors || 'Fallo general'}`,
        'warning'
      );
    }
    refresh();
  };
  return (
    <Edit
      actions={<LoanApplicationActions {...props} />}
      onFailure={onFailure}
      {...props}
    >
      <LoanApplicationForm />
    </Edit>
  );
};
export default LoanApplicationEdit;
