import React from 'react';
import { TextInput } from 'react-admin';
import { formatInputNumber, parseInputNumber } from '../Utils/functions';

const FormatNumberInput = ({
  source,
  resource,
  disabled = false,
  ...props
}) => {
  return (
    <TextInput
      fullWidth
      source={source}
      defaultValue='0'
      format={(v) => formatInputNumber(v)}
      parse={(v) => parseInputNumber(v)}
      resource={resource}
      variant='outlined'
      disabled={disabled}
      {...props}
    />
  );
};

export default FormatNumberInput;
