import AvTimerIcon from '@material-ui/icons/AvTimer';

import PromotionList from './PromotionList';

export const Promotion: object = {
  list: PromotionList,
  icon: AvTimerIcon,
};

export default Promotion;
