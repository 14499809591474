import LoanApplicationCreate from './LoanApplicationCreate';
import LoanApplicationEdit from './LoanApplicationEdit';
import LoanApplicationList from './LoanApplicationList';
import LoanApplicationShow from './LoanApplicationShow';

export const loanApplications: object = {
  list: LoanApplicationList,
  edit: LoanApplicationEdit,
  create: LoanApplicationCreate,
  show: LoanApplicationShow,
};

export default loanApplications;
