export const changeMade = 'Cambio efectuado!';
export const nipConfirm = '¡Nip confirmado!';
export const thereWasProblem = 'Hubo un problema';
export const hasLoanActive =
  'Esta persona ya tiene una solicitud activa de este tipo';
export const guaranteeIsRegistered =
  'Este bien ya fue registrado como garantía';
export const thisApplicationIsNotToYou =
  'No puedes actualizar esta solicitud porque no esta asignada a ti';
export const agentSuccess = 'Gestor asignado exitosamente';

export const errors = {
  0: 'Fallo general',
  101: 'No posee la posición para hacer este cambio de estado.',
  102: 'La solicitud no cumple con las condiciones para cambiar de estado.',
  103: 'La solicitud no tiene los documentos necesarios para cambiar de estado.',
  105: 'No se puede eliminar este estado, ya que es el estado por default.',
  106: 'La(s) solicitud(es) seleccionada(s) ya cuenta(n) con un referidor asignado.',
  201: 'El nombre ya está registrado.',
  202: 'El código ya está registrado.',
  203: 'El manager ya está asignado a otra franquicia.',
  204: 'Campo de email requerido.',
  301: 'No puede cotejar los documentos. Se requiere tener la compañía originadora seleccionada.',
  401: 'El cliente no tiene una dirección válida.',
  402: 'El cliente no tiene correo y/o número de celular.',
  403: 'El cliente no tiene los datos suficientes.',
  404: 'El cliente ya tiene un flujo en progreso.',
  501: 'Unykoo ID inválido en la petición.',
  502: 'Inicialización inválida.',
  503: 'Formulario inválido.',
  504: 'Número de NIP inválido.',
  505: 'Buró de crédito inválido.',
  506: 'Documentos SIC inválidos.',
  601: 'La solicitud necesita tener un interés para calcular el rating.',
  701: 'Persona registrada, número de documento duplicado.',
  702: 'No tiene este cliente asignado.',
  703: 'Persona no editable',
  711: 'Persona moral registrada, número de documento duplicado.',
  712: 'No tiene este cliente asignado.',
  713: 'Persona moral no editable.',
  714: 'Email requerido para continuar.',
  801: 'Empleado registrado, código de empleado duplicado.',
  802: 'El correo suministrado ya está registrado.',
  810: 'Debe eliminar la garantía antes de eliminar el bien.',
  811: 'Información del coacreedor, rating o garantía incompleta.',
  900: 'Esta configuración ya existe.',
  1001: 'Este bien no posee una dirección válida registrada',
  2001: 'La persona seleccionada no tiene un contacto de tipo "Teléfono" registrado',
  1440: 'Este rol está en uso, no puede ser eliminado',
};
