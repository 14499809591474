import React from 'react';
import { Fragment, useCallback } from 'react';
import { Datagrid, TextField, Filter, TextInput, FunctionField, DeleteWithConfirmButton, useNotify, useRefresh, BooleanField } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { Drawer, makeStyles } from '@material-ui/core';

import StateCreate from './StateCreate';

import ListActions from '../../Components/ListActions';
import TablePlaceholder from '../../Components/TablePlaceholder';
import CustomList from '../../Components/CustomList';
import StateEdit from './StateEdit'
import StateInfoPanelShow from './StateInfoPanelShow';

const ENTITY_NAME = 'state';

const useStyles = makeStyles(() => ({
  drawerContent: { width: '50vw', padding: '0 1rem' },
}));

const Filters = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput label='ra.action.search' source='name' alwaysOn />
  </Filter>
);

const StateList = ({ permissions, hasCreate, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClose = useCallback(() => {
    history.push('/states');
  }, [history]);

  const onFailure = (error) => {
    notify(error.body?.data?.errors);
    refresh();
  };

  return (
    <Fragment>
      <CustomList
        {...props}
        filters={<Filters />}
        actions={
          <ListActions
            isMaintenance
            entity={ENTITY_NAME}
            permissions={permissions}
            {...props}
            hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
          />
        }
      >
        <Datagrid
          rowClick='edit'
          empty={<TablePlaceholder title='Estado' />}
          expand={<StateInfoPanelShow />}
        >
          <TextField source='id' resource='states' />
          <TextField source='name' resource='states' label='Nombre'/>
          <BooleanField source='isRestricted' resource='states' label='Restringido' defaultValue='value' />
          <BooleanField source='isDefault' resource='states' label='Default' defaultValue='value' />
          <FunctionField
            render={(record) => (
              <>
                <DeleteWithConfirmButton
                  record={record}
                  redirect={false}
                  label=''
                  confirmContent='¿Está seguro? '
                  confirmTitle='Eliminar Estado'
                  onFailure={onFailure}
                />
              </>
            )}
          />
        
        </Datagrid>
      </CustomList>
      <Route path='/states/create'>
        {({ match }) => (
          <Drawer open={!!match} anchor='right' onClose={handleClose}>
            <StateCreate
              className={classes.drawerContent}
              onCancel={handleClose}
              entity={ENTITY_NAME}
              {...props}
              hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
            />
          </Drawer>
        )}
      </Route>
      <Route path='/states/:id'>
        {({match}) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          );

          return (
            <Drawer
            open={isMatch} 
            anchor='right' 
            onClose={handleClose}
            >
              {isMatch ? (
                <StateEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                  entity={ENTITY_NAME}
                  hasEdit={permissions?.[ENTITY_NAME]?.update?.enabled}
                />
              ): (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default StateList;
