import * as React from 'react';
import { AppBar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { THEMES } from '../../../constants';
import type { Theme } from '../../../Theme';

import Settings from './Settings';
import Notifications from './Notifications/index';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.paper,
        }
      : {}),
  },
  spacer: {
    flex: 1,
  },
  toolbar: {
    minHeight: 64,
  },
}));

const TopBar: React.FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root} classes={classes} {...rest}>
      <span className={classes.spacer} />
      <Notifications />
      <Settings />
    </AppBar>
  );
};

export default TopBar;
