export interface ContactType {
  contactType: number;
  contact: string;
}

export interface AddressType {
  postalCode: string;
}

export const indexs = {
  'contacts[0]': 0,
};

export const getIndexFromForm = (index: number) => `contacts[${index}]`;

export function findDuplicate(
  values: ContactType[] | undefined[],
  currentValue?: ContactType,
  currentStringId?: string
) {
  let mapRepeats = {};
  let foundRepeatWithCurrent = 0;
  if (!currentStringId) return '';
  if (!currentValue) return '';
  if (values.length === 0) return '';

  for (let index = 0; index < values.length; index++) {
    const valueContactType = values[index];

    if (valueContactType === undefined) continue;

    foundRepeatWithCurrent =
      incrementRepeat(
        valueContactType.contactType,
        currentValue.contactType,
        valueContactType.contact,
        currentValue.contact
      ) && getIndexFromForm(index) !== currentStringId
        ? 1
        : 0;
    mapRepeats[getIndexFromForm(index)] = {
      repeat: foundRepeatWithCurrent,
      message: foundRepeatWithCurrent >= 1 ? 'Contacto duplicado' : '',
    };
  }

  return mapRepeats;
}

function incrementRepeat(
  valueContactType: number,
  currentContactType: number,
  valueContact: string,
  currentContact: string
) {
  return (
    !!currentContact &&
    !!valueContact &&
    valueContactType !== currentContactType &&
    valueContact === currentContact
  );
}

export function anotherFunction(
  values: ContactType[] | undefined[],
  currentContact: ContactType
) {
  if (values.length <= 1) {
    return '';
  }
}

export function getOnlyNumber(indexString: string) {
  const [nothing, thisNumber, ...rest] = indexString.split(/[^0-9]{1,}/);
  if (isNaN(Number(thisNumber))) {
    return -1;
  }
  return Number(thisNumber);
}

export const duplicatedErrorContacts = (messageValue: any) => {
  let errors = {};
  errors['contact'] = messageValue;
  return errors;
};

export function findDuplicateContactWithMessage(
  id: any,
  values: ContactType[] | undefined[],
  current?: ContactType
) {
  const foundDuplicate = {};
  if (values.length === 0 || values.length === 1 || !current) {
    return foundDuplicate;
  }
  const onlyIndex = getOnlyNumber(id);
  for (let index = 0; index < values.length; index++) {
    const value = values[index];
    if (value === undefined) continue;
    if (
      !!value.contact &&
      !!value.contactType &&
      !!current.contactType &&
      !!current.contact &&
      value.contact === current.contact &&
      value.contactType !== current.contactType &&
      onlyIndex > index
    ) {
      foundDuplicate[id] = {
        message: 'Contacto duplicado',
      };
    }
  }
  return foundDuplicate;
}

export function findDuplicateAddresses(
  id: any,
  values: AddressType[] | undefined[],
  current?: AddressType
) {
  const foundDuplicate = {};
  if (values.length === 0 || values.length === 1 || !current) {
    return foundDuplicate;
  }

  for (let index = 0; index < values.length; index++) {
    const value = values[index];
    if (value === undefined) continue;
    if (
      !!current.postalCode &&
      value.postalCode === current.postalCode &&
      getOnlyNumber(id) > index
    ) {
      foundDuplicate[id] = {
        message: 'Dirección duplicada',
      };
    }
  }
  return foundDuplicate;
}

export function conditionForLinkedFranchise(record: any) {
  if (record === undefined) return false;
  return !record;
}
