import React from 'react';
import { RichUtils } from 'draft-js';
import DraftTextInputLinkButton from './DraftTextInputLinkButton';
import { draftTextInputStyles } from './DraftTextInputStyles';
import { draftTextInputStylesDark } from './DraftTextInputStyles-dark';
import {
  defaultBlockStyles,
  defaultHeaderStyles,
  defaultInlineStyles,
} from './DraftTextInputToolbarUtils';
import { Theme } from '../../../Theme';
import { useTheme } from '@material-ui/core';

const DraftTextInputToolbar = ({
  editorState,
  setEditorState,
  showHeadingSelect = true,
}) => {
  const theme: Theme = useTheme();
  const textInputStyles = theme.palette.type === 'light' ? draftTextInputStyles : draftTextInputStylesDark;
  // CUSTOM BUTTONS EVENTS
  const onChangeSelectHeading = (e: any) => handleBlockStyle(e, e.target.value);
  const handleInlineStyle = (event, style) => {
    event.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };
  const handleBlockStyle = (event: any, block: any) => {
    event.preventDefault();
    setEditorState(RichUtils.toggleBlockType(editorState, block));
  };

  // CUSTOM BUTTONS
  const renderHeadingSelect = () => {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);
    return (
      <select
        name='selectHeading'
        defaultValue={0}
        onChange={onChangeSelectHeading}
        style={textInputStyles.toolbarSelect}
      >
        {defaultHeaderStyles.map((style, index) => {
          const selected = currentBlockType === style.type;
          return (
            <option key={`sel-${index}`} value={style.type} selected={selected}>
              {style.label}
            </option>
          );
        })}
      </select>
    );
  };
  const renderInlineStyleButton = (style: any, index: any) => {
    const currentInlineStyle = editorState.getCurrentInlineStyle();
    const className = currentInlineStyle.has(style.type)
      ? textInputStyles.toolbarSelectedButton
      : textInputStyles.toolbarButton;
    return (
      <button
        key={index}
        title={style.toolTip}
        onMouseDown={(event) => handleInlineStyle(event, style.type)}
        onClick={(event) => event.preventDefault()}
        style={className}
      >
        {style.label}
      </button>
    );
  };
  const renderBlockStyleButton = (block: any, index: number) => {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);
    const className =
      currentBlockType === block.type
        ? textInputStyles.toolbarSelectedButton
        : textInputStyles.toolbarButton;
    return (
      <button
        key={index}
        title={block.toolTip}
        onMouseDown={(event) => handleBlockStyle(event, block.type)}
        onClick={(event) => event.preventDefault()}
        style={className}
      >
        {block.label}
      </button>
    );
  };

  // EVENTS
  const onLinkChange = (contentWithLink) => setEditorState(contentWithLink);

  return (
    <div
      id='editor-toolbar'
      className='ql-toolbar ql-snow'
      style={textInputStyles.toolbar as any}
    >
      {showHeadingSelect && renderHeadingSelect()}
      {defaultInlineStyles.map((style, index) =>
        renderInlineStyleButton(style, index)
      )}
      {defaultBlockStyles.map((block, index) =>
        renderBlockStyleButton(block, index)
      )}
      <DraftTextInputLinkButton
        editorState={editorState}
        onChange={onLinkChange}
      ></DraftTextInputLinkButton>
    </div>
  );
};

export default DraftTextInputToolbar;
