import React from "react";
import { Create, ListButton, TopToolbar } from "react-admin";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import SegmentRoleForm from "./SegmentRoleForm";


const SegmentRoleActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const SegmentRoleCreate = (props: any) => (
  <Create actions={<SegmentRoleActions {...props} />} {...props}>
    <SegmentRoleForm {...props} />
  </Create>
);

export default SegmentRoleCreate;