import React from 'react';
import CustomList from '../../Components/CustomList';
import ListActions from '../../Components/ListActions';
import { Datagrid, DeleteWithConfirmButton, EditButton, Filter, FunctionField, ShowButton, TextField, TextInput, useLoading, useNotify, useRedirect, useRefresh } from 'react-admin';
import { errors, thereWasProblem } from '../../Utils/messages';
import { savePreviousPath } from '../../Utils/functions';

const ENTITY_NAME = 'roles-catalog';

const UsersPermissionsRolesFilter = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput source='name' resettable alwaysOn />
  </Filter>
);
export const UsersPermissionsRolesList = ({ permissions, ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const loading  = useLoading();

  const onDeletionFailure = (error) => {
    notify(
      `${thereWasProblem}: ${errors[error.body?.data?.errorCode] || error.body?.data?.errors || errors[0]}`,
      'warning'
    );
    refresh();
  };
  return (
    <CustomList
      filters={<UsersPermissionsRolesFilter />}
      actions={
        !loading && <ListActions hasCreate entity={ENTITY_NAME} permissions={permissions} {...props} />
      }
      {...props}
    >
      <Datagrid rowClick='' >
        <TextField source='name' label='Nombre' />
        <TextField source='description' label='Descripción' />
        <TextField source='nb_users' label='No. usuarios' />
        <FunctionField
          render={(record) => (
            <>
              {
                permissions?.[ENTITY_NAME]?.findone?.enabled && 
                <FunctionField
                  render={(record) => (
                    <>
                      <ShowButton
                        label=''
                        onClick={() => {
                          savePreviousPath(window.location.href);
                          redirect(`/roles-catalog/${record?.id}/show`);
                        }}
                        disabled={loading}
                      />
                    </>
                  )}
                />
              }
              {
                permissions?.[ENTITY_NAME]?.update?.enabled && (!record?.isAdmin) && 
                <EditButton record={record} label='' disabled={loading} />
              }
              {
                permissions?.[ENTITY_NAME]?.delete?.enabled && (!record?.isAdmin) && 
                <DeleteWithConfirmButton
                  record={record}
                  redirect={false}
                  label=''
                  confirmContent='¿Está seguro? '
                  confirmTitle='Eliminar Rol'
                  onFailure={onDeletionFailure}
                  disabled={loading}
                />
              }
            </>
          )}
        />
       
      </Datagrid>
    </CustomList>
  );
};

export default UsersPermissionsRolesList;