import { ChevronLeft } from "@material-ui/icons";
import React from "react";
import { Create, ListButton, TopToolbar } from "react-admin";
import { GuaranteeTypeForm } from "./GuaranteeTypeForm";

const GuaranteeTypeActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton 
      basePath={basePath} 
      label="ra.action.back"
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const GuaranteeTypeCreate = (props: any) => (
  <Create actions={<GuaranteeTypeActions {...props} />} {...props} >
    <GuaranteeTypeForm {...props} />
  </Create>
);

export default GuaranteeTypeCreate;