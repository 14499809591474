import React, { useState } from 'react';
import { Button, useMutation, useNotify, useRedirect } from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import { useRefresh } from 'ra-core';
import { makeStyles } from '@material-ui/styles';

import { errors, nipConfirm, thereWasProblem } from '../../Utils/messages';
import { Theme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogActions: {
    padding: theme.spacing(1, 3),
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const MoralPersonNipButton = ({ record }) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const classes = useStyles();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [nipNumber, setNipNumber] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setNipNumber('');
    setOpen(false);
  };

  const [handleSubmit, { loading }] = useMutation(
    {
      type: 'create',
      resource: 'credit-inquiry-unykoos/confirm',
      payload: {
        id: record.id,
        data: {
          unykoo: record.unykoo,
          nip: nipNumber,
          legalPerson: record.legalPerson,
        },
      },
    },
    {
      onSuccess: () => {
        handleClose();
        notify(nipConfirm);
        redirect(
          `/legal-people/${record.legalPerson}/show/credit-inquiry-unykoos`
        );
        refresh();
      },
      onFailure: (error) => {
        const code = error.body.data.errorCode;
        notify(
          `${thereWasProblem}: ${
            errors[code] ||
            'Hubo un error con su solicitud, inténtelo mas tarde...'
          }`,
          'warning'
        );
      },
    }
  );

  return (
    <div className={classes.root}>
      <Button
        variant='contained'
        endIcon={<SendIcon />}
        color='secondary'
        label='Confirmar NIP'
        onClick={handleOpen}
        disabled={record.requestDate ? true : false}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
      >
        <MuiDialogTitle id='form-dialog-title' disableTypography>
          <Typography variant='h4'>NIP</Typography>
        </MuiDialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label='Número NIP'
            margin='dense'
            onChange={(e) => setNipNumber(e.target.value)}
            placeholder='0000'
            type='text'
            variant='outlined'
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            label='CANCELAR'
            onClick={() => handleClose()}
            color='primary'
          />
          <Button
            label='ENVIAR'
            onClick={handleSubmit}
            color='primary'
            disabled={loading || nipNumber.length !== 4}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MoralPersonNipButton;
