import { useState } from 'react';
import {
  FormWithRedirect,
  required,
  SaveButton,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';
import { Box, Toolbar, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { canShowSaveButton } from '../../Utils/MaintenancePersmissionsUtils'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      marginBottom: '10px',
      '& > p': {
        display: 'none',
      },
    },
  })
);

interface DepartmentInterface {
  props?: object;
}

const isRequired = [required()];

export const DepartmentForm: React.FC<DepartmentInterface> = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState<any>();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { company } = user;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        if (!values) setValues(formProps.record);
        return (
          <>
            <Grid container spacing={2} classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  General
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ReferenceInput
                      source={
                        formProps.record?.id
                          ? 'parentDepartment.id'
                          : 'parentDepartment'
                      }
                      resource='departments'
                      reference='departments'
                      disabled={!company}
                      fullWidth
                      filter={
                        company?.id
                          ? {
                              company: company?.id,
                            }
                          : {
                              company,
                            }
                      }
                      variant='outlined'
                    >
                      <AutocompleteInput optionText='name' />
                    </ReferenceInput>
                    <TextInput
                      fullWidth
                      source='name'
                      resource='departments'
                      validate={isRequired}
                      variant='outlined'
                    />
                    <ReferenceInput
                      source={formProps.record?.id ? 'defaultRole.id' : 'defaultRole'}
                      resource='departments'
                      reference='users-permissions/roles'
                      disabled={!company}
                      fullWidth
                      validate={isRequired}
                      variant='outlined'
                      
                    >
                      <AutocompleteInput
                        optionText={(record) => record && `${record.name}`}
                      />
                    </ReferenceInput>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            { canShowSaveButton(props) && 
              <Toolbar>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <SaveButton
                    saving={formProps.saving}
                    disabled={formProps.invalid}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    redirect='list'
                  />
                </Box>
              </Toolbar>
            }
          </>
        );
      }}
    />
  );
};

export default DepartmentForm;
