import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useDataProvider } from 'react-admin';
import {
  Box,
  Button,
  IconButton,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  Badge,
  makeStyles,
  Checkbox,
} from '@material-ui/core';

import { Bell as BellIcon } from 'react-feather';
import NotificationCards from './NotificationCards';

const useStyles = makeStyles(() => ({
  popover: {
    width: 550,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Notifications: FC = () => {
  const user = JSON.parse(window.localStorage.getItem('user') || '{}').id;
  const classes = useStyles();
  const ref = useRef<any>(null);
  const dataProvider = useDataProvider();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [unreadOnly, setUnreadOnly] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [notifications, setNotifications] = useState([]);

  const getNotifications = () => {
    dataProvider
      .getList('notifications', {
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'id', order: 'DESC' },
        filter: { notificationOwner: user },
      })
      .then(({ data }) => {
        setNotifications(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleReadAll = () => {
    setNotifications(
      notifications.map((notification) => ({ ...notification, isRead: true }))
    );
    dataProvider.create(
      'notifications/mark-read',
      {
        data: {},
      },
      { onSuccess: () => getNotifications() }
    );
  };

  useEffect(() => {
    if (!notifications.length) {
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = (): void => {
    getNotifications();
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const notificationsAmount =
    notifications.filter((notification) => !notification.isRead).length > 9
      ? '9+'
      : notifications.filter((notification) => !notification.isRead).length;

  return (
    <>
      <Tooltip title='Notificaciones'>
        <IconButton color='inherit' ref={ref} onClick={handleOpen}>
          <Badge color='secondary' badgeContent={notificationsAmount}>
            <SvgIcon fontSize='small'>
              <BellIcon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2} className={classes.header}>
          <Typography variant='h4' color='textPrimary'>
            Notificaciones
          </Typography>
          <Typography variant='h6' color='textPrimary'>
            Mostrar solo no leídos
            <Checkbox
              checked={unreadOnly}
              onChange={() => setUnreadOnly(!unreadOnly)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Typography>
        </Box>
        <NotificationCards
          loading={loading}
          notifications={notifications}
          handleClose={handleClose}
          getNotifications={getNotifications}
          unreadOnly={unreadOnly}
        />
        <Box
          p={1}
          display='flex'
          justifyContent='center'
          onClick={handleReadAll}
        >
          <Button size='small'>Marcar todas como leídas</Button>
        </Box>
      </Popover>
    </>
  );
};

export default Notifications;
