export const sex = [
  { id: 'M', name: 'M' },
  { id: 'F', name: 'F' },
];

export const monthsP2P = [
  { id: 6, name: '6' },
  { id: 12, name: '12' },
  { id: 18, name: '18' },
  { id: 24, name: '24' },
  { id: 36, name: '36' },
  { id: 48, name: '48' },
  { id: 60, name: '60' },
  { id: 72, name: '72' },
  { id: 84, name: '84' },
  { id: 96, name: '96' },
  { id: 108, name: '108' },
  { id: 120, name: '120' },
];

export enum Department {
  Risk = 10,
  CommercialLink = 4,
}

export enum ErrorCode {
  MissingDocumentsErrorCode = 103,
}

export const DocumentSegment = {
  Personal: { id: 1, name: 'person' },
  Business: { id: 2, name: 'legalPerson' },
  Asset: { id: 5, name: 'asset' },
  ApplicationPersonal: { id: 6, name: 'loanApplication' },
  ApplicationMoral: { id: 7, name: 'loanApplication' },
  Promotion: { id: 8, name: 'promotion' },
};

export const GuaranteeTypes = [
  { id: 1, name: 'Propio' },
  { id: 2, name: 'Garante' },
];

export enum GuaranteeType {
  Own = 1,
  Guarantor,
}

export enum BlacklistSegment {
  Person = 'person',
  LegalPerson = 'legalPerson',
  Franchise = 'franchise',
}

export enum RepresentativeType {
  Proxy = 'Proxy',
  Shareholder = 'Shareholder',
  LegalRepresentative = 'LegalRepresentative',
}

export enum RepresentativeTypeNames {
  Proxy = 'Apoderado',
  Shareholder = 'Accionista',
  LegalRepresentative = 'Representante',
}

export enum PersonType {
  PhysicalPerson = 1,
  LegalPerson,
}

export enum PersonTypeName {
  PhysicalPerson = 'Persona física',
  LegalPerson = 'Persona moral',
}

export const personTypes = [
  {
    id: PersonType.PhysicalPerson,
    name: PersonTypeName.PhysicalPerson.toString(),
  },
  {
    id: PersonType.LegalPerson,
    name: PersonTypeName.LegalPerson.toString(),
  },
];

export enum ApplicationType {
  ImproveMortgage = 1,
  Solution,
  P2P,
  IncreasingQuoteSolution,
  Business,
  Construction,
  Promotion,
}

export const ratings = [
  {
    id: 'AA',
    name: 'AA',
  },
  {
    id: 'A',
    name: 'A',
  },
  {
    id: 'B',
    name: 'B',
  },
  {
    id: 'C',
    name: 'C',
  },
  {
    id: 'D',
    name: 'D',
  },
  {
    id: 'E',
    name: 'E',
  },
  {
    id: 'F',
    name: 'F',
  },
];

export enum DocumentType {
  CURP = 5,
  CreditReport = 27,
  RFC = 34,
  Promotion = 78,
}

export enum StateType {
  LoanApplication = 1,
  Employee,
  Users,
  Franchises,
  Assets,
  References = 7,
}

export enum RelatedType {
  Guarantor = 4,
}

export enum AssetSegment {
  Property = 'property',
  Vehicle = 'vehicle',
}

export enum ApplicationSource {
  CRM = 1,
  Web,
}

export enum Position {
  JrLawyer = 16,
  SrLawyer = 25,
  ManagerLayer = 24,
  Director = 26,
}

export enum UnykooState {
  Init = 'INIT',
  Progress = 'PROGRESS',
  Success = 'SUCCESS',
}

export const assetsType = [
  {
    id: 'property',
    name: 'Propiedad',
  },
  {
    id: 'vehicle',
    name: 'Vehículo',
  },
];

export const documentTypeSegment = [
  {
    id: 'files',
    name: 'Documento',
  },
  {
    id: 'info',
    name: 'Numero de documento',
  },
];

export const propertyUse = [
  {
    id: 'residential',
    name: 'Residencial',
  },
  {
    id: 'commercial',
    name: 'Comercial',
  },
  {
    id: 'institutional',
    name: 'Institucional',
  },
  {
    id: 'industrial',
    name: 'Industrial',
  },
  {
    id: 'agricultural',
    name: 'Agricultura',
  },
];

export const timelineType = {
  Amount: 'change_amount',
  BlacklistIn: 'blacklist_in',
  BlacklistOut: 'blacklist_out',
  Months: 'change_months',
  InterestRate: 'change_interest_rate',
  Referrer: 'change_referrer',
  Agent: 'change_agent',
  Analyst: 'change_analyst',
  Lawyer: 'change_lawyer',
  JrLawyer: 'change_lawyer_jr',
  State: 'change_state',
  Substate: 'change_substate',
  Manager: 'change_manager',
  ApplicationReason: 'change_application_reason',
  ClientDocumentNumber: 'change_documentNumber',
  PersonName: 'change_person_name',
  LegalPersonName: 'change_legalPerson_name',
  Addresses: 'change_address',
  Contacts: 'change_contacts',
  IncomeCompany: 'change_income_company',
  IncomeAmount: 'change_income_amount',
  AssetDocument: 'change_asset_documentNumber',
  AssetValue: 'change_asset_value',
  AssetDelete: 'delete_asset',
  AssetCreate: 'create_asset',
  IncomeDelete: 'delete_income',
  IncomeCreate: 'create_income',
  AddDocumentCheck: 'add_check_documents',
  RemoveDocumentCheck: 'remove_check_documents',
};

export enum EmployeeState {
  Active = 1,
  Inactive = 2,
  Discharged = 3,
}

export enum FranchiseState {
  Active = 8,
  Inactive = 9,
}

export enum SegmentState {
  segmentState2 = 'Segmento de estado 2',
  segmentState3 = 'Segmento de estado 3',
  segmentState4 = 'Segmento de estado 4',
}

export enum Company {
  Company = 1,
}

export const yesNo = [
  { id: true, name: 'Si' },
  { id: false, name: 'No' },
];

export enum ReferenceState {
  PendingCall = 37,
  NotContacted,
  Contacted,
}

export const personType = {
  physical: 'Persona física',
  moral: 'Persona moral',
};

export const entities = {
  physical: 'Persona física',
  moral: 'Persona moral',
  asset: 'Bien',
  franchise: 'Franquicia',
  reference: 'Referencia',
};

export enum PersonTypeAlias {
  Physical = 'PF',
  Moral = 'PM',
}

export enum MessageTypePersonForLoanApplication {
  IsEmployeeAndFranchiseManager = 'NOTA: Es encargado de franquicia y es empleado',
  IsFranchiseManager = 'NOTA: La persona seleccionada está dada de alta como encargado de una franquicia',
  IsEmployee = 'NOTA: La persona seleccionada está dada de alta como empleado',
}

export enum Role {
  Franchise_Holder = 'Titular de Franquicia'
}