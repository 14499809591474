import React from 'react';
import { Create, ListButton, TopToolbar, useNotify } from 'react-admin';
import FranchiseForm from './FranchiseForm';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { errors, thereWasProblem } from '../../Utils/messages';

const FranchiseActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const FranchiseCreate = (props) => {
  const notify = useNotify();

  const onFailure = (error) => {
    const code = error.body.data.errorCode;

    notify(
      `${thereWasProblem}: ${
        errors[code] || 'Hubo un error con su solicitud, inténtelo mas tarde...'
      }`,
      'warning'
    );
  };

  return (
    <Create
      actions={<FranchiseActions {...props} />}
      onFailure={onFailure}
      {...props}
    >
      <FranchiseForm />
    </Create>
  );
};

export default FranchiseCreate;
