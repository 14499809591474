import React from "react";
import { ChevronLeft } from "@material-ui/icons";
import { Create, ListButton, TopToolbar } from "react-admin";
import RoleStateForm from "./RoleStateForm";


const RoleStateActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const RoleStateCreate = (props: any) => {
  return (
    <Create actions={<RoleStateActions {...props} />} {...props}>
      <RoleStateForm {...props} />
    </Create>
  );
};

export default RoleStateCreate;