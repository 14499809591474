import React from 'react';
import { Bold, Italic, Underline, Code, Menu, List } from 'react-feather';

// CONSTANTS VALUES:
export const defaultInlineStyles = [
  { type: 'BOLD', label: <Bold size={15} />, toolTip: 'Bold' },
  { type: 'ITALIC', label: <Italic size={15} />, toolTip: 'Italic' },
  { type: 'UNDERLINE', label: <Underline size={15} />, toolTip: 'Underline' },
  { type: 'CODE', label: <Code size={15} />, toolTip: 'Code Block' },
];

export const defaultBlockStyles = [
  {
    type: 'ordered-list-item',
    label: <Menu size={15} />,
    toolTip: 'Ordered List',
  },
  {
    type: 'unordered-list-item',
    label: <List size={15} />,
    toolTip: 'Unordered List',
  },
];

export const defaultHeaderStyles = [
  { type: 'paragraph', label: 'Normal', toolTip: 'Normal' },
  { type: 'header-one', label: 'Heading 1', toolTip: 'Heading 1' },
  { type: 'header-two', label: 'Heading 2', toolTip: 'Heading 2' },
  { type: 'header-three', label: 'Heading 3', toolTip: 'Heading 3' },
  { type: 'header-four', label: 'Heading 4', toolTip: 'Heading 4' },
];
