export const usersPermissionsRolesConfig = {
  'application': [
    {
      'name': 'person',
      'resource': 'roles-catalog',
      'permissions': [
        {
          'controller': 'person',
          'actions': [
            'find',
            'create',
            'update',
            'assignagent',
            'unassignagent',
            'requestciec',
          ]
        },
        // {
        //   'controller': 'reference',
        //   'actions': [
        //     'find',
        //     'create'
        //   ]
        // },
        // {
        //   'controller': 'related',
        //   'actions': [
        //     'find',
        //     'create'
        //   ]
        // },
        // {
        //   'controller': 'income',
        //   'actions': [
        //     'find',
        //     'create'
        //   ]
        // },
        // {
        //   'controller': 'asset',
        //   'actions': [
        //     'find',
        //     'create'
        //   ]
        // },
      ]
    },
    {
      'name': 'legal-people',
      'resource': 'roles-catalog',
      'permissions': [
        {
          'controller': 'legal-people',
          'actions': [
            'find',
            'create',
            'update',
            'assignagent',
            'unassignagent',
            'requestciec',
          ]
        },
      ]
    },
    {
      'name': 'client',
      'resource': 'roles-catalog',
      'permissions': [
        {
          'controller': 'reference',
          'actions': [
            'find',
            'create'
          ]
        },
        {
          'controller': 'related',
          'actions': [
            'find',
            'create'
          ]
        },
        {
          'controller': 'income',
          'actions': [
            'find',
            'create'
          ]
        },
        {
          'controller': 'asset',
          'actions': [
            'find',
            'create'
          ]
        },
      ]
    },
    {
      'name': 'blacklist',
      'resource': 'roles-catalog',
      'permissions': [
        {
          'controller': 'blacklist',
          'actions': [
            'find',
          ]
        },
      ]
    },
    {
      'name': 'loan-application',
      'resource': 'roles-catalog',
      'permissions': [
        {
          'controller': 'loan-application',
          'actions': [
            'find',
            'create',
            'update',
            'statechange',
            'assignsubstate',
            'assignreferrer',
          ]
        },
        {
          'controller': 'contact-history',
          'actions': [
            'find',
            'create',
          ]
        },
        {
          'controller': 'guarantee',
          'actions': [
            'find',
            'create',
          ]
        },
        {
          'controller': 'document',
          'actions': [
            'find',
            'create',
            'update',
            'veredicto',
          ]
        },
        {
          'controller': 'rating',
          'actions': [
            'find',
            'calculate',
            'save',
          ]
        },
        {
          'controller': 'amortization-table',
          'actions': [
            'getloansimulation',
            'generateloanprojectionpdf',
          ]
        },
        {
          'controller': 'loan-references',
          'actions': [
            'find',
            'create',
            'update',
            'delete',
          ]
        },
      ]
    },
    {
      'name': 'franchise',
      'resource': 'roles-catalog',
      'permissions': [
        {
          'controller': 'franchise',
          'actions': [
            'find',
            'create',
            'update',
            'findall',
          ]
        },
        {
          'controller': 'assign-franchise',
          'actions': [
            'assign',
            'unassign'
          ]
        },
      ]
    },
    {
      'name': 'employee',
      'resource': 'roles-catalog',
      'permissions': [
        {
          'controller': 'employee',
          'actions': [
            'find',
            'create',
            'update',
            'delete',
          ]
        },
      ]
    },
    {
      'name': 'state-rule',
      'resource': 'roles-catalog',
      'permissions': [
        {
          'controller': 'state-rule',
          'actions': [
            'find',
            'create',
            'update',
          ]
        },
      ]
    },
  ],
  'users-permissions': [
    {
      'name': 'user',
      'resource': 'roles-catalog',
      'permissions': [
        {
          'controller': 'user',
          'actions': [
            'find',
            'update',
          ]
        },
        {
          'controller': 'auth',
          'actions': [
            'forgotpassword'
          ]
        },
      ]
    },
  ]
};
