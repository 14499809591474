import React from 'react';
import { Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    padding: '4px 10px 4px 10px',
    width: '100%',
  },
}));

const ImageUploadButton = ({ setEvent }) => {
  const classes = useStyles();

  const handleClick = (event) => {
    setEvent(event);
  };

  return (
    <Button
      color='secondary'
      variant='contained'
      startIcon={<CloudUploadIcon />}
    >
      <label className={classes.root} htmlFor='document'>
        Subir imágen
      </label>
      <input
        hidden
        id='document'
        name='document'
        onChange={handleClick}
        title='Imagen'
        type='file'
        multiple
      />
    </Button>
  );
};

export default ImageUploadButton;
