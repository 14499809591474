import React, { useRef, useState, useMemo, useCallback } from 'react';
import {
  EditorState,
  convertToRaw,
  CompositeDecorator,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import createMentionPlugin, {
  MentionData,
  defaultSuggestionsFilter,
  defaultTheme,
} from '@draft-js-plugins/mention';
import mentionStyles from './MentionsStyles.module.css';
import mentionStylesDark from './MentionsStyles-dark.module.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import { useTranslate } from 'ra-core';
import { convertToHTML } from 'draft-convert';
import ImageDecorator from './DraftTextInputDecorator/ImageDecorator';
import LinkDecorator, {
  linkDecoratorConfig,
} from './DraftTextInputDecorator/LinkDecorator';
import { getErrors, getSimpleText } from './DraftTextInputUtils';
import DraftTextInputToolbar from './DraftTextInputToolbar/DraftTextInputToolbar';
import { Theme } from '../../Theme';
import { useTheme } from '@material-ui/core';

const convertValueToEditor = (editorStateValue, decorator) => {
  const blocksFromHTML = convertFromHTML(editorStateValue);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(contentState, decorator);
};

const imagePlugin = createImagePlugin();

//REFS
const classNameEditor = 'editor';

// DEFAULT.
const defaultSuggestionFilter = (suggestion, value) =>
  suggestion.name?.toLowerCase() === value?.toLowerCase() ? suggestion : null;

export interface MentionDataDraft extends MentionData {}

const DraftTextInput = ({
  isRequired = false,
  onChange: onChangeValue,
  suggestionsList,
  touched,
  setTouched,
  defaultValue = '',
  suggestionFilter = defaultSuggestionFilter,
  ...props
}) => {
  const theme: Theme = useTheme();
  const { MentionSuggestions, plugins } = useMemo(() => {
    const styles = theme.palette.type === 'light' ? mentionStyles : mentionStylesDark;
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      supportWhitespace: true,
      theme: { ...defaultTheme, ...styles },
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin, imagePlugin];
    return { plugins, MentionSuggestions };
  }, []);
  const decorator = useMemo(() => {
    return new CompositeDecorator([linkDecoratorConfig]);
  }, []);

  const [editorState, setEditorState] = useState(
    convertValueToEditor(defaultValue, decorator)
  );
  const [suggestions, setSuggestions] = useState([] as MentionDataDraft[]);
  const [errors, setErrors] = useState({} as any);
  const [open, setOpen] = useState(false);
  const t = useTranslate();
  const refs = useRef(null);

  // EDITOR EVENTS.
  const onChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const newValue = extractValueWithDecorators(contentState);
    const rawObject = convertToRaw(contentState);
    setErrors(getErrors(getSimpleText(rawObject), errors));
    const mentions = extractMentions(editorState);
    if (onChangeValue) onChangeValue(newValue, mentions, errors);
  };
  const onBlur = () => setTouched(true);
  const focusEditor = () => (refs?.current as any)?.focus();
  // MENTIONS EVENT.
  const onSearchChange = ({ value }) => {
    if (value) {
      const newSuggestions = suggestionsList.filter((suggestion) =>
        suggestionFilter(suggestion, value)
      );
      setSuggestions(defaultSuggestionsFilter(value, newSuggestions));
      return;
    }
    setSuggestions(defaultSuggestionsFilter(value, suggestionsList));
  };
  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const handlePastedFiles = (files: Array<File>) => {
    if (!files.length) return;
    if (!files[0].type.includes('image')) return;

    const reader = new FileReader();
    const file = files[0];

    reader.onload = () => {
      const dataURL = reader.result;
      const editorStateWithImage = imagePlugin.addImage(editorState, dataURL);
      onChange(editorStateWithImage);
    };

    reader.readAsDataURL(file);
  };

  // CONVERTS.
  const extractMentions = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    const mentionedUsers: any[] = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === 'mention') {
        mentionedUsers.push(ent.data.mention);
      }
    }
    return mentionedUsers;
  };
  const extractValueWithDecorators = (contentState) => {
    const decoratedHtml = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK')
          return (
            <LinkDecorator href={entity.data.url}>{originalText}</LinkDecorator>
          );
        if (entity.type === 'IMAGE')
          return (
            <ImageDecorator src={entity.data.src}>
              {originalText}
            </ImageDecorator>
          );
        return originalText;
      },
    })(contentState);
    return decoratedHtml;
  };

  return (
    <div className='draft-editor' style={styles.draftContainer as any}>
      <div className='control-panel'>
        <DraftTextInputToolbar
          editorState={editorState}
          setEditorState={setEditorState}
        />
      </div>
      <div
        className={classNameEditor}
        style={styles.editorContent as any}
        onClick={focusEditor}
      >
        <Editor
          {...props}
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          onBlur={onBlur}
          decorators={[linkDecoratorConfig]}
          customStyleMap={styles.styleMap}
          handlePastedFiles={handlePastedFiles}
          ref={refs}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          onSearchChange={onSearchChange}
          suggestions={suggestions}
          id={props.id}
          key={props.key}
        />
      </div>
      {touched && errors.required && (
        <span
          style={{ display: 'block', marginBottom: '1em' }}
          className='MuiFormHelperText-root ra-rich-text-input-error Mui-error MuiFormHelperText-marginDense'
        >
          {t('ra.validation.required')}
        </span>
      )}
    </div>
  );
};

const styles = {
  draftContainer: {
    margin: '0',
    marginBottom: '1.5rem',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'borderBox',
    fontFamily: 'Helvetica, Arial, sansSerif',
  },
  editorContent: {
    minHeight: '140px',
    height: '100%',
    outline: 'none',
    padding: '12px 15px',
    tabSize: '4',
    wordWrap: 'breakWord',
    boxSizing: 'borderBox',
    overflowY: 'auto',
    textAlign: 'left',
    lineHeight: '1.42',
    whiteSpace: 'preWrap',
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
    borderBottom: '#e6e5e8 solid 1px',
    cursor: 'text',
  },
  styleMap: {
    CODE: {
      backgroundColor: 'rgba(200, 200, 200, 0.5)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: '0.9rem',
      padding: 2,
    },
  },
};

export default DraftTextInput;
