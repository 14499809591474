import * as React from 'react';
import {
  ArrayField,
  Datagrid,
  TabbedShowLayout,
  Tab,
  TextField,
  useTranslate,
} from 'react-admin';

import { FormShow } from '../../styles';
import TablePlaceholder from '../../Components/TablePlaceholder';

export const EmployeeShow = () => {
  const classes = FormShow();
  const translate = useTranslate();

  return (
    <TabbedShowLayout className={classes.borderBottom}>
      <Tab label='Contactos' contentClassName={classes.flexParent}>
        <ArrayField
          fieldKey='id'
          addLabel={false}
          label=''
          source='contacts'
          className={classes.width100}
        >
          <Datagrid empty={<TablePlaceholder title='Contactos' />}>
            <TextField
              source='contactType.name'
              emptyText='-'
              label={translate('resources.contacts.fields.contactType')}
            />
            <TextField
              source='contact'
              emptyText='-'
              label={translate('resources.contacts.fields.contact')}
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  );
};

export default EmployeeShow;
