import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import Svg403 from './assets/icons/403.svg';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    width: '50%;',
  },
  title: {
    margin: '4rem auto',
    textAlign: 'center',
  },
}));

export const ForbiddenPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img width='100%' src={Svg403} alt='Página restringida' />
      <div className={classes.title}>
        <Typography gutterBottom variant='h2'>
          Lo sentimos, pero no tienes acceso para ejecutar esta acción o para
          acceder a esta página.
        </Typography>
      </div>
    </div>
  );
};
