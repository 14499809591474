import React from "react";
import { AutocompleteInput, FormWithRedirect, NumberInput, ReferenceInput, required, SaveButton, SelectInput, TextInput } from 'react-admin';
import { Box, Toolbar, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { canShowSaveButton } from '../../Utils/MaintenancePersmissionsUtils';
import { getUserFromLS } from "../../Utils/functions";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      marginBottom: '10px',
      '& > p': {
        display: 'none',
      },
    },
  })
);

interface ApplicationTypeSettingInterface {
  props?: object;
}

const isRequired = [required()];

export const ApplicationTypeSettingForm: React.FC<ApplicationTypeSettingInterface> = (
  props
) => {
  const classes = useStyles();
  const user = getUserFromLS();
  const { company } = user;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        return (
          <>
            <Grid container spacing={2} classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  General
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ReferenceInput
                      source={formProps.record?.id ? 'application_type.id' : 'application_type'}
                      label='Tipo de solicitud'
                      resource='application-type-settings'
                      reference='application-types'
                      disabled={formProps.record?.id}
                      fullWidth
                      validate={isRequired}
                      variant='outlined'
                      
                    >
                      <SelectInput
                        optionText={(record) => record && `${record.name}`}
                        optionValue='id'
                      />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={12}>
                    <ReferenceInput
                      source={formProps.record?.id ? 'setting_type.id' : 'setting_type'}
                      label='Tipo de configuración'
                      resource='application-type-settings'
                      reference='setting-types'
                      disabled={formProps.record?.id}
                      fullWidth
                      validate={isRequired}
                      variant='outlined'
                      
                    >
                      <SelectInput
                        optionText={(record) => record && `${record.name}`}
                        optionValue='id'
                      />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={12}>
                    <NumberInput
                      fullWidth
                      source='value'
                      label='Valor'
                      resource='application-type-settings'
                      validate={isRequired}
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            { canShowSaveButton(props) && 
              <Toolbar>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <SaveButton
                    saving={formProps.saving}
                    disabled={formProps.invalid}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    redirect='list'
                  />
                </Box>
              </Toolbar>
            }
          </>
        );
      }}
    />
  );
};

export default ApplicationTypeSettingForm;
