import AssetsCreate from './AssetsCreate';
import AssetsEdit from './AssetsEdit';
import AssetsShow from './AssetsShow';

const assets = {
  create: AssetsCreate,
  edit: AssetsEdit,
  show: AssetsShow,
};

export default assets;
