import { useState, useEffect } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';

const useSelectDataProvider = (resource, filter = {}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [selectData, setSelectData] = useState([]);
  const refresh = useRefresh();

  useEffect(() => {
    dataProvider
      .getList(resource, {
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'id', order: 'ASC' },
        filter,
      })
      .then((rest: any) => {
        if (rest?.data) {
          setSelectData(rest?.data);
        } else {
          refresh();
        }
      })
      .catch((error) => {
        notify(`useSelectDataProvider[${resource}]: ${error.message}`, 'error');
      });
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource]);

  return selectData;
};

export default useSelectDataProvider;
