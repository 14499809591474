import React from 'react';

const ImageDecorator = ({ src, children }) => {
  return (
    <>
      <img alt='' src={src} />
      {children}
    </>
  );
};

export default ImageDecorator;
