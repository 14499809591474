import React, { useState } from 'react';
import { SaveButton, useNotify, useRefresh } from 'react-admin';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import { Save } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: theme.spacing(1, 3),
  },
}));

interface EditI {
  record?: any;
}

const SaveButtonLoanStates = (formProps: any) => (<SaveButton
  saving={formProps.saving}
  disabled={formProps.invalid}
  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
  redirect='list'
  label='Aceptar'
/>);

const LoanApplicationStateSaveButton = ({ record, ...formProps }: EditI & any) => {
  const { form } = formProps;
  const formValues = form?.getState().values;
  const notify = useNotify();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();

  const { default: defaultOriginal } = record;
  const { default: defaultNew } = formValues;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if ( !(!defaultOriginal && defaultNew) ) return <SaveButtonLoanStates {...formProps}/>;

  return (
    <div>
      <Button
        size='medium'
        color='primary'
        variant = 'contained'
        onClick={handleClickOpen} >
          {formProps.saving ? (
                <CircularProgress
                    size={18}
                    thickness={2}
                />
            ) : (
              <Save style={{fontSize:'18px', marginRight:'8px'}} />
            )}
            Aceptar
      </Button>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
      >
        <MuiDialogTitle id='form-dialog-title' disableTypography>
          <Typography variant='h4'>Confirmar</Typography>
        </MuiDialogTitle>
        <DialogContent>
         <Typography variant='h6'>Desea que el Estado por Default se reemplace con este nuevo Estado?</Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={() => handleClose()}
            color='primary'
          >
            Cancelar
          </Button>
          <SaveButtonLoanStates {...formProps}/>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoanApplicationStateSaveButton;
