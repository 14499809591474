import React from "react";
import { Box, Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
// import { required } from "ra-core/src/form/validate";
import { AutocompleteInput, FormWithRedirect, ReferenceInput, SaveButton, TextInput, Toolbar, required, useNotify, useRedirect, useRefresh } from 'react-admin'
import { useState } from 'react';
import { canShowSaveButton } from "../../Utils/MaintenancePersmissionsUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  })
);

const isRequired = [required()];

export const SegmentRoleForm: React.FC<any> = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState<any>();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { company } = user;


  // const notify = useNotify();
  // const redirect = useRedirect();
  // const refresh = useRefresh();
  // const [loading, setLoading] = useState<boolean>();
  // const [event, setEvent] = useState<any>();

  return (
    <FormWithRedirect
      { ...props }
      render={(formProps) => {
        if (!values) setValues(formProps.record);
        return (
          <>
            <Grid container spacing={2} classes={{ root: classes.root }}>
                <Grid item xs={12}>
                  <Typography variant='h4' gutterBottom>
                    General
                  </Typography>
                  <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <ReferenceInput
                          source={formProps.record?.id ? 'defaultRole.id' : 'defaultRole'}
                          label='Rol'
                          resource='segment_roles'
                          reference='users-permissions/roles'
                          disabled={!company}
                          fullWidth
                          validate={isRequired}
                          variant='outlined'
                          
                        >
                          <AutocompleteInput
                            optionText={(record) => record && `${record.name}`}
                            optionValue='id'
                          />
                        </ReferenceInput>
                        <TextInput
                          fullWidth
                          source='description'
                          label='Descripción'
                          resource='segment_roles'
                          // validate={isRequired}
                          disabled={(!company || !!formProps.record)}
                          variant='outlined'
                        />
                      </Grid>
                  </Grid>
                </Grid>
            </Grid>
            { canShowSaveButton(props) && 
              <Toolbar>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <SaveButton
                    saving={formProps.saving}
                    disabled={formProps.invalid}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    redirect='list'
                  />
                </Box>
              </Toolbar>
            }
          </>
        );
      }}
    >
    </FormWithRedirect>
  );
}

export default SegmentRoleForm;