import CastForEducationIcon from '@material-ui/icons/CastForEducation';

import ProfessionList from './ProfessionList';

export const Profession: object = {
  list: ProfessionList,
  icon: CastForEducationIcon,
};

export default Profession;
