import React from 'react';
import {
  useTranslate,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  TopToolbar,
  ListButton,
  EditButton,
  ReferenceField,
  Datagrid,
  ArrayField,
  NumberField,
  FunctionField,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { FormShow } from '../../styles';
import FormTitle from '../../Components/FormTitle';
import TablePlaceholder from '../../Components/TablePlaceholder';
import CustomDateInput from '../../Components/CustomDateInput';
import { Timeline } from '../../Components/Timeline';

const IncomeShowActions = ({ basePath, data }: any) => {
  return (
    <TopToolbar>
      <ListButton
        to={
          data?.person
            ? `/people/${data?.person}/show/incomes`
            : `/legal-people/${data?.legalPerson}/show/incomes`
        }
        label='ra.action.back'
        icon={<ChevronLeft />}
      />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const IncomeShow = ({ ...props }) => {
  const classes = FormShow();
  const translate = useTranslate();

  return (
    <Show
      {...props}
      actions={<IncomeShowActions {...props} />}
      aside={<Timeline segment='income' />}
    >
      <TabbedShowLayout className={classes.borderBottom}>
        <Tab label='General' contentClassName={classes.flexParent}>
          <FormTitle title='Información ingreso' className={classes.width100} />
          <ReferenceField
            source='incomeType'
            resource='people'
            reference='income-types'
            className={classes.width25}
            link={false}
            emptyText='-'
            label={translate('resources.incomes.fields.incomeType')}
          >
            <TextField source='name' />
          </ReferenceField>
          <TextField
            source='companyName'
            emptyText='-'
            label={translate('resources.incomes.fields.companyName')}
            className={classes.width25}
          />
          <TextField
            source='position'
            emptyText='-'
            label={translate('resources.incomes.fields.position')}
            className={classes.width25}
          />
          <NumberField
            source='amount'
            emptyText='-'
            label={translate('resources.incomes.fields.incomes')}
            options={{ style: 'currency', currency: 'MXN' }}
            className={classes.width25}
          />
          <TextField
            source='supervisor'
            emptyText='-'
            label={translate('resources.incomes.fields.supervisor')}
            className={classes.width25}
            fullWidth
          />
          <FunctionField
            source='effectiveDate'
            className={classes.width25}
            render={() => <CustomDateInput source='effectiveDate' />}
          />
          <FunctionField
            source='expirationDate'
            className={classes.width25}
            render={() => <CustomDateInput source='expirationDate' />}
          />
          <FormTitle title='Contactos' className={classes.width100} />
          <ArrayField
            addLabel={false}
            source='contacts'
            fieldKey='id'
            className={classes.width100}
          >
            <Datagrid empty={<TablePlaceholder title='Contactos' />}>
              <TextField
                emptyText='-'
                source='contactType.name'
                label={translate('resources.contacts.fields.contactType')}
              />
              <TextField
                emptyText='-'
                source='contact'
                label={translate('resources.contacts.fields.contact')}
              />
            </Datagrid>
          </ArrayField>
          <FormTitle title='Direcciones' className={classes.width100} />
          <ArrayField
            addLabel={false}
            source='addresses'
            fieldKey='id'
            className={classes.width100}
          >
            <Datagrid empty={<TablePlaceholder title='Direcciones' />}>
              <TextField
                emptyText='-'
                source='addressType.name'
                label={translate('resources.addresses.fields.addressType')}
              />
              <TextField
                emptyText='-'
                source='country.name'
                label={translate('resources.addresses.fields.country')}
              />
              <TextField
                emptyText='-'
                source='province.name'
                label={translate('resources.addresses.fields.province')}
              />
              <TextField
                emptyText='-'
                source='municipality.name'
                label={translate('resources.addresses.fields.municipality')}
              />
              <TextField
                emptyText='-'
                source='neighborhood.name'
                label={translate('resources.addresses.fields.neighborhood')}
              />
              <TextField
                emptyText='-'
                source='city.name'
                label={translate('resources.addresses.fields.city')}
              />
              <TextField
                emptyText='-'
                source='street'
                label={translate('resources.addresses.fields.street')}
              />
              <TextField
                emptyText='-'
                source='outsideNumber'
                label={translate('resources.addresses.fields.outsideNumber')}
              />
              <TextField
                emptyText='-'
                source='postalCode'
                label={translate('resources.addresses.fields.postalCode')}
              />
            </Datagrid>
          </ArrayField>
          <FormTitle
            title='Contactos del supervisor'
            className={classes.width100}
          />
          <ArrayField
            addLabel={false}
            source='supervisorContacts'
            fieldKey='id'
            className={classes.width100}
          >
            <Datagrid empty={<TablePlaceholder title='Supervisor contacts' />}>
              <TextField emptyText='-' source='contact' />
              <TextField
                emptyText='-'
                source='contactType.name'
                label={translate('resources.contacts.fields.contactType')}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default IncomeShow;
