import React from 'react';
import {
  Create,
  ListButton,
  TopToolbar,
  TabbedForm,
  FormTab,
} from 'react-admin';
import { parse } from 'query-string';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AssetsForm from './AssetsForm';

const AssetsActions = ({ person, legalPerson }) => (
  <TopToolbar>
    <ListButton
      to={
        person
          ? `/people/${person}/show/assets`
          : `/legal-people/${legalPerson}/show/assets`
      }
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const AssetsCreate = (props) => {
  const { person: personString, 'legal-person': legalPersonString } = parse(
    props.location.search
  );
  const person = personString ? parseInt(personString, 10) : '';
  const legalPerson = legalPersonString ? parseInt(legalPersonString, 10) : '';

  return (
    <Create
      actions={<AssetsActions person={person} legalPerson={legalPerson} />}
      title=''
      {...props}
    >
      <TabbedForm
        initialValues={{ person, legalPerson }}
        redirect={
          person
            ? `/people/${person}/show/assets`
            : `/legal-people/${legalPerson}/show/assets`
        }
        variant='outlined'
      >
        <FormTab label='Bien'>
          <AssetsForm />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default AssetsCreate;
