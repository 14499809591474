import { Create, ListButton, TopToolbar } from 'react-admin';

import ChevronLeft from '@material-ui/icons/ChevronLeft';

import BankForm from './BankForm';

const BankActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const BankCreate = (props: any) => {
  return (
    <Create actions={<BankActions {...props} />} {...props}>
      <BankForm {...props} />
    </Create>
  );
};

export default BankCreate;
