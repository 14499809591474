import React from 'react';
import {
  ArrayInput,
  BooleanInput,
    FormWithRedirect,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    SimpleFormIterator,
    TextInput,
  } from 'react-admin';
  import { Box, Toolbar, Grid } from '@material-ui/core';
  import { makeStyles, createStyles } from '@material-ui/core/styles';
  import Typography from '@material-ui/core/Typography';
  import { usePermissions } from 'react-admin';
import { canShowSaveButton } from '../../Utils/MaintenancePersmissionsUtils';
import LoanApplicationStateSaveButton from './LoanApplicationStateSaveButton';

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        padding: theme.spacing(2),
      },
      paper: {
        padding: theme.spacing(2),
        boxShadow: 'none',
        color: theme.palette.text.secondary,
      },
      width100: {
        width: '100%',
        fontSize: '15px',
        marginBottom: '10px',
        '& > p': {
          display: 'none',
        },
      },
    })
  );
  
  interface BankInterface {
    props?: object;
  }
  
  const isRequired = [required()];
  export const LoanApplicationStateForm: React.FC<BankInterface> = (props) => {
    const classes = useStyles();
    const { permissions } = usePermissions();
  
    return (
      <FormWithRedirect
        {...props}
        render={(formProps) => {
          return (
            <>
              <Grid container spacing={2} classes={{ root: classes.root }}>
                <Grid item xs={12}>
                  <Typography variant='h4' gutterBottom>
                    General
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextInput
                        fullWidth
                        source='name'
                        resource='loan-application-state'
                        validate={isRequired}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BooleanInput
                        source='restricted'
                        resource='loan-application-state'
                        label="¿Es restringido?"
                        defaultValue={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BooleanInput
                        source='default'
                        resource='loan-application-state'
                        label="¿Es default?"
                        defaultValue={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BooleanInput
                        source='loanApplicationActive'
                        resource='loan-application-state'
                        label="¿Solicitud activa?"
                        defaultValue={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h5' gutterBottom>
                        Elegir Estatus Futuros
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ArrayInput source='permittedStates' label="Estatus futuros">
                        <SimpleFormIterator>
                          <ReferenceInput
                            source={formProps.record?.id ? 'id' : ''}
                            reference='loan-application-states'
                            validate={required()}
                            variant='outlined'
                            label=''
                            fullWidth
                            filter={{
                              id_ne: [formProps.record?.id]
                            }}
                          >
                            <SelectInput resource='state' />
                          </ReferenceInput>
                        </SimpleFormIterator>
                      </ArrayInput>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              { canShowSaveButton(props) &&
                <Toolbar>
                  <Box display='flex' justifyContent='space-between' width='100%'>
                    <LoanApplicationStateSaveButton {...formProps}/>
                  </Box>
                </Toolbar>
              }
            </>
          );
        }}
      />
    );
  };
  
  export default LoanApplicationStateForm;