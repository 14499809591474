import GavelIcon from '@material-ui/icons/Gavel';

import NotaryList from './NotaryList';

export const Notary: object = {
  list: NotaryList,
  icon: GavelIcon,
};

export default Notary;
