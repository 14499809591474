import React from 'react';
import {
  ArrayInput,
  ReferenceInput,
  required,
  FormDataConsumer,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { PersonTypeAlias } from '../Utils/enums';
import { findDuplicateContactWithMessage } from '../Utils/PhysicalPerson';

const isRequired = [required()];

const Contacts = ({
  className = '',
  isValidated = false,
  source = 'contacts',
  hasDescription = false,
  columns = hasDescription ? 4 : 6,
  ...rest
}) => {
  const translate = useTranslate();
  const typePerson = rest['typePerson'];
  return (
    <ArrayInput
      source={source}
      validation={isValidated ? isRequired : false}
      label=''
      disableAdd={true}
      {...rest}
    >
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ scopedFormData, getSource, formData, ...rest }) => {
            const validateContact = (values: any) => {
              if (typePerson !== PersonTypeAlias.Physical) {
                return undefined;
              }
              const messageForDuplicate =
                findDuplicateContactWithMessage(
                  rest['id'],
                  formData.contacts,
                  scopedFormData
                )[rest['id']]?.message ?? '';
              if (messageForDuplicate) {
                return 'Contacto duplicado';
              }
              return undefined;
            };
            return (
              <Grid container spacing={2}>
                <Grid item xs={columns}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'contactType.id' : 'contactType'
                    )}
                    reference='contact-types'
                    validate={isRequired}
                    variant='outlined'
                    fullWidth
                    label={translate('resources.contacts.fields.contactType')}
                  >
                    <SelectInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={columns}>
                  <TextInput
                    source={getSource('contact')}
                    variant='outlined'
                    validate={[...isRequired, validateContact]}
                    fullWidth
                    label={translate('resources.contacts.fields.contact')}
                  />
                </Grid>
                {hasDescription && (
                  <Grid item xs={columns}>
                    <TextInput
                      source={getSource('description')}
                      variant='outlined'
                      fullWidth
                      label={translate('resources.contacts.fields.description')}
                    />
                  </Grid>
                )}
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default Contacts;
