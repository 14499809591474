import { makeStyles } from "@material-ui/core";
import React, { Fragment, useCallback } from "react";
import { BooleanField, Datagrid, Filter, TextField, TextInput } from "react-admin";
import { Route, useHistory } from 'react-router-dom';
import CustomList from "../../Components/CustomList";
import ListActions from "../../Components/ListActions";
import TablePlaceholder from "../../Components/TablePlaceholder";
import { Drawer } from '@material-ui/core';
import ContactHistoryTypeCreate from "./ContactHistoryTypeCreate";
import ContactHistoryTypeEdit from "./ContactHistoryTypeEdit";

const ENTITY_NAME = 'contact-history-type';

const useStyles = makeStyles(() => ({
  drawerContent: { width: '50vw', padding: '0 1rem' },
}));

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput label="ra.action.search" source="name" alwaysOn />
  </Filter>
);
const ContactHistoryTypeList = ({ permissions, hasCreate, ...props }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/contact-history-types');
  }, [history]);

  return (
    <Fragment>
      <CustomList
        {...props}
        filters={<Filters />}
        actions={
          <ListActions
            hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
            isMaintenance
            entity={ ENTITY_NAME }
            permissions={ permissions }
            { ...props }
          />
        }
      >
        <Datagrid
          rowClick='edit'
          empty={<TablePlaceholder title='Tipo de notas' />}
        >
          <TextField source='name' label="Nombre" />
          <BooleanField source='isVisible' label="Visible" />
        </Datagrid>

      </CustomList>
      <Route path='/contact-history-types/create'>
        {({ match }) => (
          <Drawer open={!!match} anchor='right' onClose={handleClose}>
            <ContactHistoryTypeCreate
              className={classes.drawerContent}
              onCancel={handleClose}
              {...props}
              entity={ENTITY_NAME}
              hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
            />
          </Drawer>
        )}
      </Route>
      <Route path='/contact-history-types/:id'>
        {({match}) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          );

          return (
            <Drawer
            open={isMatch} 
            anchor='right' 
            onClose={handleClose}
            >
              {isMatch ? (
                <ContactHistoryTypeEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                  entity={ENTITY_NAME}
                  hasEdit={permissions?.[ENTITY_NAME]?.update?.enabled}
                />
              ): (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default ContactHistoryTypeList;
