export const APP_VERSION = '1.0.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};


export const CONF_ENABLE_CREDIT_BUREAU =  (String(process.env.REACT_APP_ENABLE_CREDIT_BUREAU).toLowerCase() === 'true') || false;