import { isUserAdmin } from './functions'
export const hasAccessToMaintenanceScreen = ( ) => {
  const isAdmin = isUserAdmin();
  return  isAdmin;
};

export const hasAccessToPersonMaintenanceSection = ( permissions: any[] = [] ) => {
  return [
    permissions?.['academic-level']?.find.enabled, 
    permissions?.['profession']?.find.enabled, 
    permissions?.['position']?.find.enabled, 
    permissions?.['contact-type']?.find.enabled, 
    permissions?.['marital-status']?.find.enabled, 
    permissions?.['related-types']?.find.enabled, 
    permissions?.['document-type']?.find.enabled, 
    permissions?.['economic-activity']?.find.enabled, 
    permissions?.['income-type']?.find.enabled, 
    permissions?.['asset-type']?.find.enabled, 
    permissions?.['reference-type']?.find.enabled, 
  ].includes(true);
};

export const hasAccessToLoanAppMaintenanceSection = ( permissions: any[] = [] ) => {
  return [
    permissions?.['application-reason']?.find.enabled,
    permissions?.['application-source']?.find.enabled,
    permissions?.['application-type']?.find.enabled,
    permissions?.['client-source']?.find.enabled,
    permissions?.['evaluation-unit']?.find.enabled,
    permissions?.['notary']?.find.enabled,
    permissions?.['loan-application-state']?.find.enabled,
    permissions?.['substates']?.find.enabled, 
    permissions?.['application_type_settings']?.find.enabled, 
  ].includes(true);
};

export const hasAccessToOthersMaintenanceSection = ( permissions: any[] = [] ) => {
  return [
    permissions?.['bank']?.find.enabled, 
    permissions?.['department']?.find.enabled, 
    permissions?.['position']?.find.enabled, 
    permissions?.['address-type']?.find.enabled, 
    permissions?.['promotion']?.find.enabled, 
  ].includes(true);
};

export const canShowSaveButton = ( props: any ) => {
  // {record: any, hasEdit: boolean, hasCreate: boolean}
  const {record, hasEdit, hasCreate} = (props as any) || {};
  const isUpdate = !!(record?.id);
  return ((isUpdate && hasEdit) || (!isUpdate && hasCreate));
};

export const canShowMaintenanceOption = ( section: string, permissions: [] = [] ) => {
  if ( !section || permissions.length < 1 ) return false;
  const sectionPermissions = permissions?.[`${section}`] || {};
  return  sectionPermissions?.create?.enabled || 
    sectionPermissions?.delete?.enabled || 
    sectionPermissions?.update?.enabled
  ;
};
