import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import Cookies from '../../Utils/cookies';
import ShowHeader from './Components/ShowHeader';
import PromotionModal from '../Promotions/PromotionModal';

const Dashboard: FC = () => {
  const [data, setData] = useState<any>();
  const getDataDashboard = async (zone?: string) => {
    const token = Cookies.getCookie('token');
    const data = await fetch(
      zone
        ? `${process.env.REACT_APP_API_URL}/dashboard?department=${zone}`
        : `${process.env.REACT_APP_API_URL}/dashboard`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((rest) => rest.json());
    if (!data.error) setData(data);
  };

  useEffect(() => {
    // getDataDashboard();
  }, []);

  return (
    <>
      <PromotionModal />
      <ShowHeader />
    </>
  );
};

export default Dashboard;
