import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {
  Button,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRefresh,
  useMutation,
} from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../../Theme';
import { thereWasProblem } from '../../../Utils/messages';
import { ReferenceState } from '../../../Utils/enums';
interface AssignStateLoanReferenceProps {
  loanApplication: any;
  loanReference: any;
  openDialog: boolean;
  handleClickOpenClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingInline: theme.spacing(0.5),
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
  },
  content: { '& > *': { padding: '0 !important' } },
  buttonSpacing: {
    margin: '0rem 0rem 1rem 0rem;',
  },
}));

const statesReference = () => [
  { id: ReferenceState.PendingCall, name: 'Volver a llamar' },
  { id: ReferenceState.NotContacted, name: 'No contactado' },
  { id: ReferenceState.Contacted, name: 'Contactado' },
];

export const AssignStateLoanReference = ({
  loanApplication,
  loanReference,
  openDialog,
  handleClickOpenClose,
}: AssignStateLoanReferenceProps) => {
  const [loanReferenceId, setLoanReferenceId] = useState<any>();
  const [stateReference, setStateReference] = useState<any>();
  const [comment, setComment] = useState('');

  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  useEffect(() => {
    setLoanReferenceId(loanReference?.id);
  }, [loanReference]);
  const [handleSubmit, { loading }] = useMutation(
    {
      type: 'update',
      resource: 'loan-references',
      payload: {
        id: '',
        data: {
          loan_applicationId: loanApplication,
          referenceId: loanReferenceId,
          stateId: stateReference,
          comment,
        },
      },
    },
    {
      onSuccess: () => {
        handleClickOpenClose();
        notify('Estado de la referencia actualizado con éxito');
        refresh();
      },
      onFailure: (error) => {
        notify(
          `${thereWasProblem}: ${
            (error.body.data ? error.body.data.error : error.body.error) ||
            'Fallo general'
          }`,
          'warning'
        );
      },
    }
  );
  return (
    <div className={classes.root}>
      <Dialog
        open={openDialog}
        onClose={handleClickOpenClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle id='form-dialog-title' disableTypography>
          <Typography variant='h4'>Estado de la comunicación</Typography>
        </DialogTitle>
        <DialogContent>
          <SimpleForm toolbar={<></>} className={classes.content}>
            <TextInput
              source='loanReferenceId'
              label='Referencia'
              defaultValue={`${loanReference ? loanReference?.name : ''}`}
              validate={required()}
              variant='outlined'
              fullWidth
              disabled
            />
            <SelectInput
              source='statesReference'
              label='Estados para la referencia'
              choices={statesReference()}
              optionText='name'
              optionValue='id'
              fullWidth
              validate={required()}
              variant='outlined'
              onChange={(e: any) => setStateReference(e.target.value)}
            />
            <TextInput
              fullWidth
              variant='outlined'
              source='comment'
              resource='loan-references'
              validate={required()}
              onBlur={(e: any) => setComment(e.target.value)}
            />
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button
            label='Cambiar estado'
            color='primary'
            variant='contained'
            onClick={() => {
              if (!loanReferenceId || !stateReference || !comment) return;
              handleSubmit();
              handleClickOpenClose();
            }}
            disabled={loading}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};
