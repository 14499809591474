import { MessageTypePersonForLoanApplication, PersonTypeAlias } from './enums';

export const generateYearsSince = (value = 10) => {
  const max = new Date().getFullYear();
  const min = max - value;
  const years = [];

  for (let i = max; i >= min; i--) {
    const options = {
      id: i,
      name: i.toString(),
    };

    years.push(options);
  }
  return years;
};

export const formatNumber = (number) => {
  return new Intl.NumberFormat('ES-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(number);
};

export const formatInputNumber = (number) => {
  return new Intl.NumberFormat('ES-MX').format(number);
};

export const parseInputNumber = (formattedNumber) => {
  return Number(formattedNumber.replace(/,/g, ''));
};

export const calculatePercentages = (elements) => {
  let arr = [];
  let arrPercentages = [];
  let suma = 0;
  for (var i = 0; i < elements.length; i++) {
    suma += parseInt(elements[i]);
    arr.push(parseInt(elements[i]));
  }
  for (var j = 0; j < elements.length; j++) {
    let pors = 0;
    pors = (100 * elements[j]) / suma;
    const porsStr = pors.toFixed(2);
    arrPercentages.push(porsStr + '%');
  }

  return arrPercentages;
};

export function savePreviousPath(path: string) {
  const [_, realPath] = path.split('#');

  localStorage.setItem('previous-path', realPath);
}

export function goPreviousPath() {
  const previousPath = localStorage.getItem('previous-path');
  return previousPath;
}

export function deletePreviousPath() {
  localStorage.removeItem('previous-path');
}

export function pathToGoParentEntity(
  dataRecord: any,
  previousPath: string | null
) {
  if (previousPath) return previousPath;

  return dataRecord?.person
    ? `/people/${getPerson(dataRecord?.person)}/show/assets`
    : `/legal-people/${getPerson(dataRecord?.legalPerson)}/show/assets`;
}

export function getPerson(anyPerson: any) {
  if (typeof anyPerson === 'object') return anyPerson.id;
  return anyPerson;
}

export function getUserFromLS() {
  try {
    return JSON.parse(localStorage.getItem('user') || '{}');
  } catch (err) {
    console.log(err);
    return {};
  }
}
export function isUserAdmin() {
  const user = getUserFromLS();
  return !!user?.role?.isAdmin;
}

/**
 *
 * @param selectedPerson any - From selected person in autocompleteInput or any kind of select component
 * @returns string
 */

export function getTypePersonByAnySelected(selectedPerson: any) {
  if (!selectedPerson) return '';
  if (selectedPerson?.businessName) return PersonTypeAlias.Moral;
  return PersonTypeAlias.Physical;
}

export function messageWhenIsEmployeeOrFranchiseManager(
  person: any,
  personFromRecord: any
): string {
  const dataPerson = person ?? personFromRecord;

  if (!dataPerson) return '';
  if (dataPerson.isFranchiseManager && dataPerson.isEmployee) {
    return MessageTypePersonForLoanApplication.IsEmployeeAndFranchiseManager;
  }
  if (dataPerson.isFranchiseManager) {
    return MessageTypePersonForLoanApplication.IsFranchiseManager;
  }
  if (dataPerson.isEmployee) {
    return MessageTypePersonForLoanApplication.IsEmployee;
  }
  return '';
}
