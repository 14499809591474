import React from 'react';
import {
  Create,
  TabbedForm,
  FormTab,
  TopToolbar,
  ListButton,
  useNotify,
  useRedirect,
} from 'react-admin';

import { parse } from 'query-string';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import RelatedForm from './RelatedForm';
import { errors } from '../../Utils/messages';

const Actions = ({ person, legalPerson }) => (
  <TopToolbar>
    <ListButton
      to={
        person
          ? `/people/${person}/show/relateds`
          : `/legal-people/${legalPerson}/show/relateds`
      }
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const ReferenceCreate = (props) => {
  const { person: personString, 'legal-person': legalPersonString } = parse(
    props.location.search
  );
  const person = personString ? parseInt(personString, 10) : '';
  const legalPerson = legalPersonString ? parseInt(legalPersonString, 10) : '';
  const notify = useNotify();
  const redirect = useRedirect();

  const onFailure = (error) => {
    const code = error.body?.data?.errorCode;
    const errors = error.body?.data?.errors;
    if(code) {
      notify(
        code
          ? `No se solicito el buró de crédito automáticamente del relacionado por: ${errors[code]}`
          : 'Error con su solicitud, inténtelo mas tarde..',
        'error',
        undefined,
        false,
        4500
      );
    }
    if(errors){
      notify(
        errors
          ? errors
          : 'Error con su solicitud, inténtelo mas tarde..',
        'error',
        undefined,
        false,
        4500
      );
    }
    redirect(
      person
        ? `/people/${person}/show/relateds`
        : `/legal-people/${legalPerson}/show/relateds`
    );
  };

  return (
    <Create
      title=''
      {...props}
      actions={<Actions person={person} legalPerson={legalPerson} />}
      onFailure={onFailure}
    >
      <TabbedForm
        initialValues={{ person, legalPerson }}
        redirect={
          person
            ? `/people/${person}/show/relateds`
            : `/legal-people/${legalPerson}/show/relateds`
        }
        variant='outlined'
      >
        <FormTab label='Relacionado'>
          <RelatedForm />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default ReferenceCreate;
