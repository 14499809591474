import React from 'react';
import {
  ArrayInput,
  required,
  SimpleFormIterator,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  useTranslate,
  TextInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';

const choices = [
  { id: 'Ahorro', name: 'Ahorro' },
  { id: 'Corriente', name: 'Corriente' },
];
const isRequired = [required()];

const InformationBank = ({ className = '', ...rest }) => {
  const translate = useTranslate();
  return (
    <ArrayInput source='bankInformation' label='' {...rest}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <SelectInput
                    source={getSource('productType')}
                    validate={isRequired}
                    variant='outlined'
                    fullWidth
                    label={translate(
                      'resources.information-bank.fields.productType'
                    )}
                    choices={choices}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInput
                    source={getSource(rest.record?.id ? 'bank.id' : 'bank')}
                    reference='banks'
                    validate={isRequired}
                    variant='outlined'
                    fullWidth
                    label={translate('resources.information-bank.fields.bank')}
                  >
                    <SelectInput optionText='name' />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    source={getSource('number')}
                    validate={isRequired}
                    variant='outlined'
                    fullWidth
                    label={translate(
                      'resources.information-bank.fields.number'
                    )}
                  />
                </Grid>
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default InformationBank;
