import MoralPersonList from './MoralPersonList';
import MoralPersonCreate from './MoralPersonCreate';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MoralPersonEdit from './MoralPersonEdit';
import MoralPersonShow from './MoralPersonShow';

export const MoralPeople: object = {
  list: MoralPersonList,
  create: MoralPersonCreate,
  edit: MoralPersonEdit,
  show: MoralPersonShow,
  icon: PeopleAltIcon,
};

export default MoralPeople;
