import { ChevronLeft } from "@material-ui/icons";
import React from "react";
import { Create, ListButton, TopToolbar } from "react-admin";
import { CreditTypeForm } from "./CreditTypeForm";

const CreditTypeActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton 
      basePath={basePath} 
      label="ra.action.back"
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const CreditTypeCreate = (props: any) => (
  <Create actions={<CreditTypeActions {...props} />} {...props} >
    <CreditTypeForm {...props} />
  </Create>
);

export default CreditTypeCreate;