import IncomeCreate from './IncomeCreate';
import IncomeEdit from './IncomeEdit';
import IncomeShow from './IncomeShow';

const Incomes: object = {
  create: IncomeCreate,
  edit: IncomeEdit,
  show: IncomeShow,
};

export default Incomes;
