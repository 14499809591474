import React from "react";
import { Create, Edit, ListButton, TopToolbar, useNotify, useRefresh } from "react-admin";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ApplicationTypeSettingForm from "./ApplicationTypeSettingForm";
import { errors, thereWasProblem } from "../../Utils/messages";


const ApplicationTypeSettingActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const ApplicationTypeSettingCreate = (props: any) => {
  const notify = useNotify();
  const onFailure = (error) => notify(
    `${thereWasProblem}: ${errors[error.body?.data?.errorCode] || error.body?.data?.errors || 'Fallo general'}`,
    'warning'
  );
  return (
    <Create 
      actions={<ApplicationTypeSettingActions {...props} />} 
      onFailure={onFailure} 
      {...props}
    >
      <ApplicationTypeSettingForm {...props} />
    </Create>
  );
};

export default ApplicationTypeSettingCreate;
