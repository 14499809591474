import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

interface Iprops {
  title: string;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'capitalize',
    letterSpacing: '1px',
    color: theme.palette.text.secondary,
    padding: '0.3rem',
    marginTop: '0.5rem',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: theme.palette.background.default,
    borderBottom: `${theme.palette.divider} 1px solid`,
    fontSize: '1rem',
  },
}));

const FormTitle = ({ title }: Iprops) => {
  const classes = useStyles();

  return (
    <Typography
      gutterBottom
      className={classes.title}
      component='div'
      variant='h6'
    >
      {title}
    </Typography>
  );
};

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FormTitle;
