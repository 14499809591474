import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import MaritalStatusList from './MaritalStatusList';

export const MaritalStatus: object = {
  list: MaritalStatusList,
  icon: SupervisedUserCircleIcon,
};

export default MaritalStatus;
