import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceField,
  FunctionField,
  TopToolbar,
  ListButton,
  EditButton,
  ReferenceManyField,
  Datagrid,
  useTranslate,
  Pagination,
  ArrayField,
  BooleanField,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { entities } from '../../Utils/enums';

import { FormShow } from '../../styles';
import FormTitle from '../../Components/FormTitle';
import NotesIterator from '../Notes/NotesIterator';
import TablePlaceholder from '../../Components/TablePlaceholder';

const LoanApplicationsShowActions = ({ basePath, data, permissions }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
    {permissions?.['franchise']?.update?.enabled && (
      <EditButton basePath={basePath} record={data} />
    )}
  </TopToolbar>
);

const FranchiseShow = (props) => {
  const classes = FormShow();
  const translate = useTranslate();
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  return (
    <Show {...props} actions={<LoanApplicationsShowActions {...props} />}>
      <TabbedShowLayout className={classes.borderBottom}>
        <Tab label='General' contentClassName={classes.flexParent}>
          <FormTitle title='Información' className={classes.width100} />
          <TextField source='code_id' label='Id' className={classes.width25} emptyText='-' />
          <TextField source='code' className={classes.width25} emptyText='-' />
          <TextField source='name' className={classes.width25} emptyText='-' />
          <ReferenceField
            source='manager'
            reference='people'
            link={false}
            className={classes.width25}
            emptyText='-'
          >
            <FunctionField
              render={(record) =>
                record && `${record.firstName} ${record.fatherLastName}`
              }
            />
          </ReferenceField>
          <ReferenceField
            source='agent.id'
            reference='employees'
            link={false}
            className={classes.width25}
            emptyText='-'
          >
            <FunctionField
              render={(record) =>
                record &&
                `${record.person?.firstName ?? ''} ${
                  record.person?.fatherLastName ?? ''
                }`
              }
            />
          </ReferenceField>
          <ReferenceField
            source='masterBroker'
            reference='franchises'
            link={false}
            className={classes.width25}
            emptyText='-'
          >
            <TextField source='name' />
          </ReferenceField>
          <BooleanField
            source='blacklist'
            className={classes.width25}
            emptyText='-'
          />
          <FormTitle title='Contactos' className={classes.width100} />
          <ArrayField
            addLabel={false}
            source='contacts'
            fieldKey='id'
            className={classes.width100}
          >
            <Datagrid empty={<TablePlaceholder title='Contactos' />}>
              <TextField
                emptyText='-'
                source='contactType.name'
                label={translate('resources.contacts.fields.contactType')}
              />
              <TextField
                emptyText='-'
                source='contact'
                label={translate('resources.contacts.fields.contact')}
              />
              <FunctionField
                emptyText='-'
                source='duplicate'
                label={translate('resources.contacts.fields.duplicate')}
                render={(record) =>
                  record?.duplicate
                    ? `Este contacto esta registrado en la entidad ${
                        entities[record.duplicate.entity]
                      } ${record.duplicate.id}`
                    : '-'
                }
              />
            </Datagrid>
          </ArrayField>
          <FormTitle title='Direcciones' className={classes.width100} />
          <ArrayField
            addLabel={false}
            source='addresses'
            fieldKey='id'
            className={classes.width100}
          >
            <Datagrid empty={<TablePlaceholder title='Direcciones' />}>
              <TextField
                emptyText='-'
                source='addressType.name'
                label={translate('resources.addresses.fields.addressType')}
              />
              <TextField
                emptyText='-'
                source='country.name'
                label={translate('resources.addresses.fields.country')}
              />
              <TextField
                emptyText='-'
                source='province.name'
                label={translate('resources.addresses.fields.province')}
              />
              <TextField
                emptyText='-'
                source='municipality.name'
                label={translate('resources.addresses.fields.municipality')}
              />
              <TextField
                emptyText='-'
                source='neighborhood.name'
                label={translate('resources.addresses.fields.neighborhood')}
              />
              <TextField
                emptyText='-'
                source='city.name'
                label={translate('resources.addresses.fields.city')}
              />
              <TextField
                emptyText='-'
                source='street'
                label={translate('resources.addresses.fields.street')}
              />
              <TextField
                emptyText='-'
                source='outsideNumber'
                label={translate('resources.addresses.fields.outsideNumber')}
              />
              <TextField
                emptyText='-'
                source='postalCode'
                label={translate('resources.addresses.fields.postalCode')}
              />
              <FunctionField
                emptyText='-'
                source='duplicate'
                label={translate('resources.contacts.fields.duplicate')}
                render={(record) =>
                  record?.duplicate
                    ? `Esta dirección esta registrada en la entidad ${
                        entities[record.duplicate.entity]
                      } ${record.duplicate.id}`
                    : '-'
                }
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label='Notas' path='notes'>
          <ReferenceManyField
            target='franchise'
            reference='contact-histories'
            addLabel={false}
            className={classes.width100}
            filter={{ segment: 'franchise', related: props.id }}
            pagination={<Pagination />}
          >
            <NotesIterator
              showStatus
              reference='franchises'
              segment='franchise'
              related={props.id}
            />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default FranchiseShow;
