import AssessmentIcon from '@material-ui/icons/Assessment';

import EvaluationUnitList from './EvaluationUnitList';

export const EvaluationUnit: object = {
  list: EvaluationUnitList,
  icon: AssessmentIcon,
};

export default EvaluationUnit;
