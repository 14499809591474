import PersonAddIcon from '@material-ui/icons/PersonAdd';

import ReferenceTypeList from './ReferenceTypeList';

export const ReferenceType: object = {
  list: ReferenceTypeList,
  icon: PersonAddIcon,
};

export default ReferenceType;
