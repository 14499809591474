export const convertTheValueToBytes = (bytes) => {
  let value = Math.floor(Math.log(bytes) / Math.log(1024));
  if (value < 0) {
    value = 0;
  }
  return (
    (bytes / Math.pow(1024, value)).toFixed(2) * 1 +
    ' ' +
    ['KB', 'MB', 'GB', 'TB'][value]
  );
};
