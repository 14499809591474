import React, { useState } from 'react';
import { Button, useMutation, useNotify } from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/styles';
import { thereWasProblem } from '../../Utils/messages';
import { Theme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonSpacing: {
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
}));

const PasswordResetButton = ({ record }) => {
  const notify = useNotify();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [sendEmail, { loading }] = useMutation(
    {
      type: 'create',
      resource: 'auth/forgot-password',
      payload: {
        data: { email: record.email },
      },
    },
    {
      onSuccess: () => {
        notify('El correo fue enviado exitosamente.');
        handleClose();
      },
      onFailure: (error) => {
        notify(
          `${thereWasProblem}: ${
            (error.body.data ? error.body.data.error : error.body.error) ||
            'Ha ocurrido un error.'
          }`,
          'warning'
        );
        handleClose();
      },
    }
  );

  return (
    <div className={classes.root}>
      <Button
        className={classes.buttonSpacing}
        variant='contained'
        disabled={loading}
        label='Reiniciar contraseña'
        onClick={handleOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        maxWidth='xs'
        fullWidth
      >
        <MuiDialogTitle id='form-dialog-title' disableTypography>
          <Typography variant='h4'>Reiniciar contraseña</Typography>
        </MuiDialogTitle>
        <DialogContent>
          <Typography variant='h6'>Seguro que deseas continuar?</Typography>
        </DialogContent>
        <DialogActions>
          <Button label='Cancelar' onClick={handleClose} color='primary' />
          <Button
            label='Confirmar'
            onClick={sendEmail}
            color='primary'
            disabled={loading}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PasswordResetButton;
