import React from 'react';
import { Grid } from '@material-ui/core';
import { ReferenceContact } from './RefereceContact';
import { makeStyles } from '@material-ui/styles';
import { LoanReferenceList } from './LoanReferenceList';

const useStyles = makeStyles({
  gridContent: {
    width: '100%',
  },
});

const clientTypeFromRecord = (anyPerson: any) => {
  if (anyPerson.person)
    return {
      person: anyPerson.person?.id,
    };
  return {
    legalPerson: anyPerson.legalPerson?.id,
  };
};

const userNameFromRecord = (anyPerson: any) => {
  if (anyPerson.person)
    return `${anyPerson.person?.firstName} ${anyPerson.person?.secondName}`;
  return `${anyPerson.legalPerson?.businessName}`;
};

export const References = (props: any) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const classes = useStyles();
  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Grid className={classes.gridContent}>
        <ReferenceContact
          filterClient={clientTypeFromRecord(props?.record)}
          loanApplicationId={props.record?.id}
          userName={userNameFromRecord(props.record)}
          userRole={user.role?.id}
          {...props}
        />
      </Grid>
      <Grid className={classes.gridContent}>
        <LoanReferenceList userRole={user.role?.id} {...props} />
      </Grid>
    </Grid>
  );
};
