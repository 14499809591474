import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  TopToolbar,
  ArrayField,
  Datagrid,
  ReferenceField,
  ListButton,
  EditButton,
} from 'react-admin';
import { ChevronLeft } from '@material-ui/icons';

import { FormShow } from '../../styles';
import FormTitle from '../../Components/FormTitle';
import TablePlaceholder from '../../Components/TablePlaceholder';

const LoanApplicationsShowActions = ({ basePath, data, permissions }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
    {permissions?.['state-rule']?.update?.enabled && (
      <EditButton basePath={basePath} record={data} />
    )}
  </TopToolbar>
);

const StateRuleShow = (props) => {
  const classes = FormShow();

  return (
    <Show
      {...props}
      actions={<LoanApplicationsShowActions {...props} />}
      classes={{ card: classes.show }}
    >
      <TabbedShowLayout className={classes.borderBottom}>
        <Tab label='General' contentClassName={classes.flexParent}>
          <FormTitle title='Flujo' className={classes.width100} />
          <TextField className={classes.width25} source='name' emptyText='-' />
          <ReferenceField
            source='actualState'
            emptyText='-'
            reference='loan-application-states'
            className={classes.width25}
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>
          <ReferenceField
            source='futureState'
            emptyText='-'
            reference='loan-application-states'
            className={classes.width25}
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>
          <FormTitle
            title='Tipos de solicitudes'
            className={classes.width100}
          />
          <ArrayField
            fieldKey='id'
            addLabel={false}
            className={classes.width100}
            source='applicationTypes'
          >
            <Datagrid empty={<TablePlaceholder title='Tipos de solicitudes' />}>
              <TextField source='name' emptyText='-' />
            </Datagrid>
          </ArrayField>
          <FormTitle title='Posiciones' className={classes.width100} />
          <ArrayField
            fieldKey='id'
            addLabel={false}
            className={classes.width100}
            source='positions'
          >
            <Datagrid empty={<TablePlaceholder title='Posiciones' />}>
              <TextField source='position.name' emptyText='-' />
            </Datagrid>
          </ArrayField>
          <FormTitle title='Condiciones' className={classes.width100} />
          <ArrayField
            fieldKey='id'
            addLabel={false}
            className={classes.width100}
            source='conditions'
          >
            <Datagrid empty={<TablePlaceholder title='Condiciones' />}>
              <TextField source='parameter.name' emptyText='-' />
              <TextField source='operator.name' emptyText='-' />
              <TextField source='value' emptyText='-' />
            </Datagrid>
          </ArrayField>
          <FormTitle title='Documentos' className={classes.width100} />
          <ArrayField
            fieldKey='id'
            addLabel={false}
            className={classes.width100}
            source='documents'
          >
            <Datagrid empty={<TablePlaceholder title='Documentos' />}>
              <TextField source='documentSegment.name' emptyText='-' />
              <TextField source='documentType.name' emptyText='-' />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default StateRuleShow;
