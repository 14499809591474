import React from 'react';
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import ListActions from '../../Components/ListActions';
import CustomList from '../../Components/CustomList';
import AgentAssignButton from './AgentAssignButton';
import AgentUnassignButton from './AgentUnassignButton';
import { FormShow } from '../../styles';
import { getUserFromLS } from '../../Utils/functions';

const ENTITY_NAME = 'legal-people';

const MoralPersonBulkActionButtons = ({permissions, ...props}) => (
  <>
    {permissions?.[ENTITY_NAME]?.assignagent?.enabled && <AgentAssignButton {...props} />}
    {permissions?.[ENTITY_NAME]?.unassignagent?.enabled && <AgentUnassignButton {...props} />}
  </>
);

const MoralPersonFilter = (props) => {
  const classes = FormShow();
  return (<Filter {...props} variant='outlined'>
      <TextInput source='businessName' resettable alwaysOn />
      <TextInput source='code_id' label='Id' resettable className={classes.small}/>
      <TextInput source='documentNumber' resettable />
      <TextInput source='tradeName' resettable />
      <ReferenceInput
        reference='employees'
        source='agent'
        label='resources.legal-people.fields.agent'
        resettable
      >
        <AutocompleteInput
          optionText={(record) =>
            record.id &&
            `${record?.code || ''} | ${record.person?.firstName ?? ''} ${
              record.person?.fatherLastName ?? ''
            }`
          }
        />
      </ReferenceInput>
    </Filter>);
};

export const MoralPersonList = ({ permissions, ...props }) => {
  return (
    <CustomList
      filters={<MoralPersonFilter />}
      bulkActionButtons={
        <MoralPersonBulkActionButtons permissions={permissions} {...props} />
      }
      actions={
        <ListActions
          entity='legal-people'
          permissions={permissions}
          {...props}
        />
      }
      {...props}
      hasCreate={permissions?.[ENTITY_NAME]?.create?.enabled}
    >
      <Datagrid rowClick='show'>
        <TextField source='code_id' label='Id' />
        <TextField source='documentNumber' />
        <TextField source='businessName' />
        <FunctionField
          source='agent'
          emptyText='-'
          render={(record) =>
            record.agent
              ? `${record?.agent?.code ?? ''} | ${
                  record?.agent?.person?.firstName ?? ''
                } ${record?.agent?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <TextField source='tradeName' />
      </Datagrid>
    </CustomList>
  );
};

export default MoralPersonList;
