import React, { useState } from 'react';
import {
  useTranslate,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  TopToolbar,
  ListButton,
  EditButton,
  ChipField,
  Datagrid,
  ArrayField,
  NumberField,
  BooleanField,
  useGetOne,
  FunctionField,
  usePermissions,
} from 'react-admin';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { FormShow } from '../../styles';
import FormTitle from '../../Components/FormTitle';
import { DocumentUploadButton, DocumentList } from '../Documents';
import TablePlaceholder from '../../Components/TablePlaceholder';
import {
  AssetSegment,
  DocumentSegment,
  entities,
} from '../../Utils/enums';
import FullNameField from '../../Components/FullNameField';
import { Timeline } from '../../Components/Timeline';
import { goPreviousPath, pathToGoParentEntity } from '../../Utils/functions';

const AssetsShowActions = ({ basePath, data, owner }: any) => {
  const [toBackPath] = useState(pathToGoParentEntity(data, goPreviousPath()));
  const { permissions } = usePermissions();

  return (
    <TopToolbar>
      <ListButton
        to={toBackPath}
        label='ra.action.back'
        icon={<ChevronLeft />}
      />
      {(permissions?.['asset']?.update.enabled) && <EditButton basePath={basePath} record={data} />}
    </TopToolbar>
  );
};

const AssetsShow = ({ ...props }) => {
  const classes = FormShow();
  const translate = useTranslate();
  const { data } = useGetOne('assets', props.id);
  const isOwnerPerson = Boolean(data?.person?.id);
  const { data: owner } = useGetOne(
    isOwnerPerson ? 'people' : 'legal-people',
    isOwnerPerson ? data?.person?.id : data?.legalPerson?.id
  );

  return (
    <Show
      {...props}
      actions={<AssetsShowActions {...props} owner={owner} />}
      aside={<Timeline segment='asset' />}
    >
      <TabbedShowLayout className={classes.borderBottom}>
        <Tab label='General' contentClassName={classes.flexParent}>
          <FormTitle title='Información Bien' className={classes.width100} />
          <TextField
            source='assetType.name'
            resource='assets'
            className={classes.width25}
            emptyText='-'
          />
          <FunctionField
            label='Tipo de documento'
            className={classes.width25}
            render={() => (
              <ChipField
                source='documentType.name'
                resource='assets'
                style={{ width: 'initial' }}
                emptyText='-'
              />
            )}
          />
          <TextField
            source='documentNumber'
            emptyText='-'
            className={classes.width25}
          />
          <NumberField
            emptyText='-'
            source='value'
            options={{ style: 'currency', currency: 'MXN' }}
            className={classes.width25}
          />
          {data?.segment === AssetSegment.Vehicle ? (
            <TextField
              source='brand'
              emptyText='-'
              className={classes.width25}
            />
          ) : null}
          {data?.segment === AssetSegment.Vehicle ? (
            <TextField
              source='model'
              emptyText='-'
              className={classes.width25}
            />
          ) : null}
          {data?.segment === AssetSegment.Vehicle ? (
            <TextField
              source='year'
              emptyText='-'
              className={classes.width25}
            />
          ) : null}
          {data?.segment === AssetSegment.Vehicle ? (
            <BooleanField
              source='isInsured'
              emptyText='-'
              className={classes.width25}
            />
          ) : null}
          {data?.segment === AssetSegment.Property ? (
            <BooleanField
              source='haveDeed'
              emptyText='-'
              className={classes.width25}
            />
          ) : null}
          <BooleanField
            source='legalOpposition'
            emptyText='-'
            className={classes.width25}
          />
          <FunctionField
            label='Nombre del cliente'
            source='person'
            render={(record) =>
              record?.person ? (
                <Link
                  component={RouterLink}
                  to={`/people/${record.person?.id}/show`}
                  variant='inherit'
                  color='textPrimary'
                  underline='none'
                >
                  <FullNameField
                    record={record}
                    source='person'
                    entity='person'
                  />
                </Link>
              ) : (
                <Link
                  component={RouterLink}
                  to={`/people/${record?.legalPerson?.id}/show`}
                  variant='inherit'
                  color='textPrimary'
                  underline='none'
                >
                  <TextField source='legalPerson.businessName' emptyText='-' />
                </Link>
              )
            }
          />
          {data?.segment === AssetSegment.Property ? (
            <FormTitle title='Direcciones' className={classes.width100} />
          ) : null}
          {data?.segment === AssetSegment.Property ? (
            <ArrayField
              addLabel={false}
              source='addresses'
              fieldKey='id'
              className={classes.width100}
            >
              <Datagrid empty={<TablePlaceholder title='Direcciones' />}>
                <TextField
                  emptyText='-'
                  source='addressType.name'
                  label={translate('resources.addresses.fields.addressType')}
                />
                <TextField
                  emptyText='-'
                  source='country.name'
                  label={translate('resources.addresses.fields.country')}
                />
                <TextField
                  emptyText='-'
                  source='province.name'
                  label={translate('resources.addresses.fields.province')}
                />
                <TextField
                  emptyText='-'
                  source='municipality.name'
                  label={translate('resources.addresses.fields.municipality')}
                />
                <TextField
                  emptyText='-'
                  source='neighborhood.name'
                  label={translate('resources.addresses.fields.neighborhood')}
                />
                <TextField
                  emptyText='-'
                  source='city.name'
                  label={translate('resources.addresses.fields.city')}
                />
                <TextField
                  emptyText='-'
                  source='street'
                  label={translate('resources.addresses.fields.street')}
                />
                <TextField
                  emptyText='-'
                  source='outsideNumber'
                  label={translate('resources.addresses.fields.outsideNumber')}
                />
                <TextField
                  emptyText='-'
                  source='postalCode'
                  label={translate('resources.addresses.fields.postalCode')}
                />
                <FunctionField
                  emptyText='-'
                  source='duplicate'
                  label={translate('resources.contacts.fields.duplicate')}
                  render={(record) =>
                    record?.duplicate
                      ? `Esta dirección esta registrada en la entidad ${
                          entities[record.duplicate.entity]
                        } ${record.duplicate.id}`
                      : '-'
                  }
                />
              </Datagrid>
            </ArrayField>
          ) : null}
        </Tab>
        <Tab label='Documentos' path='documents'>
          <DocumentUploadButton
            documentSegment={DocumentSegment.Asset.id}
            segment={DocumentSegment.Asset.name}
            related={props.id}
          />
          <DocumentList segment={DocumentSegment.Asset.name} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default AssetsShow;
