import { Create, ListButton, TopToolbar } from 'react-admin';

import ChevronLeft from '@material-ui/icons/ChevronLeft';

import SubstateForm from './SubstateForm';

const SubstateActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const SubstateCreate = (props: any) => {
  return (
    <Create actions={<SubstateActions {...props} />} {...props}>
      <SubstateForm {...props} />
    </Create>
  );
};

export default SubstateCreate;
